import get from 'lodash/get';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';

import { PageName, StaticSlugPageName } from '@features/core/routing';
import { getStaticPageURL } from '@features/core/routing/linkAliases';
import services from '@features/core/services';

import { GCMS_FORM_COOKIE, USER_ID } from '@common/constants/cookie';
import { useUserState } from '@common/providers/user/useUserState';
import postGCMSForm from '@common/api/static/postGCMSForm';

export default (theme): string => `
  .color-cta {
      color: ${theme.accent};
    }
    .color-light-blue {
      color: ${theme.primaryBackground};
    }
    .color-dark-blue {
      color: #6f8a95;
    }
    .background-color-primary {
      background-color: ${theme.accent};
    }
    .background-color-light-blue {
      background-color: ${theme.primaryBackground};
    }
    .background-color-dark-blue {
      background-color: ${theme.main};
    }
    .width-25 {
      width: 25%;
    }
    .width-50 {
      width: 50%;
    }
    .width-75 {
      width: 75%;
    }
    .width-100 {
      width: 100%;
    }
    .flex {
      display: flex;
    }
    .flex-row {
      flex-direction: row;
    }
    .flex-column {
      flex-direction: column;
    }
    .flex-order-1 {
      order: 1;
    }
    .flex-order-2 {
      order: 2;
    }
    .flex-order-3 {
      order: 3;
    }
    .flex-order-4 {
      order: 4;
    }
    .flex-order-5 {
      order: 5;
    }
    .responsive-image {
      display: block;
      max-width: 100%;
    }
    .text-xxxl {
      font-size: 4.375rem;
    }
    .text-xxl {
      font-size: 3.75rem;
    }
    .text-xl {
      font-size: 2.5rem;
    }
    .text-l {
      font-size: ${theme.fontH3};
    }
    .text-m {
      font-size: ${theme.fontLG};
    }
    .text-normal {
      font-size: ${theme.baseFontSize};
    }
    .button {
      border: 0px;
      text-align: center;
      padding: ${theme.space_5};
      background-color: ${theme.textTertiary};
      color: ${theme.highlightWrapperBackground};
    }
    .button.big-button {
      padding: ${theme.space_10} 35px;
      font-size: 2.5rem;
    }
    .button.medium-button {
      padding: ${theme.space_10} ${theme.space_20};
      font-size: ${theme.fontH3};
    }
    .button.normal-button {
      padding: ${theme.space_10} ${theme.space_15};
      font-size: ${theme.fontMD};
    }
    .button.cta {
      background-color: ${theme.accent};
      padding: ${theme.space_10} ${theme.space_15};
      color: #1a3650;
      text-align: center;
      border: 0px none;
    }
    .button.ghost {
      border: 1px solid ${theme.highlightWrapperBackground};
      background: none transparent;
    }
`;

/**
 * addQueryParametersToLink
 * Logic for adding Query Parameters from URI to all Links on static pages
 *
 * @param {Array} links
 * @returns {void}
 */

export const addQueryParametersToLink = (): void => {
  const links = document.querySelectorAll('#static-page-section a');

  const searchParams = new URLSearchParams(window.location.search);
  forEach(links, link => {
    const currentHref = link.getAttribute('href');

    if (currentHref && ((searchParams as unknown) as { size: number }).size) {
      const url = new URL(currentHref, window.location.href);
      forEach(searchParams, (value, key): void =>
        url.searchParams.set(key, (value as unknown) as string),
      );
      link.setAttribute('href', url.href);
    }
  });
};

/**
 * toggleLinks
 * Logic for showing/hiding CTA button if user is logged in/out
 *
 * @param {string} classname
 * @param {boolean} show
 * @returns {void}
 */
export const toggleLinks = (classname, show): void => {
  const elements = document.getElementsByClassName(classname);

  forEach(elements, element => {
    if (element instanceof HTMLElement) {
      // eslint-disable-next-line no-param-reassign
      element.style.display = show ? 'block' : 'none';
    }
  });
};

export const toggleStaticLinks = (isLogout?: boolean): void => {
  toggleLinks('user-link', !isLogout);
  toggleLinks('guest-link', isLogout);
};

export const getStaticPageType = (
  route: PageName,
  slug: StaticSlugPageName,
): string => {
  const pageUrl = getStaticPageURL(route, slug);

  return replace(pageUrl, /^\/en\/|^\//, '');
};

export const applySubmitButtonStyles = (isError: boolean): void => {
  const loader = document.querySelector('.form-submit-button > svg');
  const span = document.querySelector(
    '.form-submit-button > span',
  ) as HTMLSpanElement;
  const submitButton = document.querySelector('.form-submit-button');
  if (!isError) {
    loader?.classList.remove('disabled');
    submitButton?.classList.add('disabled');
    span.style.display = 'none';
  } else {
    loader?.classList.add('disabled');
    submitButton?.classList.remove('disabled');
    span.style.display = 'inline';
  }
};

export const formSubmissionHandler = async (event, formID): Promise<void> => {
  const userInput = document.getElementsByName('username')?.[0];

  if (userInput) {
    userInput.setAttribute(
      'value',
      useUserState.getState().data?.username as string,
    );
  }

  const formData = new FormData((event.target as unknown) as HTMLFormElement);
  const params = Object.fromEntries(formData);
  let userEmail = get(params, 'email', '');

  const sendData = new FormData();
  // Attachment files that we send to server
  const attachments = document.getElementById('attachments') as HTMLFormElement;

  if (attachments && attachments.files.length) {
    for (let i = 0; i < attachments.files.length; i++) {
      sendData.append('attachments', attachments.files[i]);
    }

    delete params.attachments;
  }

  if (services.cookie.get(USER_ID)) {
    userEmail = services.cookie.get(USER_ID);
  }

  sendData.append(
    'data',
    JSON.stringify({
      userId: userEmail,
      key: event.target.getAttribute('data-form-key'),
      formId: event.target.getAttribute(formID),
      value: {
        ...params,
      },
    }),
  );

  await postGCMSForm(sendData);

  services.cookie.set(
    GCMS_FORM_COOKIE,
    event.target.getAttribute(formID) as string,
    { expires: null },
  );
};
