import React from 'react';
import { useTranslation } from 'react-i18next';
import replace from 'lodash/replace';

import CustomLink from '@features/core/routing/CustomLink';
import { getPageURL, PageName } from '@features/core/routing/index';
import services from '@features/core/services';

import { WALLET_REQUEST } from '@common/constants/dialogs';
import { setLocation } from '@common/providers/router/helper';
import {
  closeSidebar,
  setStaticPageContent,
} from '@common/providers/application/useAppState';
import { isOpenDialog } from '@common/helpers/appState/dialogs';

import ISupportLink from './SupportLink.types';
import TextBold from './SupportLink.styled';

const SupportLink = ({
  dataQa,
  isBold,
  label,
  className,
}: ISupportLink): JSX.Element => {
  const { t } = useTranslation();
  const supportLink = getPageURL(PageName.SUPPORT);
  const slug = replace(getPageURL(PageName.SUPPORT), /^\/(en\/)?/, '');
  const isWalletRequest = isOpenDialog(WALLET_REQUEST);
  const onLinkClick = (e): void => {
    e.preventDefault();
    if (isWalletRequest) {
      closeSidebar(WALLET_REQUEST);
    }
    setStaticPageContent({
      lang: services.domainLang,
      types: [slug],
    });
    setLocation(PageName.SUPPORT);
  };

  return (
    <CustomLink
      href={supportLink}
      className={className}
      dataQa={dataQa}
      ariaLabel={t('common.links.support')}
      onClick={onLinkClick}
    >
      {isBold ? <TextBold>{t(label)}</TextBold> : <>{t(label)}</>}
    </CustomLink>
  );
};

export default SupportLink;
