import { ISelection, IUser } from '@common/interfaces';
import { ISetBettingSlipErrorPayload } from '@common/providers/bettingslip/types';

export enum IBettingslipStates {
  'opened',
  'accepted',
  'queued',
  'rejected',
  'frozen',
}

export enum IBettingslipType {
  'single',
  'combi',
  'system',
}

export enum IBetPackerType {
  'normal',
  'betPacker',
}

export enum IBSWalletType {
  REGULAR = 'REGULAR',
  BONUS = 'BONUS',
  SHOP = 'SHOP',
}

export interface IBettingslip {
  type: keyof typeof IBettingslipType;
  state: keyof typeof IBettingslipStates;
  banks: number[];
  selections: ISelection[];
  size: number[];
  totalStake: number;
  stake: number;
  user: IUser;
  loading?: boolean;
  error: ISetBettingSlipErrorPayload | null;
  bet_id?: string | null;
  queue_delay?: number;
  restoredSelections: ISelection[];
  bsMode: keyof typeof IBetPackerType;
  betPackerSelections: ISelection[];
  betPackerPredictions: string[];
  betPackerOdds: number | null;
  walletType?: keyof typeof IBSWalletType;
  legsCount: number;
}

export interface IQueuedBettingslip {
  lang: string;
  token: string;
  session: string;
  bet_id?: string;
  is_total_amount: number;
}

export interface ISubmittedBettingslip extends IQueuedBettingslip {
  [index: string]: string | number | string[] | number[] | undefined;
  selections: string[];
  legs_count: number;
  leg_size: number[];
  possible_profit: string;
  amount: string;
  bet_type: keyof typeof IBettingslipType;
  tax: string;
  source_of_bet: number;
  use_bonus?: number;
  use_shop_emoney?: number;
}

export interface IBettingslipResponse {
  result: {
    is_accepted: number;
    is_queued: number;
    queue_delay?: string;
    is_rejected: number;
  };
}

export interface ISetBettingSlipPayload {
  totalStake?: number;
  size?: number[];
  user?: IUser;
  restoredSelections?: Partial<ISelection>[];
  selections?: ISelection[];
  type?: keyof typeof IBettingslipType;
}
