import filter from 'lodash/filter';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import { Socket } from 'phoenix';
import { MutableRefObject, createRef } from 'react';

import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';
import CustomError from '@features/core/error/error';

import { UserEvents } from '@packages/events/appEvents';

import {
  EVENT_SORTING_KEY,
  TOKEN,
  SESSION_ID,
  DIALOGS,
  FAVORITES_EVENTS_COOKIE,
  FAVORITES_CATEGORIES_COOKIE,
  SLI,
} from '@common/constants/cookie';
import { MOBILE_SIDEBARS, savedDialogs } from '@common/constants/dialogs';
import { EXCLUDE_MARKETS, HAS_SOCKET_CLUSTER } from '@common/constants/config';
import { setLocation } from '@common/providers/router/helper';
import clusterEventUpdater from '@common/helpers/eventsHelper/socketCluster';
import eventUpdater from '@common/helpers/eventsHelper/socketUpdater';
import authUser from '@common/providers/user/actions/authUser';
import {
  TWENTY_HOURS_IN_SEK,
  createExpiresStamp,
  isDesktopView,
} from '@common/helpers/deviceUtil';
import { isWebView } from '@common/helpers/links';
import { ISidebar } from '@common/providers/application/types';
import {
  restoreBettingSlip,
  saveBettingSlip,
  validateSelections,
} from '@common/providers/bettingslip/helper';
import {
  getCategoriesTree,
  getHighlightedEvents,
} from '@common/providers/events/liveCategories/helper';
import {
  getMarketsToExclude,
  openSidebar,
  setEventsListSortingKey,
  setSocketConnection,
  useAppState,
} from '@common/providers/application/useAppState';
import { SortingKeys } from '@common/providers/application/state';
import checkForMarketingCookies from '@common/helpers/marketingCookieHelper';
import { getFavoriteData } from '@common/helpers/favoritesHelper';
import { logoutFuncs } from '@common/providers/user/actions/logoutUser';
import { setWalletsData } from '@common/providers/payments/wallets/useWallets';
import { fetchWallets } from '@common/providers/payments/wallets/helpers';

export const openSavedDialogs = (): void => {
  let openedSidebars = [] as ISidebar[];
  try {
    if (services.cookie.get(DIALOGS)) {
      openedSidebars = JSON.parse(services.cookie.get(DIALOGS));
      if (isDesktopView()) {
        openedSidebars = filter(
          openedSidebars,
          panelName => !MOBILE_SIDEBARS[panelName.type],
        );
      }
      forEach(openedSidebars, e => openSidebar(e));
      services.cookie.remove(DIALOGS);
    }
  } catch (e) {
    services.logger.log(`Not able to parse cookies for dialogs ${String(e)}`);
  }
};

export const setOutsideAppTime = (): void => {
  const expirationDate = new Date();
  expirationDate.setMinutes(expirationDate.getMinutes() + 2);
  services.cookie.set('appRefreshed', new Date().toISOString(), {
    expires: expirationDate,
  });
};

const createdSocketRef: MutableRefObject<typeof Socket | null> = createRef();

export const connectSocket = (): void => {
  const { socket } = useAppState.getState();

  if (!createdSocketRef.current || !socket) {
    createdSocketRef.current = services.config.get(HAS_SOCKET_CLUSTER)
      ? clusterEventUpdater()
      : eventUpdater();
    setSocketConnection(createdSocketRef.current);
  } else {
    socket?.connect();
  }
};

export const updateUserWallets = async (): Promise<void> => {
  const response = await fetchWallets();
  if (response instanceof CustomError) {
    return logoutFuncs();
  }
  return setWalletsData(response?.result?.wallets);
};

export const autoLoginUser = async (isInitial?: boolean): Promise<void> => {
  if (isWebView(window.location.search)) {
    services.events.emitEvent(UserEvents.REQUEST_AUTOLOGIN, {
      [TOKEN]: services.cookie.get(TOKEN),
      [SESSION_ID]: services.cookie.get(SESSION_ID),
    });
  }
  if (services.cookie.get(TOKEN) && !isWebView(window.location.search)) {
    if (isInitial) {
      authUser();
    } else {
      updateUserWallets();
    }
  }
};

export const loginNativeUser = (): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const expires = createExpiresStamp(TWENTY_HOURS_IN_SEK);
  const token = urlParams.get('token');
  const session = urlParams.get('session');
  if (token && session) {
    services.cookie.set(TOKEN, token, { expires });
    services.cookie.set(SESSION_ID, session, { expires });
  }
};

export const initialize = async (): Promise<void> => {
  loginNativeUser();
  document.getElementById('loading')?.remove();
  document.getElementById('loading-styles')?.remove();
  checkForMarketingCookies(window.location);
  openSavedDialogs();
  connectSocket();
  autoLoginUser(true);
  getCategoriesTree({});
  getHighlightedEvents();
  restoreBettingSlip();
  getFavoriteData(FAVORITES_EVENTS_COOKIE, 'event');
  getFavoriteData(FAVORITES_CATEGORIES_COOKIE, 'category');
  if (services.config.get(EXCLUDE_MARKETS)) {
    getMarketsToExclude();
  }
  if (services.cookie.get(SLI)) {
    setLocation(PageName.MIGRATION);
  }
  try {
    const sortingKey = services.cookie.get(EVENT_SORTING_KEY) as SortingKeys;
    if (sortingKey) {
      setEventsListSortingKey(sortingKey);
    }
  } catch (e) {
    services.logger.log(`Not able to parse cookies for sorting ${String(e)}`);
  }
};

export const onBeforeSleep = (): void => {
  saveBettingSlip();
  const { openedSidebars } = useAppState.getState();

  if (openedSidebars.length) {
    services.cookie.set(
      DIALOGS,
      JSON.stringify(
        filter(openedSidebars, e => includes(savedDialogs, e.type)),
      ),
      { expires: null },
    );
  }
};

export const onAfterSleep = (): void => {
  const { socket: appSocket } = useAppState.getState();

  if (appSocket && appSocket.disconnect) {
    appSocket.disconnect();
  }

  connectSocket();
  validateSelections('update');
};
