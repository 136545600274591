import React from 'react';
import includes from 'lodash/includes';
import trim from 'lodash/trim';
import split from 'lodash/split';

import { Icon } from '@features/core/commonComponents/icon';
import countries from '@features/core/countriesList';

import {
  isFlagImageJpeg,
  isOlympiaGame,
} from '@common/helpers/categories/categoriesModel';
import getImageBasePath from '@common/helpers/getImageBasePath';

import * as S from './CategoryFlag.styled';

const CategoryFlag = ({ isLongTermCategory, categoryPath }): JSX.Element => {
  const countryName =
    trim(split(categoryPath, '/')[isLongTermCategory ? 2 : 1]) || '';

  const getFlag = (): string => {
    if (includes(countryName, 'UEFA')) {
      return countries.UEFA;
    }
    if (countries[countryName]) {
      return countries[countryName];
    }
    if (isOlympiaGame(categoryPath)) {
      return 'olympia';
    }
    return 'international';
  };

  return (
    <S.FlagWrap>
      {isFlagImageJpeg(countryName) ? (
        <S.Flag
          src={getImageBasePath(`/images/flags/${getFlag()}.jpg`)}
          alt={countryName}
        />
      ) : (
        <Icon name={getFlag()} width="15" height="13" />
      )}
    </S.FlagWrap>
  );
};

export default CategoryFlag;
