import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '@features/events/components/prediction';
import { Icon } from '@features/core/commonComponents/icon';

const DisabledPrediction = (): React.ReactElement => {
  const { t } = useTranslation();
  const buttonName = t('common.buttons.lock');

  return (
    <StyledButton
      className="disabled"
      aria-label={buttonName}
      style={{ justifyContent: 'center' }}
    >
      <Icon width="12" height="13.6" name="lock-outlined" fill="#707070" />
    </StyledButton>
  );
};

export default DisabledPrediction;
