import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import services from '@features/core/services';
import { DualRingLoader } from '@features/core/commonComponents/dualRingLoader';

import { IS_TERMINAL } from '@common/constants/config';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';

import BettingslipWrapperContainter from './BettingslipWrapper.styled';

const is_terminal = services.config.get(IS_TERMINAL);

const Bettingslip = loadable(
  () => import('@features/bettingslip/components/bettingslip/Bettingslip'),
  {
    fallback: <DualRingLoader />,
  },
);

const handlerClick = (e): void => {
  e.stopPropagation();
  e.preventDefault();
};

const BettingslipWrapper: React.FC = (): React.ReactElement => {
  const state = useBettingSlip(s => s.state);

  useEffect(() => {
    const body = document.querySelector('body');
    const isWaiting = state === STATE_FROZEN || state === STATE_QUEUED;
    if (isWaiting) {
      body?.addEventListener('click', handlerClick, true);
    } else {
      body?.removeEventListener('click', handlerClick, true);
    }
  }, [state]);

  useEffect(() => {
    return () => {
      document
        .querySelector('body')
        ?.removeEventListener('click', handlerClick, true);
    };
  }, []);

  return (
    <BettingslipWrapperContainter className={is_terminal ? 'is_terminal' : ''}>
      <Bettingslip />
    </BettingslipWrapperContainter>
  );
};

export default BettingslipWrapper;
