import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';
import { SidebarSide } from '@features/app/layout/sidebar/Sidebar.types';
import CustomLink from '@features/core/routing/CustomLink';
import services from '@features/core/services';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { HIDE_PUSH_NOTIFICATION_LAYER } from '@common/constants/cookie';
import { PUSH_NOTIFICATION_LAYER } from '@common/constants/dialogs';
import { ButtonSize } from '@common/interfaces';
import { getApplicationLink } from '@common/helpers/appLayoutHelper';
import { closeSidebar } from '@common/providers/application/useAppState';

import * as S from './PushNotificationLayer.styled';

const PushNotificationLayer = (): React.ReactElement => {
  const { t } = useTranslation();
  const onPressUseNow = async (): Promise<void> => {
    setTimeout(() => {
      closeSidebar(PUSH_NOTIFICATION_LAYER);
    }, 500);
  };

  const onPressLater = (): void => {
    const expirationDate = new Date();

    expirationDate.setDate(expirationDate.getDate() + 90);

    services.cookie.set(HIDE_PUSH_NOTIFICATION_LAYER, 'hide_push', {
      expires: expirationDate,
    });

    closeSidebar(PUSH_NOTIFICATION_LAYER);
  };

  return (
    <S.Content>
      <S.IconWrapper
        onClick={(): void => {
          closeSidebar(PUSH_NOTIFICATION_LAYER);
        }}
      >
        <Icon name="close" width="14" height="14" fill="#004560" />
      </S.IconWrapper>
      <S.InfoSection>
        <S.Title>{t('user.push_notification.modal_title')}</S.Title>
        <S.InfoText>
          <Trans i18nKey="user.push_notification.modal_text_1">
            <S.BoldText />
          </Trans>
        </S.InfoText>
        <S.InfoText>
          <Trans i18nKey="user.push_notification.modal_text_2">
            <S.BoldText />
          </Trans>
        </S.InfoText>
      </S.InfoSection>
      <S.Footer>
        <S.ButtonWrapper>
          <CustomLink href={getApplicationLink()} ariaLabel="app-link">
            <StyledButton
              onClick={onPressUseNow}
              label="user.push_notification.btn_use_now"
              size={ButtonSize.Big}
            />
          </CustomLink>
        </S.ButtonWrapper>
        <S.TransparentButtonWrapper onClick={onPressLater}>
          <S.LaterText>{t('user.push_notification.btn_later')}</S.LaterText>
        </S.TransparentButtonWrapper>
      </S.Footer>
    </S.Content>
  );
};

PushNotificationLayer.meta = {
  side: SidebarSide.center,
  key: PUSH_NOTIFICATION_LAYER,
};

export { PushNotificationLayer as Component };

export default PushNotificationLayer;
