import styled from 'styled-components';

import { getHeaderHeight } from '../header/Header.styled';

export const ContentWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.textMain};
  color: ${props => props.theme.textActive};
  font-size: ${props => props.theme.fontMD};
  width: 600px;

  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  overflow-y: auto;
  overflow-x: hidden;

  & .border {
    width: 100%;
    height: 0.5px;
    background-color: rgb(0 42 59 / 20%);
  }

  @media screen and (min-width: 481px) {
    border-radius: 8px;
    max-height: 660px;

    & .top-container {
      padding: ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_20}
        0;
    }

    & .options {
      display: grid;
      grid-row-gap: ${({ theme }) => theme.space_15};
      padding: ${({ theme }) => theme.space_25} ${({ theme }) => theme.space_20}
        0;
    }
  }

  @media screen and (max-width: 481px) {
    max-height: calc(100% - ${getHeaderHeight()}px);
    width: 100%;
    overflow-y: scroll;

    & .top-container {
      padding: ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_10}
        0 ${({ theme }) => theme.space_15};
    }

    & .options {
      display: grid;
      grid-row-gap: ${({ theme }) => theme.space_15};
      padding: ${({ theme }) => theme.space_25} ${({ theme }) => theme.space_10}
        ${({ theme }) => theme.space_30};
    }
  }

  & .info-content {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    box-sizing: border-box;
  }

  & .info-content.info-down {
    max-height: 0;
  }

  & .info-content.info-up {
    max-height: 100px;
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: ${({ theme }) => theme.space_15};
  color: ${({ theme }) => theme.main};
`;
export const Title = styled.div`
  font-size: ${props => props.theme.fontLG};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const StickyButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};

  @media screen and (min-width: 481px) {
    margin-top: ${({ theme }) => theme.space_30};
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;

  @media screen and (min-width: 481px) {
    grid-template-columns: repeat(3, 1fr);
    padding: ${({ theme }) => theme.space_20};
    grid-gap: ${({ theme }) => theme.space_15};
  }

  @media screen and (max-width: 481px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.space_15};
    padding: ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_10};

    & .acceptAll {
      grid-area: 2 / 1 / 3 / 3;
    }

    & .decline {
      grid-area: 1 / 1 / 2 / 2;
    }

    & .save {
      grid-area: 1 / 2 / 2 / 3;
    }
  }
`;

export const MainInfoContentItem = styled.div`
  border: 1px solid #e6eaeb;
  border-radius: 5px;
  transition: background-color 0.25s linear, color 0.25s linear,
    border-color 0.25s linear;
`;

export const ItemHeaderSection = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: ${({ theme }) => theme.space_5};

  .header {
    font-weight: ${({ theme }) => theme.fontBold};
  }
`;

export const ItemSwitcher = styled.div``;
export const ItemArrow = styled.div`
  position: relative;
  display: grid;
  justify-content: end;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;

  i {
    border: solid #004560;
    border-width: 0 2px 2px 0;
    display: block;
    width: 7px;
    height: 7px;
    cursor: pointer;
  }

  .arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`;

export const ItemContentArrowInfo = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.space_10};

  .info-content-item {
    background-color: #e4ecef;
    display: block;
    width: fit-content;
    border-radius: 9999px;
    color: ${({ theme }) => theme.inputDefaultText};
    white-space: nowrap;
    padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
    font-size: ${({ theme }) => theme.fontSM};
    margin: ${({ theme }) => theme.space_5};
  }
`;

export const ItemContentWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 50px 10px;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space_15};

  @media screen and (min-width: 481px) {
    padding: ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_25}
      ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_15};
  }

  @media screen and (max-width: 481px) {
    padding: ${({ theme }) => theme.space_15};
  }
`;
