import isArray from 'lodash/isArray';
import clone from 'lodash/clone';
import reduce from 'lodash/reduce';
import valuesIn from 'lodash/valuesIn';
import maxBy from 'lodash/maxBy';
import max from 'lodash/max';
import has from 'lodash/has';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import some from 'lodash/some';
import map from 'lodash/map';
import includes from 'lodash/includes';

import services from '@features/core/services';

import { TOKEN, SESSION_ID } from '@common/constants/cookie';
import {
  IBettingslip,
  IQueuedBettingslip,
  IBettingslipType,
  IEventData,
  ISelection,
  IRestoreSelection,
  IExpandLeg,
  IBettingSlipModelRange,
  LiveStatus,
} from '@common/interfaces';
import {
  getLiveStatus,
  isQuotenboost,
} from '@common/helpers/eventsHelper/eventStatusHelper';
import {
  getMarketMinimumSelections,
  isEnabled,
} from '@common/helpers/markets/marketModel';
import { hasValidOdds } from '@common/helpers/eventsHelper/predictionModel';
import { fixDecimals } from '@common/helpers/deviceUtil';
import {
  groupByEvent,
  groupByCategory,
  isFullyLive,
} from '@common/helpers/eventsHelper/selectionModel';
import { getLocks } from '@common/helpers/categories/categoriesModel';
import { BetView } from '@common/providers/bettingslip/types';

import Combinations from '../combinations/combinations';

export const getState = (bettingslip: IBettingslip): string => {
  return bettingslip?.state || '';
};

/**
 * getBanks
 * Return an events collection of bank events.
 * Empty if banks are disabled.
 *
 * @param {IBettingslip} bettingslip
 * @returns {Array} banksCount
 */
export const getBanks = (bettingslip: IBettingslip): number[] => {
  return bettingslip.banks;
};

/**
 * getSelectionsByEvent
 * Return an array of selections grouped by event.
 * e.g.: [selection, selection], [selection]]
 *
 * @param {IBettingslip} bettingslip
 * @returns {Array} selectionsByEvent
 */
export const getSelectionsByEvent = (
  bettingslip: IBettingslip,
): ISelection[][] => {
  return groupByEvent(bettingslip.selections);
};

/**
 * getEventsCount
 * getEventsCount, calculate events count
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} eventsCount
 */
export const getEventsCount = (bettingslip: IBettingslip): number => {
  return valuesIn(getSelectionsByEvent(bettingslip)).length;
};

export const groupEvents = (bettingslip: IBettingslip): Array<ISelection[]> => {
  return reduce(
    getSelectionsByEvent(bettingslip),
    (acc: Array<ISelection[]>, value: ISelection[]) => {
      acc.push(value);
      return acc;
    },
    [],
  );
};

/**
 * getSelectionsByRegularEvent
 * Same as getEventsSelections but return only non banks.
 *
 * @param {IBettingslip} bettingslip
 * @returns {Array} selectionsByEvent
 */
export const getSelectionsByRegularEvent = (
  bettingslip: IBettingslip,
): Array<ISelection[]> => {
  const groupedEvents = groupEvents(bettingslip);
  if (!getBanks(bettingslip).length) {
    return groupedEvents;
  }
  return filter(
    groupedEvents,
    eventSelections =>
      !includes(
        getBanks(bettingslip),
        parseInt(eventSelections['0'].event.id, 10),
      ),
  );
};

/**
 * getSelectionsByBankEvent
 * Same as getEventsSelections but return only banks.
 *
 * @param {IBettingslip} bettingslip
 * @returns {Array} selectionsByBankEvent
 */
export const getSelectionsByBankEvent = (
  bettingslip: IBettingslip,
): Array<ISelection[]> => {
  const groupedEvents = groupEvents(bettingslip);
  if (!getBanks(bettingslip).length) {
    return [];
  }
  return filter(groupedEvents, eventSelections =>
    includes(
      getBanks(bettingslip),
      parseInt(eventSelections['0'].event.id, 10),
    ),
  );
};

/**
 * expandLeg
 * Expand recursivelly a nested leg (array of arrays).
 * e.g.: leg: [selection1, selection2], [selection3]]
 * expanded_leg: [selection1, selection3], [selection2, selection3]]
 *
 * @param {IExpandLeg} leg
 * @returns {IExpandLeg[]} legs
 */
export const expandLeg = (leg: IExpandLeg): IExpandLeg[] => {
  // Store leg at first element of expanded legs.
  const legs = [leg];
  let x = 0;
  // Iterate expanded legs array while it grows.
  while (x < legs.length) {
    /* eslint-disable */
    leg = legs[x];
    /* eslint-enable */
    for (let y = 0; y < leg.length; y++) {
      const event = leg[y];
      // If item is an array.
      if (isArray(event)) {
        // Iterate selections.
        for (let z = 1; z < event.length; z++) {
          const selection = event[z];
          // Clone leg for every selection and push them to legs.
          legs.push(clone(leg));
          // Modify pushed legs so nested array become current selection.
          legs[legs.length - 1][y] = selection;
        }
        // Replace current.
        // eslint-disable-next-line prefer-destructuring
        legs[x][y] = legs[x][y][0];
      }
    }
    x++;
  }
  return legs;
};

/**
 * combine
 * Make combinations of given elements in legs of given size.
 * iteratorElement to be executed on every element.
 *
 * @param {Array<ISelection[]>} elements
 * @param {number} size
 * @param {Record} options
 * @param {Function} options.iteratorLeg
 * @param {Function}  options.iteratorElement
 */
export const combine = (
  elements: Array<ISelection[]>,
  size: number,
  options: {
    iteratorLeg?: (leg: Array<ISelection[]>) => Array<ISelection[]>;
    iteratorElement?: (leg: ISelection[], idx?: number) => ISelection[];
  },
): void => {
  // elements count.
  const total = elements.length;
  // e.g.: Gambit.combinations.get(2, 3); // [ [0,1],[0,2],[1,2] ]
  const combinations:
    | Array<number>
    | Array<Array<number>> = Combinations.get.call(Combinations, size, total);
  // Array that will be returned.
  const { iteratorLeg, iteratorElement } = options;
  for (let x = 0; x < combinations.length; x++) {
    // Get index combination array.
    const combination: number | Array<number> = combinations[x];
    let leg: Array<ISelection[]> = [];
    // Iterate combination indexes.
    for (let y = 0; y < combination.length; y++) {
      // Map combination index into given elements.
      // Previously Execute iterator if present.
      const element: ISelection[] = iteratorElement
        ? iteratorElement(elements[combination[y]], y)
        : elements[combination[y]];
      // Add to leg.
      leg.push(element);
    }
    // Execute iterator.
    if (iteratorLeg) {
      leg = iteratorLeg(leg);
    }
  }
};

/**
 * getLegsForSize
 * Returns array of possible legs for current selections, size, banks.
 * Make legs of groups of selections of same event.
 * Then expand those legs, so any leg has only one selection per event.
 *
 * @param {IBettingslip} bettingslip
 * @param {number} size
 * @returns {Array} legs
 */
export const getLegsForSize = (
  bettingslip: IBettingslip,
  size: number,
): Array<ISelection[]> => {
  let legs: Array<ISelection[]> = []; // Array that will be returned.
  const banksCount = getBanks(bettingslip).length; // Banks count.
  // Substract banks from size.
  const deltaSize = size - banksCount;
  if (deltaSize > 0) {
    // Good, we have a deltaSize to work with
    const selectionsByEvent = getSelectionsByRegularEvent(bettingslip); // Only non-bank events.
    const baseLeg = getSelectionsByBankEvent(bettingslip); // All legs must contain bank events.
    // Make all combinations of selections grouped by event.
    // Pass iterator to concat baseLeg (banks) to any leg and then expand them.
    // We need to expand legs with more than one selection per event.
    combine(selectionsByEvent, deltaSize, {
      iteratorLeg: (leg: Array<ISelection[]>) => {
        // Add banks.
        /* eslint-disable */
        leg = clone(baseLeg).concat(leg);
        /* eslint-enable */
        // Add expanded leg to allLegs.
        // We do this here in iterator for performance.
        // This way we don't need to flatten the array of expanded legs.
        legs = legs.concat(expandLeg(leg));
        // Return leg.
        // This has no purpose in this case. We are not using returned data.
        // Only doing stuff in iterator.
        return leg;
      },
    });
  }
  return legs;
};

/**
 * getCalculatedLegs
 *
 * @param {IBettingslip} bettingslip
 * @returns {number[]} calculateLegs
 */
export const getCalculatedLegs = (bettingslip: IBettingslip): number[] => {
  const legForSizes = reduce(
    bettingslip.size,
    (acc, size: number) => acc.concat(getLegsForSize(bettingslip, +size)),
    [] as Array<ISelection[]>,
  );

  const multipliedOdds = (leg): number =>
    reduce(leg, (sum, selection) => sum * parseFloat(selection.odds) || 1, 1);

  return reduce(
    legForSizes,
    (acc, leg) => acc.concat(multipliedOdds(leg)),
    [] as Array<number>,
  );
};

/**
 * getTotalQuotas
 *
 * @param {number[]} calculateLegs
 * @returns {string} totalQuotas
 */
export const getTotalQuotas = (calculateLegs): string =>
  fixDecimals(reduce(calculateLegs, (acc, quote) => acc + quote, 0));

/**
 * getTaxPercentForSize
 *
 * @param {IBettingslip} bettingslip
 * @param {number} size
 * @returns {number} taxPercent
 */
export const getTaxPercentForSize = (
  bettingslip: IBettingslip,
  size: number,
): number => {
  const fee = bettingslip.user.taxes;
  let taxPercent = 0;
  let key = '';
  if (isFullyLive(bettingslip.selections)) {
    key = 'live';
  } else {
    key = (size > 10 ? 10 : size)?.toString();
  }
  if (has(fee, key)) {
    taxPercent = fee[key] as number;
  }
  return taxPercent;
};

/**
 * getTotalAmount
 * getTotalAmount, returns user input(total amount)
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} totalAmount
 */
export const getTotalAmount = (bettingslip: IBettingslip): number => {
  return bettingslip.totalStake;
};

/**
 * getLegsCount
 * getLegsCount, returns user legs count
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} lestCount
 */
export const getLegsCount = (bettingslip: IBettingslip): number =>
  bettingslip.legsCount;

/**
 * isMultiway
 *  More than one selection per event.
 *
 * @param {IBettingslip} bettingslip
 * @returns {boolean} isMultiway
 */
export const isMultiway = (bettingslip: IBettingslip): boolean => {
  return bettingslip.selections.length > getEventsCount(bettingslip);
};

/**
 * countSuspendedSelections
 * Returns count of suspended selections.
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} count
 */
export const countSuspendedSelections = (bettingslip: IBettingslip): number => {
  return filter(
    bettingslip.selections,
    selection =>
      (selection.market &&
        !isEnabled(selection.market, getLiveStatus(selection.event))) ||
      !hasValidOdds(selection),
  ).length;
};

/**
 * Returns false when banks are invalid. Overrided in Terminal.
 *
 * @param {IBettingslip} bettingslip
 * @returns {boolean} validateBanks
 */
export const validateBanks = (bettingslip: IBettingslip): boolean => {
  if (bettingslip.bsMode === BetView.BETPACKER) {
    return false;
  }
  return getBanks(bettingslip).length >= getEventsCount(bettingslip);
};

export const hasSelections = (
  selections: ISelection[],
  betPackerSelections: ISelection[],
  restoredSelections?: ISelection[],
): boolean => {
  return (
    !!selections.length ||
    !!betPackerSelections.length ||
    !!restoredSelections?.length
  );
};

export const getSelections = (
  selections,
  betPackerSelections,
): ISelection[] => {
  return selections.length ? selections : betPackerSelections;
};

/**
 * getMinimumSelections
 * Return the highest market.minimumSelections setting of all selections.
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} minimumSelections
 */
export const getMinimumSelections = (bettingslip: IBettingslip): number => {
  const maxValue = maxBy(bettingslip.selections, (selection: ISelection) => {
    const { market, event } = selection;
    return getMarketMinimumSelections(market, event);
  });
  return maxValue
    ? getMarketMinimumSelections(maxValue.market, maxValue.event)
    : 0;
};

/**
 * getMinSize
 * Minimum size for a leg.
 * Param: [int min], [int min], [int min],
 * Never return less than min.
 * Actually, returns the max value of minimumSelections and passed arguments.
 *
 * @param {IBettingslip} bettingslip
 * @param {...any} rest
 * @returns {number} minSize
 */
export const getMinSize = (
  bettingslip: IBettingslip,
  ...rest: number[]
): number => {
  const values = rest;
  values.push(getMinimumSelections(bettingslip));
  return max(values) as number;
};

/**
 * getLockedSelections
 * Return an array of 2 or none selections locked for combinations.
 * According to categories locks.
 *
 * @param {IBettingslip} bettingslip
 * @returns {Array} labels
 */
export const getLockedSelections = (
  bettingslip: IBettingslip,
): ISelection[] => {
  // Group selections by categories.
  const selectionsByCategories = groupByCategory(bettingslip.selections);
  const k = keys(selectionsByCategories);
  const l = k.length;
  // Iterate groups.
  let i = l;
  while (i) {
    i--;
    const selection1 = selectionsByCategories[k[i]][0];

    const category1 = selection1.category;
    // Missing selection. Missing event and category.
    /* eslint-disable no-continue */
    // Second iteration.
    let ii = l;
    while (ii) {
      ii--;
      // Don't compare with itself.
      if (ii === i) {
        continue;
      }
      const selection2 = selectionsByCategories[k[ii]][0];
      const category2 = selection2.category;
      /* eslint-enable no-continue */
      // Check if CID of first iteration is present in locks of second iteration.
      if (
        category1 &&
        some(
          getLocks(category2),
          CID => parseInt(`${CID}`, 10) === parseInt(category1.id, 10),
        )
      ) {
        // Return locked selections. Break iteration.
        return [selection1, selection2];
      }
    }
  }
  return [];
};

/**
 * rangesIntersects
 * Utility. Calculate Ranges intersection.
 * Used to know when two selections don't collide cannot be true at the same time.
 */

export const rangesIntersects = (
  R1: IBettingSlipModelRange | IBettingSlipModelRange[] | null,
  R2: IBettingSlipModelRange | IBettingSlipModelRange[] | null,
): boolean => {
  if (!R1 || !R2) {
    return false;
  }
  // eslint-disable no-use-before-define
  if (isArray(R1)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return recursiveRangesIntersects(R1, R2);
  }
  if (isArray(R2)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return recursiveRangesIntersects(R2, R1);
  }
  //  As X0 <= X1 and Y0 <= Y1 for both ranges, whenever X0 or Y0 in one range is greather than
  //  X1 or Y1 (respectively) in the other one, there's not intersection
  const emptyIntersection =
    R1.X0 > R2.X1 || R2.X0 > R1.X1 || R1.Y0 > R2.Y1 || R2.Y0 > R1.Y1;
  return !emptyIntersection;
};

/**
 * Check if there are an intersection between ranges
 *
 * @param {Array} R1
 * @param {Record | Array} R2
 * @returns {boolean} rangesIntersects
 */
export const recursiveRangesIntersects = (
  R1: IBettingSlipModelRange[],
  R2: IBettingSlipModelRange | IBettingSlipModelRange[],
): boolean => {
  return some(R1, R => rangesIntersects(R, R2));
};

/**
 * canCombine
 * canCombine, can combine is used only for checking if system/combi is enabled
 * so we count disable selection and enabled selections
 * it CAN'T be used for validation ...
 *
 * @param {IBettingslip} bettingslip
 * @returns {boolean} canCombine
 */
export const canCombine = (bettingslip: IBettingslip): boolean => {
  const eventCount = getEventsCount(bettingslip);
  const minSize = getMinSize(bettingslip, 2);
  return !(
    eventCount < minSize ||
    getLockedSelections(bettingslip).length ||
    some(bettingslip.selections, selection =>
      isQuotenboost(selection.event?.label),
    )
  );
};

/**
 *  Can be a bet of type (single/combi/system).
 *
 * @param {bettingslip} bettingslip
 * @param {keyof typeof IBettingslipType} type
 * @returns {boolean} can
 */
export const can = (
  bettingslip: IBettingslip,
  type: keyof typeof IBettingslipType,
): boolean => {
  if (!bettingslip.selections.length) {
    return false;
  }
  if (type === 'single') {
    return getMinSize(bettingslip) === 1;
  }
  return canCombine(bettingslip);
};

/**
 * serializeQueued
 * serialize queued betting slip befor sending to server
 *
 * @param {string} bet_id
 * @returns {Promise} promise
 */
export const serializeQueued = (bet_id: string): IQueuedBettingslip => {
  return {
    token: services.cookie.get(TOKEN),
    session: services.cookie.get(SESSION_ID),
    lang: services.domainLang,
    is_total_amount: 1,
    bet_id,
  };
};

/**
 * checkBettingSlipType
 * checks betting slip type after adding/removing odds
 *
 * @param {IBettingslip} bettingslip
 * @param {boolean} shouldChange
 * @returns {keyof typeof IBettingslipType} bettingSlipType
 */
export const checkBettingSlipType = (
  bettingslip: IBettingslip,
  shouldChange: boolean,
): keyof typeof IBettingslipType => {
  let { type } = bettingslip;
  if (type !== 'single' && !can(bettingslip, type)) {
    type = 'single';
  }

  if (type === 'single' && !can(bettingslip, 'single')) {
    type = 'combi';
  }

  if (type === 'single' && shouldChange && can(bettingslip, 'combi')) {
    type = 'combi';
  }
  return type;
};

/**
 * restoreBettingslipFromList
 * restoreBettingslipFromList, update selection information with new data from event list
 * if event is not present in event list
 * we are adding mocked event with status disabled, market status disabled
 * timer stopped and period ended, so event is shown as disabled and ended after laoding list
 * and allow user to take a decision about removing it
 */

export const restoreBettingslipFromList = (
  selections: IRestoreSelection[],
  list: IEventData,
): {
  selections: ISelection[];
} => {
  return {
    selections: map(
      filter(selections || [], selection => {
        const marketId = `${selection.market_id ||
          selection.mid ||
          (selection.market && selection.market.id)}`;
        const predictionId = `${selection.prediction_id ||
          selection.pid ||
          selection.id}`;
        const market = list.markets && list?.markets[marketId];
        const prediction = list.predictions && list?.predictions[predictionId];

        return !!(market && prediction);
      }),
      (selection: IRestoreSelection) => {
        const marketId = `${selection.market_id ||
          selection.mid ||
          (selection.market && selection.market.id)}`;
        const predictionId = `${selection.prediction_id ||
          selection.pid ||
          selection.id}`;
        const eventId = `${selection.event_id ||
          selection.eid ||
          (selection.event && selection.event.id)}`;
        const marketUpdate = list.markets && list?.markets[marketId];
        const market = {
          id: marketId,
          live_status: LiveStatus.suspended,
          ...(marketUpdate || {}),
        };
        const predictionUpdate =
          list.predictions && list?.predictions[predictionId];
        const prediction = {
          id: predictionId,
          ...(predictionUpdate || {}),
        };
        const eventUpdate = list.events && list?.events[eventId];
        const event = {
          timer: {
            period_id: 'FINISHED',
            period_short: 'beendet',
            period_long: 'beendet',
            running: 0,
          },
          ...(eventUpdate || {
            id: eventId,
            live_status: LiveStatus.suspended,
          }),
          id: eventId,
        };
        const category =
          list.categories &&
          event.category_id &&
          list?.categories[event.category_id];

        return {
          ...prediction,
          market: { ...prediction, ...market },
          event: { ...prediction, ...event },
          category: {
            ...prediction,
            ...category,
          },
        } as ISelection;
      },
    ),
  };
};

export const getType = (bettingslip: IBettingslip): string => bettingslip.type;
