import React, { useEffect } from 'react';
import noop from 'lodash/noop';

import { GenericNotification } from '@features/core/commonComponents/genericNotification';
import { INotificationStyle } from '@features/core/commonComponents/genericNotification/GenericNotification.types';

import WithCountDown from '@common/hocs/WithCountDown';
import { toggleFooterNotification } from '@common/providers/application/useAppState';

import * as S from './FooterDynamicNotifier.styled';
import { IFooterNotifier } from './FooterDynamicNotifier.types';

const FooterDynamicNotifier = ({
  children,
  duration,
  countdown,
  dependency,
  needToShow,
  startCountDown,
  withArrowIcon = false,
}: IFooterNotifier): JSX.Element | null => {
  useEffect(() => {
    if (needToShow) {
      startCountDown(() => noop, duration / 1000);
    }
  }, [dependency, needToShow]);

  useEffect(() => {
    toggleFooterNotification(!!countdown && needToShow);
  }, [countdown, needToShow]);

  return countdown && needToShow ? (
    <S.NotifierWrapper id="footer-notification">
      <S.AnimatedDiv>
        <GenericNotification
          withoutIcon
          styleType={INotificationStyle.footerDynamicNotification}
        >
          {children}
        </GenericNotification>
        {withArrowIcon && <S.ActiveArrow />}
      </S.AnimatedDiv>
    </S.NotifierWrapper>
  ) : null;
};

export default WithCountDown(FooterDynamicNotifier, {});
