import React, { useEffect, useMemo } from 'react';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing/linkAliases';
import { Footer } from '@features/app/layout/footer';
import services, { useServices } from '@features/core/services';
import { FooterDynamicNotifier } from '@features/core/commonComponents/footerDynamicNotifier';

import { MENU_PANEL, WALLET_SWITCHER } from '@common/constants/dialogs';
import { HAS_TOTAL_ODDS_NOTIFIER } from '@common/constants/config';
import { ANIMATED_MENU, isIframeView } from '@common/helpers/appLayoutHelper';
import { isOpenDialog, getOpenDialog } from '@common/helpers/appState/dialogs';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { isEventDetailPages } from '@common/helpers/links';
import {
  getCalculatedLegs,
  getSelections,
  getTotalQuotas,
  hasSelections,
  isMultiway,
} from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { useUserState } from '@common/providers/user/useUserState';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { STATE_ACCEPTED } from '@common/providers/bettingslip/state';
import {
  closeSidebar,
  openSidebar,
  setStaticPageContent,
  toggleAnimatedComponent,
} from '@common/providers/application/useAppState';
import { useBets } from '@common/providers/bets/useBets';
import usePreviousPath from '@common/hooks/usePreviousPath';

const FooterWrapper: React.FC = () => {
  const { t } = useTranslation();
  const openBets = useBets(s => s.openBets);
  const user = useUserState(state => state.data);
  const isLoggedIn = useUserState(s => s.isLoggedIn);
  const { domainLang, isDesktopView } = useServices();
  const location = useLocation();
  const currentKey = getOpenDialog();
  const { pathname } = location;
  const isBettingSlip = isMatchWebRoutes(PageName.BETTING_SLIP);
  const prevPath = usePreviousPath(location);
  const isMenuOpen = isOpenDialog(MENU_PANEL);
  const isWalletSwitcherOpen = isOpenDialog(WALLET_SWITCHER);
  const selections = useBettingSlip(s => s.selections);
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const size = useBettingSlip(s => s.size);
  const banks = useBettingSlip(s => s.banks);
  const betPackerOdds = useBettingSlip(s => s.betPackerOdds);
  const state = useBettingSlip(s => s.state);

  useEffect(() => {
    setStaticPageContent({
      lang: domainLang,
      types: ['footer'],
    });

    setStaticPageContent({
      lang: domainLang,
      types: ['partners'],
    });

    setStaticPageContent({
      lang: domainLang,
      types: ['home_notifications'],
    });
  }, []);

  useEffect(() => {
    if (!currentKey && includes(pathname, 'cashout')) {
      toggleAnimatedComponent(ANIMATED_MENU);
    }
  }, [pathname, currentKey]);

  const isMenuActivePath = (): boolean => {
    return (
      (includes(pathname, 'user') ||
        isMatchWebRoutes(PageName.SUPPORT) ||
        isMatchWebRoutes(PageName.PROMOTIONS)) &&
      !includes(pathname, 'cashout')
    );
  };

  const isActiveItem = (page: string): boolean => {
    const isPrevLive = includes(prevPath, 'live');
    const mainActiveRules = (
      mainRule: boolean,
      additionalRule?: boolean,
    ): boolean => (mainRule || !!additionalRule) && !isMenuOpen;

    switch (page) {
      case 'live':
        return mainActiveRules(
          isMatchWebRoutes(PageName.LIVE),
          isEventDetailPages() && isPrevLive,
        );
      case 'user':
        return isMenuActivePath() || isMenuOpen;
      case 'sport':
        return mainActiveRules(
          isMatchWebRoutes(PageName.SPORT),
          isMatchWebRoutes(PageName.EVENTS_CATEGORY),
        );
      default:
        return mainActiveRules(includes(pathname, page));
    }
  };

  const hasLegs = useMemo(() => {
    const bettingSlipState = useBettingSlip.getState();
    return isMultiway(bettingSlipState);
  }, [size, banks]);

  const needToShow =
    !isBettingSlip &&
    ((selections.length > 1 && !hasLegs) ||
      (betPackerSelections.length > 1 && betPackerOdds));

  const calculateLegs = useMemo(() => {
    if (needToShow) {
      const bettingSlipState = useBettingSlip.getState();
      return getCalculatedLegs(bettingSlipState);
    }
    return null;
  }, [size, banks, selections, needToShow]);

  const totalOdds = useMemo(() => {
    if (needToShow) {
      return getTotalQuotas(calculateLegs);
    }
    return null;
  }, [calculateLegs, needToShow]);

  if (!isDesktopView) {
    const currentTotalOdds =
      betPackerSelections.length > 1 ? betPackerOdds : totalOdds;

    const currentSelections = getSelections(selections, betPackerSelections);

    return (
      <>
        {services.config.get(HAS_TOTAL_ODDS_NOTIFIER) &&
          state !== STATE_ACCEPTED && (
            <FooterDynamicNotifier
              withArrowIcon
              duration={3000}
              needToShow={needToShow}
              dependency={currentSelections.length}
            >
              <b>{`${toUpper(
                t('bettingslip.totalQuotas'),
              )}:  ${currentTotalOdds}`}</b>
            </FooterDynamicNotifier>
          )}
        <Footer
          items={[
            {
              key: 'sport',
              icon: {
                width: '18',
                height: '18',
                name: 'soccer',
              },
              onClick: (): void => {
                setLocation(PageName.SPORT);
              },
              active: isActiveItem('sport'),
              dataQa: 'footer-btn-sport',
            },
            {
              key: 'live',
              icon: {
                width: '18',
                height: '18',
                name: 'thunderbolt',
              },
              onClick: (): void => {
                setLocation(PageName.LIVE, {}, { forceReload: true });
              },
              active: isActiveItem('live'),
              dataQa: 'footer-btn-live',
            },
            {
              key: 'betting-slip',
              icon: {
                height: '18',
                width: '18',
                name: 'ticket',
                isMultiplyAttrs: true,
              },
              onClick: (): void => {
                setLocation(PageName.BETTING_SLIP);
              },
              badge: betPackerSelections?.length ? 1 : selections?.length || 0,
              disabled: !hasSelections(selections, betPackerSelections),
              active: isActiveItem('betting-slip'),
              dataQa: 'footer-btn-bettingSlip',
            },
            {
              key: 'cashout',
              icon: {
                width: '20',
                height: '20',
                name: 'euro-circle',
              },
              onClick: (): void => {
                setLocation(PageName.USER_CASHOUT);
              },
              badge: !user?.username ? 0 : openBets.length || 0,
              disabled: isIframeView() && !isLoggedIn,
              active: isActiveItem('cashout'),
              dataQa: 'footer-btn-cashout',
            },
            ...(!isIframeView()
              ? [
                  {
                    key: MENU_PANEL,
                    icon: {
                      width: '15',
                      height: '15',
                      name: 'hamburger-menu',
                      viewBox: '0 -53 384 384',
                    },
                    onClick: (): void => {
                      if (isWalletSwitcherOpen) {
                        closeSidebar(WALLET_SWITCHER);
                      }
                      openSidebar({ type: MENU_PANEL });
                    },
                    active: isActiveItem('user'),
                    dataQa: 'footer-btn-menu',
                  },
                ]
              : []),
          ]}
        />
      </>
    );
  }

  return null;
};

export default FooterWrapper;
