import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Icon } from '@features/core/commonComponents/icon';
import TransCustomLink from '@features/core/routing/TransCustomLink';

import * as S from './CheckboxInput.styled';
import { ICheckboxInputProps } from './CheckboxInput.types';

const CheckboxInput: React.FC<ICheckboxInputProps> = props => {
  const {
    validateStatus,
    checked,
    isDarkTheme,
    disabled,
    label,
    linkHref,
    ariaLabel,
    isLabelClickable,
    isBlueTheme,
    ...inputProps
  } = props;

  const { t } = useTranslation();
  const theme: {
    textMain: string;
    textActive: string;
  } = useTheme() as {
    textMain: string;
    textActive: string;
  };

  const onLabelClick = (event): void => {
    if (inputProps.onClick && !!isLabelClickable) {
      inputProps.onClick(event);
    }
  };

  return (
    <S.Root>
      <S.CheckboxWrapper>
        <S.StyledCheckbox
          {...inputProps}
          isError={validateStatus === 'error'}
          type="checkbox"
          disabled={!!disabled}
          isDarkTheme={!!isDarkTheme}
          checked={!!checked}
          isBlueTheme={!!isBlueTheme}
        />

        <S.IconWrapper
          checked={!!checked}
          isDarkTheme={!!isDarkTheme}
          isBlueTheme={!!isBlueTheme}
        >
          <Icon
            fill={
              isDarkTheme || isBlueTheme ? theme.textMain : theme.textActive
            }
            name="check"
            width="12px"
            height="12px"
          />
        </S.IconWrapper>
      </S.CheckboxWrapper>
      <S.LabelWrapper onClick={onLabelClick} isDarkTheme={!!isDarkTheme}>
        {label && (
          <>
            {linkHref && ariaLabel ? (
              <TransCustomLink
                text={label}
                href={linkHref}
                ariaLabel={t(ariaLabel)}
              />
            ) : (
              <>{t(label)}</>
            )}
          </>
        )}
      </S.LabelWrapper>
    </S.Root>
  );
};

export default CheckboxInput;
