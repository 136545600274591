export enum BetView {
  NORMAL = 'normal',
  BETPACKER = 'betPacker',
}

export interface ISetBettingSlipSuccessPayload {
  is_accepted: number;
  bet_id?: string;
  is_queued: number;
  queue_delay?: string;
  is_rejected: number;
}

export interface ISetBettingSlipErrorPayload {
  message?: string;
  code?: number;
  data?: {
    tag?: string;
    count?: number;
    value?: string;
    currency?: string;
    pids?: Record<string, string>;
    odds?: Record<string, string>;
    fromServer?: boolean;
    prevalidate?: boolean;
  };
}

export interface IBetPackerOdds {
  event_id: string;
  prediction_ids: number[];
}
