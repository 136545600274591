import styled from 'styled-components';

import { Icon } from '@features/core/commonComponents/icon';
import { TextSM } from '@features/core/styles/text.styled';
import { Button } from '@features/core/commonComponents/buttons';

const getWalletWidth = (
  isSingleWallet,
  isDesktop,
  isActive,
  isSwitcherPopup,
) => {
  if (isSwitcherPopup || !isActive) {
    return 'calc(100% - 20px)';
  }
  if (isDesktop && isActive && !isSingleWallet) {
    return 'auto';
  }
  if (isDesktop && isActive && isSingleWallet) {
    return '200px';
  }
  return 'auto';
};

export const WalletRow = styled.div<{
  isSingleWallet?: boolean;
  isSwitcherPopup: boolean;
  isActive?: boolean;
}>`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.inputDefaultText};
  border-radius: 5px;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  padding: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_10};
  width: ${({ isSingleWallet, theme, isActive, isSwitcherPopup }) =>
    getWalletWidth(isSingleWallet, theme.isDesktop, isActive, isSwitcherPopup)};
  height: auto;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  margin: ${({ theme, isSwitcherPopup, isSingleWallet }) =>
    !isSwitcherPopup && theme.isDesktop && isSingleWallet && theme.space_20};
  box-shadow: 0 0 5px 0 rgba(112 112 112 / 50%);
`;

export const WalletInfo = styled.div<{ isWalletLabelPresented: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-bottom: 1px solid rgb(0 69 96 / 20%);
  height: 42px;
  box-sizing: border-box;
  grid-gap: ${({ theme }) => theme.space_10};
  align-content: center;

  & svg {
    padding-bottom: ${({ isWalletLabelPresented, theme }) =>
      isWalletLabelPresented && theme.space_10};
  }
`;

export const WalletInfoIcon = styled(Icon)`
  position: relative;
`;

export const WalletInfoLabel = styled.span`
  overflow: hidden;
  font-weight: ${({ theme }) => theme.fontBold};

  & > span {
    display: flex;
    font-size: ${props => props.theme.fontXS};
    font-weight: ${({ theme }) => theme.fontRegular};

    & > small {
      display: block;
      font-size: ${props => props.theme.fontXS};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const WalletTotalBalance = styled.p`
  margin: 0 0 ${({ theme }) => theme.space_5};
  padding: ${({ theme }) => theme.space_10} 0 0;
  text-align: right;
  font-size: ${props => props.theme.fontH3};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const WalletBalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.space_5};
`;

export const WalletLabel = styled(TextSM)<{ hasTopMargin?: boolean }>`
  display: flex;
  align-items: center;
  ${({ hasTopMargin }) => hasTopMargin && 'margin-top: 5px'};

  & img {
    display: block;
    width: 13px;
    height: 16px;
  }
`;

export const LabelText = styled.span`
  margin-left: ${({ theme }) => theme.space_5};
`;

export const WalletCurrecy = styled.div`
  width: 12px;
  text-align: center;
`;

export const WalletBalance = styled(TextSM)<{ hasTopMargin?: boolean }>`
  text-align: right;
  ${({ hasTopMargin }) => hasTopMargin && 'margin-top: 5px'};
`;

export const ActiveWalletMark = styled.p`
  background-color: ${({ theme }) => theme.primaryBackground};
  color: ${({ theme }) => theme.textMain};
  padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
  border-radius: 10px;
  font-size: ${({ theme }) => theme.fontXS};
  text-transform: uppercase;
  height: fit-content;
  margin: ${({ theme }) => theme.space_10} 0;
  align-self: center;
  margin-bottom: ${({ theme }) => theme.space_10};
`;

export const ActiveWalletButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space_15};
  font-size: ${({ theme }) => theme.fontLG};
  color: ${({ theme }) => theme.textMain};
`;
