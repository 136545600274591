import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';
import { Button } from '@features/core/commonComponents/buttons';

export const SelfLockContainer = styled.div`
  margin: 0 0 ${({ theme }) => theme.space_5} 0;
  background: ${({ theme }) => theme.textActive};
`;

export const SelfLockWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  padding: ${({ theme }) => theme.space_10};
  font-size: ${props => props.theme.fontXS};
`;

export const SelfLockText = styled(TextNormal)`
  display: grid;
  align-items: center;
  color: ${({ theme }) => theme.textMain};
  font-size: ${props => props.theme.fontXS};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const ExclusionButton = styled(Button)`
  color: ${({ theme }) => theme.textMain};
  width: auto;
  padding: ${({ theme }) => theme.space_5};
  text-transform: none;
  margin-right: ${({ theme }) => theme.space_10};
  font-size: inherit;
  min-width: 65px;
  max-height: 25px;
  font-weight: ${({ theme }) => theme.fontBold};
`;
