import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { RETRY_MESSAGE } from '@common/constants/notifications';
import { ButtonType } from '@common/interfaces';
import { removeNotification } from '@common/providers/application/useAppState';

import * as S from '../TopNotification.styled';

const RetryMessage: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const onRetryMessage = (): void => {
    removeNotification(RETRY_MESSAGE);
  };

  return (
    <S.NotificationWrapper id="retry">
      <S.GridWrapper>
        <S.NotificationHeader>
          <Icon width="20" height="20" name="info-circle" />
          <S.HighlightBoldTitle id="retryTitle">
            {t('user.notifications.retryMessage')}
          </S.HighlightBoldTitle>
        </S.NotificationHeader>
        <StyledButton
          onClick={onRetryMessage}
          dataQa="btn-retry"
          buttonType={ButtonType.Blue}
          label="user.notifications.retryBtn"
          icon={{ width: 12, height: 12, name: 'repeat' }}
        />
      </S.GridWrapper>
    </S.NotificationWrapper>
  );
};

export default RetryMessage;
