/* eslint sonarjs/prefer-immediate-return: 0 */
import React, { useEffect, useState, useRef } from 'react';

import deleteTimeout from '@common/helpers/timeoutHelper';
import { TTimeout } from '@common/interfaces';
import { deleteInterval } from '@common/helpers/intervalHelper';

import { IWrapperComponent } from './WithCountDown.types';

export default (
  WrappedComponent: React.FC<IWrapperComponent>,
  options: { expireTime?: number },
): ((passedProps) => JSX.Element) => {
  const { expireTime } = options;

  const COUNT_DOWN_TIME = 5;
  const COUNTDOWN_INTERVAL = 1000;

  const WithCountDown = (passedProps): React.ReactElement => {
    const timeoutTimerRef = useRef<TTimeout>(null);
    const intervaCountlRef = useRef<TTimeout>(null);

    const [countdown, setCountDown] = useState(expireTime || COUNT_DOWN_TIME);

    const countdownHandler = (): void => {
      setCountDown(prevState => prevState - 1);
    };

    const startCountDown = (cb, delay?): void => {
      deleteInterval(intervaCountlRef.current);
      deleteTimeout(timeoutTimerRef.current);

      if (delay) {
        setCountDown(delay);
      }
      const delayTimer = `${delay || expireTime}100`;
      intervaCountlRef.current = setInterval(
        countdownHandler,
        COUNTDOWN_INTERVAL,
      );
      timeoutTimerRef.current = setTimeout(() => {
        cb();
        deleteInterval(intervaCountlRef.current);
      }, +delayTimer);
    };

    useEffect((): (() => void) => {
      return () => {
        deleteTimeout(timeoutTimerRef.current);
        deleteInterval(intervaCountlRef.current);
      };
    }, []);

    const props = {
      ...passedProps,
      countdown,
      startCountDown,
    };
    return <WrappedComponent {...props} />;
  };

  return WithCountDown;
};
