import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';
import some from 'lodash/some';
import { useTheme } from 'styled-components';

import { HeaderLogo } from '@features/core/theming/contexTheme';
import CustomLink from '@features/core/routing/CustomLink';
import { Routes } from '@features/app/router';
import { AppLayout } from '@features/app/layout/mobile';
import { Header } from '@features/app/layout/header';
import { FooterWrapper } from '@features/app/layout/footer';
import { Icon } from '@features/core/commonComponents/icon';
import {
  getPageURL,
  getStaticPageURL,
  PageName,
  StaticSlug,
  StaticSlugPageName,
} from '@features/core/routing/linkAliases';
import GlobalStyles from '@features/core/GlobalStyles';
import { UserInfo } from '@features/app/layout/userInfo';
import services, { useServices } from '@features/core/services';
import { InboxMessageCount } from '@features/inbox/inboxMessageCount';

import { MENU_PANEL, WALLET_SWITCHER } from '@common/constants/dialogs';
import { IS_LOGGED_IN } from '@common/constants/cookie';
import { isAndroid, isIOS } from '@common/helpers/deviceUtil';
import {
  getDefaultRouteName,
  setLocation,
} from '@common/providers/router/helper';
import {
  ANIMATED_MENU,
  ANIMATED_MENU_PANEL,
} from '@common/helpers/appLayoutHelper';
import { isOpenDialog } from '@common/helpers/appState/dialogs';
import { useRouterState } from '@common/providers/router/useRouterState';
import { useUserState } from '@common/providers/user/useUserState';
import {
  openSidebar,
  setFocus,
  toggleAnimatedComponent,
  useAppState,
} from '@common/providers/application/useAppState';
import { disableScroll, enableScroll } from '@common/helpers/toggleScroll';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { getWallets } from '@common/providers/payments/wallets/helpers';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { closeEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';

import * as S from './MobileLayout.styled';

const MobileLayout: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const { domainLang } = useServices();
  const location = useRouterState(s => s.location);
  const isLoggedIn =
    useUserState(state => state.isLoggedIn) ||
    !!services.cookie?.get(IS_LOGGED_IN);
  const wallet = useWalletsState(state => state.data);
  const user = useUserState(state => state.data);
  const withFocus = useAppState(state => state.withFocus);
  const openedSidebars = useAppState(state => state.openedSidebars);
  const isContentScrollDisabled = useAppState(s => s.isContentScrollDisabled);
  const isEventDetailSidebarOpen = !!useEventsListState(
    state => state.detail.selectedEvent,
  );

  const isSingleWallet =
    (wallet.length === 2 && some(wallet, ['type', IUserWalletType.bonus])) ||
    wallet.length < 2;
  const theme: { main: string } = useTheme();

  const isMenuSidebarOpen = isOpenDialog(MENU_PANEL);
  const isWalletSwitcherOpen = isOpenDialog(WALLET_SWITCHER);

  const animatedMenuHandler = (url: PageName): void => {
    toggleAnimatedComponent(ANIMATED_MENU_PANEL);
    setTimeout(() => {
      toggleAnimatedComponent(ANIMATED_MENU);
      setLocation(url);
    }, 300);
  };

  const onClickHandler = (
    url: PageName,
    e?: React.MouseEvent<HTMLElement>,
    query?: Record<string, string>,
  ): void => {
    if (e) {
      e.preventDefault();
    }
    if (isMenuSidebarOpen) {
      animatedMenuHandler(url);
      return;
    }
    if (isEventDetailSidebarOpen) {
      closeEventDetail();
    }

    setLocation(url || PageName.HOME, query);
  };

  const handleClickOnProfileIcon = (): void => {
    getWallets();
    if (!openedSidebars.length || isWalletSwitcherOpen) {
      openSidebar({ type: MENU_PANEL });
    }
  };

  const handleDocumentOnListener = ({ target }): void => {
    const el = target as HTMLInputElement;
    if (
      el.nodeName === 'INPUT' &&
      !includes(el.classList, 'react-datepicker') &&
      !includes(el.classList, 'virtual_input_field') &&
      !includes(el.classList, 'cancel-mobile-focus') &&
      !includes(el.classList, 'radio') &&
      isAndroid() &&
      el.name !== 'phone'
    ) {
      setFocus(true);
      const globalStylesElement = document.querySelector('.global-styles');
      if (globalStylesElement) {
        setTimeout((): void => {
          globalStylesElement.scrollTo({
            top: globalStylesElement.scrollTop + 25,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  };

  const handleDocumentOffListener = (): void => {
    setFocus(false);
  };

  useEffect(() => {
    if (isAndroid() || isIOS()) {
      window.addEventListener('focusin', handleDocumentOnListener);
      window.addEventListener('focusout', handleDocumentOffListener);
    }
    return () => {
      if (isAndroid() || isIOS()) {
        window.removeEventListener('focusin', handleDocumentOnListener);
        window.removeEventListener('focusout', handleDocumentOffListener);
      }
    };
  }, []);

  useEffect(() => {
    if (withFocus) {
      setFocus(false);
    }
  }, [location]);

  useEffect(() => {
    if (isContentScrollDisabled) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [isContentScrollDisabled]);

  const rightContent = isLoggedIn ? (
    <S.LoginInfo>
      {isLoggedIn && <UserInfo user={user} isSingleWallet={isSingleWallet} />}
      <S.UserLetter
        onClick={handleClickOnProfileIcon}
        data-qa="header-user-letter"
      >
        <Icon name="user" width="16" height="16" fill={theme.main} />
      </S.UserLetter>
      <InboxMessageCount pageType="mobile-profile-icon" />
    </S.LoginInfo>
  ) : (
    <S.LoginBtn
      aria-label={t('common.buttons.login')}
      id="login-btn"
      data-qa="header-btn-login"
      onClick={(): void => onClickHandler(PageName.LOGIN)}
    >
      <Icon name="login" width="28" height="30" viewBox="0 0 486 486" />
    </S.LoginBtn>
  );

  const leftContent = (
    <S.LogoContainer
      href={getPageURL(getDefaultRouteName())}
      onClick={(e): void => onClickHandler(PageName.HOME, e)}
      data-qa="header-logo"
      ariaLabel={t('common.links.logo')}
    >
      <HeaderLogo />
    </S.LogoContainer>
  );

  const centerContent = !isLoggedIn ? (
    <CustomLink
      onClick={
        (): void =>
          onClickHandler(PageName.RESPONSIBILITY, undefined, {
            slug: StaticSlug[StaticSlugPageName.AGE_WARNING][domainLang],
          })
        // eslint-disable-next-line react/jsx-curly-newline
      }
      href={getStaticPageURL(
        PageName.RESPONSIBILITY,
        StaticSlugPageName.AGE_WARNING,
      )}
      ariaLabel={t('common.links.minor_protection')}
    >
      <S.AgeBox>18+</S.AgeBox>
    </CustomLink>
  ) : null;

  const header = (
    <Header
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
      loggedIn={isLoggedIn}
    />
  );

  const footer = <FooterWrapper />;

  const center = <Routes />;

  return (
    <GlobalStyles
      hasFixedProfileMenu={false}
      withFocus={withFocus}
      className="global-styles"
      minHeight={window.screen.height}
    >
      <AppLayout header={header} center={center} footer={footer} />
    </GlobalStyles>
  );
};

export default MobileLayout;
