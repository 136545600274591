import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import merge from 'lodash/merge';
import replace from 'lodash/replace';

import services from '@features/core/services';

import { DEFAULT_LANGUAGE } from '@common/constants/config';
import { getLangFromUrl } from '@common/helpers/deviceUtil';

const themeName = process.env.THEME;

const compileStrings = (ctx): Resource => {
  const compiled = {};

  const translationsFiles = ctx.keys();

  const fileRegex = /(?<fileName>.+)\.(?<locale>\w+)\.json$/;

  const commonTranslations = filter(translationsFiles, key =>
    /common/.test(key),
  );
  // Loading common translations
  if (commonTranslations.length) {
    forEach(commonTranslations, file => {
      const { locale } = file.match(fileRegex).groups;
      compiled[locale] = ctx(file);
    });
  }

  // Loading additional translations for specific theme
  if (themeName) {
    const themeTranslations = filter(translationsFiles, key =>
      new RegExp(themeName).test(key),
    );
    if (themeTranslations.length) {
      forEach(themeTranslations, file => {
        const { locale } = file.match(fileRegex).groups;
        compiled[locale] = merge(compiled[locale], ctx(file));
      });
    }
  }

  return compiled;
};

let translations = compileStrings(
  require.context('../../../public/locale', false, /.*.json$/),
);

try {
  if (process.env.BOOM_DOMAIN) {
    translations = JSON.parse(
      replace(
        JSON.stringify(translations),
        /(www\.)?sportwetten\.de/g,
        process.env.BOOM_DOMAIN as string,
      ),
    );
  }
} catch (e) {
  services.logger.log(String(e));
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    /* default language when load the website in browser */
    lng: getLangFromUrl(),
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: services.config.get(DEFAULT_LANGUAGE) as string,
    /* debugger For Development environment */
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      bindI18n: 'languageChanged loaded',
      nsMode: 'default',
      useSuspense: true,
    },
  });

export default i18n;
