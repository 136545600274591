import some from 'lodash/some';
import filter from 'lodash/filter';

import { IUser } from '@common/interfaces';
import { IAccountFlags, IUserType } from '@common/interfaces/user/IUser';
import { useUserState } from '@common/providers/user/useUserState';
import { isIframeView } from '@common/helpers/appLayoutHelper';

export const isShopUser = (): boolean => {
  const user = useUserState.getState().data;
  return user?.type === IUserType.shop;
};

export const isOnlineUser = (): boolean => {
  const user = useUserState.getState().data;
  return user?.type === IUserType.www;
};

export const requiredUserData = [
  'maidenname',
  'region',
  'country_of_birth',
  'place_of_birth',
  'nationality',
  'street_name',
  'street_number',
  'zip_code',
  'city',
];

export const requiredUserDataNotDE = filter(
  requiredUserData,
  e => e !== 'region',
);

export const getRequiredFields = (user: IUser): string[] =>
  user?.countryCode === 'DE' ? requiredUserData : requiredUserDataNotDE;

/**
 * mustUpdateAccountData, checks if user missing data
 *
 * @param {IUser | null} user
 * @returns {boolean} return true if user have all required params
 */
export const mustUpdateAccountData = (user: IUser): boolean => {
  const requiredFileds = getRequiredFields(user);
  return (
    parseFloat(user.acceptedTAC) === 1 && some(requiredFileds, el => !user[el])
  );
};

export const isShouldChangePassword = (user: IUser | null): boolean =>
  user?.accountFlags === IAccountFlags.SHOULD_CHANGE_PASSWORD;

export const isIframeUser = (search: string, isLoggedIn: boolean): boolean => {
  const urlParams = new URLSearchParams(search);
  const signature = urlParams.get('signature');
  const player_id = urlParams.get('player_id');
  const affiliate_id = urlParams.get('affiliate_id');

  return !!(
    affiliate_id &&
    player_id &&
    signature &&
    !isLoggedIn &&
    isIframeView()
  );
};
