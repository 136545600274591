import React from 'react';
import noop from 'lodash/noop';

import { StyledButton } from '@features/core/commonComponents/buttons';
import ICountdownDoubleWhiteButtonProps from '@features/bettingslip/components/buttons/CountdownDoubleWhiteButton.types';
import {
  ButtonSize,
  ButtonType,
} from '@features/core/commonComponents/buttons/Button.styled';

import * as S from './CountdownDoubleWhiteButton.styled';

const CountdownDoubleWhiteButton: React.FC<ICountdownDoubleWhiteButtonProps> = ({
  withBackground = false,
  isWhite,
  isLoading = false,
  onClick,
  noLock = false,
  label,
  disabled,
  dataQa,
  type,
  counterOnClick,
  countdown,
  size,
  isLockIcon,
  areaLabel,
  areaLabelCountdown,
}) => {
  return (
    <S.ButtonContainer
      withBackground={!!withBackground}
      className={`${isWhite ? 'white-btn' : ''} ${
        isLoading ? 'is-waiting' : ''
      }`}
      disabled={!!disabled}
    >
      <StyledButton
        onClick={onClick || noop}
        label={label}
        disabled={!!disabled}
        dataQa={dataQa || ''}
        type={type || 'button'}
        size={size || ButtonSize.Big}
        icon={
          !noLock
            ? {
                className: 'icon-lock',
                name: 'lock-outlined',
                width: 18,
                height: 18,
              }
            : undefined
        }
        className={!noLock ? 'button-locked' : ''}
        area-label={areaLabel || ''}
      />
      {!!countdown && (
        <StyledButton
          onClick={counterOnClick || noop}
          label={`${countdown}`}
          icon={{
            name: 'light-clock',
            width: 15,
            height: 15,
          }}
          size={size || ButtonSize.Big}
          area-label={areaLabelCountdown || ''}
        />
      )}
      {!!isLockIcon && (
        <StyledButton
          onClick={noop}
          label=""
          icon={{
            name: 'lock-outlined',
            width: 15,
            height: 15,
            className: 'icon-lock',
          }}
          size={size || ButtonSize.Big}
          buttonType={ButtonType.Yellow}
          disabled
          className="lock-icon-btn"
        />
      )}
    </S.ButtonContainer>
  );
};

export default CountdownDoubleWhiteButton;
