import React, { useCallback } from 'react';

import {
  ScoreboardHoc,
  TotalScore,
  DartsScore,
} from '@features/events/components/scoreboards/scores';
import {
  ItemDiv,
  ScoreboardDiv,
  ScoreDiv,
  ScoreActiveDiv,
} from '@features/events/components/scoreboards/factoryScoreboards';
import AnimatedScoreValue from '@features/events/components/scoreboards/scores/common/AnimatedScoreValue';

import {
  getLastPeriodAway,
  getLastPeriodHome,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';
import {
  isBasketball,
  isDarts,
} from '@common/helpers/eventsHelper/eventTypeHelper';

import { IGenericScoreLastPartProps } from './GenericScoreLastPart.types';

const GenericScoreLastPart = (
  props: IGenericScoreLastPartProps,
): React.ReactElement => {
  const { event, score, period, sportName } = props;

  // const lastPeriodHome =
  //   usePrevious(getLastPeriodHome(score)) || getLastPeriodHome(score);
  // const lastPeriodAway =
  //   usePrevious(getLastPeriodAway(score)) || getLastPeriodAway(score);

  // useEffect(() => {
  //   // TODO: refactor beatScore logic
  //   if (lastPeriodHome !== getLastPeriodHome(score)) {
  //     beatScore(true);
  //   }

  //   if (lastPeriodAway !== getLastPeriodAway(score)) {
  //     beatScore(false);
  //   }
  // }, [score]);

  const generateItems = useCallback((): React.ReactElement => {
    if (isBasketball(event) && (Number(period) === 1 || Number(period) === 2)) {
      return (
        <ScoreboardDiv
          className={`generic-last-basketball-scoreboard ${sportName}`}
        >
          <ItemDiv>
            <ScoreActiveDiv total>
              <AnimatedScoreValue score={getLastPeriodHome(score)} />
            </ScoreActiveDiv>
            <ScoreActiveDiv total>
              <AnimatedScoreValue score={getLastPeriodAway(score)} />
            </ScoreActiveDiv>
          </ItemDiv>
        </ScoreboardDiv>
      );
    }
    if (isDarts(event)) {
      return <DartsScore {...props} />;
    }

    return (
      <ScoreboardDiv
        className={`scoreboard generic-last-scoreboard ${sportName}`}
      >
        <TotalScore {...score} />
        <ItemDiv>
          <ScoreDiv>
            <AnimatedScoreValue score={getLastPeriodHome(score)} />
          </ScoreDiv>
          <ScoreDiv>
            <AnimatedScoreValue score={getLastPeriodAway(score)} />
          </ScoreDiv>
        </ItemDiv>
      </ScoreboardDiv>
    );
  }, [score]);

  if (!score.periods) {
    return <></>;
  }

  return (
    <ScoreboardDiv
      className={`scoreboard-wrapper generic-last-list-scoreboard ${sportName}`}
    >
      {generateItems()}
    </ScoreboardDiv>
  );
};

export default ScoreboardHoc(GenericScoreLastPart);
