import styled from 'styled-components';

import { Icon } from '@features/core/commonComponents/icon';
import { TextLarge, TextNormal } from '@features/core/styles/text.styled';

export const Wrapper = styled.div`
  max-width: 360px;
  padding: ${({ theme }) => theme.space_20};
  background-color: ${({ theme }) => theme.textMain};
  position: relative;

  .auto-withdrowal-btn {
    color: ${({ theme }) => theme.highlightWrapperBackground};
    background-color: ${({ theme }) => theme.statusSuccess};

    &:hover {
      color: ${({ theme }) => theme.highlightWrapperBackground};
      background-color: ${({ theme }) => theme.statusSuccess};
    }
  }
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: ${({ theme }) => theme.space_15};
  right: ${({ theme }) => theme.space_15};
  width: 12px;
  height: 12px;
  color: inherit;
  cursor: pointer;
`;

export const CheckIcon = styled(Icon)`
  min-width: 20px;
  height: 20px;
  margin-right: ${({ theme }) => theme.space_10};
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.space_10};
  padding-right: ${({ theme }) => theme.space_20};
  box-sizing: border-box;
`;

export const TitleText = styled(TextLarge)`
  color: ${({ theme }) => theme.statusSuccess};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const Text = styled(TextNormal)`
  margin-bottom: ${({ theme }) => theme.space_20};
`;
