import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';
import { footerHeight } from '@features/app/layout/footer';

export const Message = styled.div<{ isError: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  background: ${({ theme }) => theme.textMain};
  color: ${({ theme, isError }) =>
    isError ? theme.statusError : theme.statusWon};
  padding: ${({ theme }) => theme.space_10};
  scroll-margin-bottom: ${({ theme }) =>
    theme.isDesktop ? 0 : footerHeight}px;
`;

export const ContentWrapper = styled(TextSM)`
  display: grid;
  grid-auto-flow: row;
  margin-left: ${({ theme }) => theme.space_10};
`;

export const Title = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.fontBold};
  margin-bottom: ${({ theme }) => theme.space_5};
`;

export const TextContent = styled.span`
  display: block;
  margin: 0;
  color: ${({ theme }) => theme.textActive};
`;
