/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { create } from 'zustand';
import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexOf';
import reject from 'lodash/reject';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';

import services from '@features/core/services';
import CustomError from '@features/core/error/error';

import { EVENT_SORTING_KEY } from '@common/constants/cookie';
import { RETRY_MESSAGE } from '@common/constants/notifications';
import {
  isAlreadyOpened,
  orderedLayers,
  orderedNotices,
} from '@common/helpers/appLayoutHelper';
import {
  ICMSContent,
  INotification,
  ISidebar,
  ISocket,
} from '@common/providers/application/types';
import {
  ApplicationProviderState,
  defaultState,
  SortingKeys,
} from '@common/providers/application/state';
import fetchDisabledMarkets from '@common/api/events/fetchDisabledMarkets';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { DynamicCalls } from '@common/api/api';
import { ICustomError } from '@common/interfaces';

const { API_SEO_CONTEXT, API_STATIC_PAGE_CONTENT } = DynamicCalls;

export const useAppState = create<ApplicationProviderState>(() => defaultState);

export const setSocketConnection = (payload: ISocket) => {
  useAppState.setState(() => ({
    socket: payload,
  }));
};

export const setEventsListSortingKey = (payload: SortingKeys) => {
  useAppState.setState(() => {
    if (payload === SortingKeys.TIME) {
      services.cookie.set(EVENT_SORTING_KEY, SortingKeys.TIME);
    } else {
      services.cookie.remove(EVENT_SORTING_KEY);
    }
    return { sortingKey: payload };
  });
};

export const startGettingStaticPageContent = (payload: string) => {
  useAppState.setState(state => ({
    staticPageContent: {
      ...state.staticPageContent,
      [payload]: { loading: true },
    },
  }));
};

export const setStaticPageContentToStore = (payload: {
  slug: string;
  data: ICMSContent;
}) => {
  useAppState.setState(state => {
    const { data = {}, slug } = payload;
    return {
      staticPageContent: {
        ...state.staticPageContent,
        [slug]: { ...data, loading: false },
      },
    };
  });
};

export const startGettingSEOContent = (slug: string) => {
  useAppState.setState(state => ({
    seoContent: {
      ...state.seoContent,
      [slug]: { loading: true },
    },
  }));
};

export const setSEOContentToStore = (payload: {
  slug: string;
  data: ICMSContent;
}) => {
  useAppState.setState(state => {
    const { data = {}, slug } = payload;
    return {
      seoContent: {
        ...state.seoContent,
        [slug]: { ...data, loading: false },
      },
    };
  });
};

export const setFocus = (payload: boolean) => {
  useAppState.setState(() => ({
    withFocus: payload,
  }));
};

export const beat = () => {
  useAppState.setState(() => ({
    beat: new Date().getTime(),
  }));
};

export const openSidebar = (payload: ISidebar) => {
  useAppState.setState(state => {
    const { type } = payload;
    const alreadyOpened = isAlreadyOpened(type, state.openedSidebars);

    if (!alreadyOpened) {
      return {
        openedSidebars: sortBy(state.openedSidebars.concat(payload), e =>
          indexOf(orderedLayers, e.type),
        ),
      };
    }
    return state;
  });
};

export const closeSidebar = (type: string) => {
  useAppState.setState(state => {
    const alreadyOpened = isAlreadyOpened(type, state.openedSidebars);
    if (alreadyOpened) {
      return {
        ...state,
        openedSidebars: sortBy(
          reject(state.openedSidebars, ['type', type]),
          e => indexOf(orderedLayers, e.type),
        ),
      };
    }
    return state;
  });
};

export const closeSidebars = () => {
  useAppState.setState(() => ({
    openedSidebars: [],
  }));
};

export const toggleSidebar = (type: string) => {
  useAppState.setState(state => {
    const alreadyOpened = isAlreadyOpened(type, state.openedSidebars);
    if (alreadyOpened) {
      return {
        openedSidebars: sortBy(
          reject(state.openedSidebars, ['type', type]),
          e => indexOf(orderedLayers, e.type),
        ),
      };
    }
    return {
      openedSidebars: sortBy(state.openedSidebars.concat({ type }), e =>
        indexOf(orderedLayers, e.type),
      ),
    };
  });
};

export const toggleAnimatedComponent = (payload: string) => {
  useAppState.setState(() => ({
    showComponent: payload,
  }));
};

export const showFooter = () => {
  useAppState.setState(() => ({
    showFooter: true,
  }));
};

export const hideFooter = () => {
  useAppState.setState(() => ({
    showFooter: false,
  }));
};

export const toggleContentScroll = (payload: 'disable' | 'enable') => {
  useAppState.setState(() => ({
    isContentScrollDisabled: payload === 'disable',
  }));
};

export const addNotification = (payload: INotification) => {
  useAppState.setState(state => {
    const { type, action } = payload;
    const alreadyOpened = isAlreadyOpened(type, state.notifications);
    const { openedRetryNotification } = useAppState.getState();

    if (
      !alreadyOpened ||
      (type === RETRY_MESSAGE && openedRetryNotification !== action)
    ) {
      return {
        ...state,
        notifications: sortBy(state.notifications.concat(payload), e =>
          indexOf(orderedNotices, e.type),
        ),
        openedRetryNotification: action,
      };
    }
    return state;
  });
};

export const removeNotification = (type: string) => {
  useAppState.setState(state => {
    const alreadyOpened = isAlreadyOpened(type, state.notifications);

    if (alreadyOpened) {
      const notification = find(state.notifications, ['type', type]);
      if (notification?.callback) {
        notification.callback();
      }

      return {
        notifications: sortBy(reject(state.notifications, ['type', type]), e =>
          indexOf(orderedNotices, e.type),
        ),
      };
    }
    return state;
  });
};

export const removeNotifications = () => {
  useAppState.setState(state => {
    forEach(state.notifications, e => e?.callback && e.callback());
    return {
      notifications: [],
    };
  });
};

export const toggleCategoryHeader = () => {
  useAppState.setState(state => ({
    hasCategoryHeader: !state.hasCategoryHeader,
  }));
};

export const addTopAppAdv = () => {
  useAppState.setState(() => ({
    hasTopAppBanner: true,
  }));
};

export const removeTopAppAdv = () => {
  useAppState.setState(() => ({
    hasTopAppBanner: false,
  }));
};

export const setOpenAppVersion = () => {
  useAppState.setState(() => ({
    openAppVersion: Date.now(),
  }));
};

export const toggleFooterNotification = (payload: boolean) => {
  useAppState.setState(() => ({
    hasFooterNotification: payload,
  }));
};

export const setTopLayoutHeight = (payload: number) => {
  useAppState.setState(() => ({
    topLayoutHeight: payload,
  }));
};

export const getMarketsToExclude = async (): Promise<void> => {
  const response = await fetchDisabledMarkets();

  if (response instanceof CustomError) {
    services.logger.log(response.message);
  } else {
    useAppState.setState(() => ({
      marketsToExclude: response,
    }));
  }
};

export const setStaticPageContent = async (payload: {
  lang: string;
  types: string[];
}): Promise<void> => {
  const { lang, types } = payload;
  const queue: Promise<ICMSContent & ICustomError>[] = reduce(
    types,
    (acc: Promise<ICMSContent & ICustomError>[], item: string) => {
      startGettingStaticPageContent(item);
      return [
        ...acc,
        apiHandler<ICMSContent>(
          { method: MethodType.Get },
          {
            apiUrl: API_STATIC_PAGE_CONTENT,
            apiData: {
              dynamicLanguage: lang,
              dynamicType: item,
            },
          },
        ),
      ];
    },
    [],
  );
  const response = await Promise.all(queue);
  forEach(response, (item, index) => {
    if (item instanceof CustomError) {
      services.logger.log(item.message);
    } else {
      setStaticPageContentToStore({ slug: types[index], data: item });
    }
  });
};

export const setSEOContent = async (payload: {
  lang: string;
  types: Array<string>;
}): Promise<void> => {
  const { types, lang } = payload;
  const queue: Promise<ICMSContent & ICustomError>[] = reduce(
    types,
    (acc: Promise<ICMSContent & ICustomError>[], item: string) => {
      startGettingSEOContent(item);
      return [
        ...acc,
        apiHandler<ICMSContent>(
          { method: MethodType.Get },
          {
            apiUrl: API_SEO_CONTEXT,
            apiData: {
              dynamicLanguage: lang,
              dynamicType: item,
            },
          },
        ),
      ];
    },
    [],
  );
  const response = await Promise.all(queue);
  forEach(response, (item, index) => {
    if (item instanceof CustomError) {
      services.logger.log(item.message);
    } else {
      setSEOContentToStore({ slug: types[index], data: item });
    }
  });
};
