import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

const bettingLongSuspendReasonStyles = `
    width: max-content;
`;

const getBettingSlipDesktopTextStyles = ({ theme }) => `
  flex-direction: column;
  font-size: ${theme.fontXS};
  align-items: flex-start;
  padding: 0 ${theme.space_5};
`;

const bettingSlipIconWrapperStyles = `  
  min-width: 26px;
  max-width: 26px;
 `;

export const Wrapper = styled.div<{
  marketKeylistCount?: number;
  isBettingSlip?: boolean;
  isCashout?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ marketKeylistCount, theme }) =>
    theme.isDesktop && marketKeylistCount && marketKeylistCount * 210 - 15}px;
  margin-right: ${({ theme }) => theme.space_5};

  @media screen and (min-width: 1280px) and (max-width: 1335px) {
    width: 555px;
  }

  ${({ isBettingSlip, isCashout }) =>
    (isBettingSlip || isCashout) && bettingLongSuspendReasonStyles};

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const getContentMargin = ({ theme }) => `margin: ${theme.space_10} 0;`;

export const Content = styled.div<{
  isBettingSlip: boolean;
  isDefault: boolean;
  isCashout: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isDefault }) => (isDefault ? 'max-content' : '100%')};
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  border-radius: 3px;
  min-height: ${({ isBettingSlip }) => (isBettingSlip ? '26px' : '28px')};
  max-height: ${({ isBettingSlip }) => (isBettingSlip ? '26px' : '28px')};

  ${({ isBettingSlip, isCashout }) =>
    !isBettingSlip && !isCashout && getContentMargin};

  @media (max-width: 768px) {
    justify-content: flex-start;

    ${({ isDefault }) => !isDefault && 'min-width: 100%;'}
  }

  @media (min-width: 768px) {
    width: max-content;
  }

  @media (min-width: 1280px) {
    margin-left: ${({ isBettingSlip, theme }) =>
      !isBettingSlip && theme.space_10};
  }
`;

export const TextWrapper = styled.div<{ isBettingSlip: boolean }>`
  min-height: ${({ isBettingSlip }) => (isBettingSlip ? '26px' : '28px')};
  max-height: ${({ isBettingSlip }) => (isBettingSlip ? '26px' : '28px')};
  border-left: 1px #005374 solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const getTextBeforeStyles = ({ theme }) =>
  `content: '-'; margin-right: ${theme.space_5};`;

export const Text = styled(TextSM)<{
  isBettingSlip: boolean;
  isCashout: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${({ theme }) => theme.space_5};
  color: ${({ theme }) => theme.inputDefaultText};
  font-size: ${({ theme }) => theme.fontXS};

  font-size: ${({ isBettingSlip, theme }) => isBettingSlip && theme.fontXS};

  & span:first-of-type {
    font-weight: ${({ theme }) => theme.fontBold};
  }

  & span:nth-of-type(2n) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;

    @media (min-width: 340px) {
      max-width: 85px;
    }

    @media (min-width: 400px) {
      max-width: 100px;
    }

    @media (min-width: 420px) {
      font-size: ${({ theme }) => theme.fontSM};
    }

    @media (min-width: 440px) {
      max-width: 120px;
    }

    @media (min-width: 768px) {
      max-width: min-content;
      font-size: ${({ theme, isCashout }) => !isCashout && theme.fontMD};
      margin-left: ${({ isBettingSlip, isCashout, theme }) =>
        !isBettingSlip && !isCashout && theme.space_5};

      &::before {
        ${({ isBettingSlip, isCashout }) =>
          !isBettingSlip && !isCashout && getTextBeforeStyles};
      }
    }
  }

  @media (max-width: 340px) {
    padding: 0 ${({ theme }) => theme.space_5};
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 ${({ theme }) => theme.space_5};
    font-size: ${({ theme }) => theme.fontMD};

    ${({ isBettingSlip, isCashout }) =>
      (isBettingSlip || isCashout) && getBettingSlipDesktopTextStyles};
  }
`;

export const IconImage = styled.img<{ width: number }>`
  width: ${({ width }) => width}px;
  max-height: 18px;
`;

export const DefaultIconWrapper = styled.div`
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export const IconWrapper = styled.div<{ isBettingSlip: boolean }>`
  min-width: 28px;
  max-width: 28px;
  padding: ${({ theme }) => theme.space_5} 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isBettingSlip }) => isBettingSlip && bettingSlipIconWrapperStyles};
`;
