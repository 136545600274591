import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';
import range from 'lodash/range';
import map from 'lodash/map';

import { useServices } from '@features/core/services';
import { Icon } from '@features/core/commonComponents/icon';

import numeral from '@common/helpers/numeralHelper';
import { usePaymentsState } from '@common/providers/payments/usePayments';

import * as S from './VirtualKeyboard.styled';
import IVirtualKeyboard from './VirtualKeyboard.types';

const separators = new Map();
separators.set('de', { value: ',', label: ',' });
separators.set('en', { value: '.', label: '.' });

const VirtualKeyboard: React.FC<IVirtualKeyboard> = props => {
  const { value, maxValue, shouldReplace, onChange } = props;
  const hasPaymentInfoPopup = usePaymentsState(s => s.hasPaymentInfoPopup);
  const { t } = useTranslation();
  const { domainLang } = useServices();
  const separator = separators.get(domainLang) || separators.get('de');

  const handleChange = useCallback(
    (sign): void | null => {
      if (shouldReplace && sign !== separator.value) {
        return onChange(sign);
      }

      const isFloat = includes(value, separator.value);
      const reachedPrecisionLimit = value[value.length - 3] === separator.value;
      const shouldSkipExcessZero =
        sign === '0' && value[value.length - 2] === separator.value;

      // prevent multiple floating points
      if (isFloat && sign === separator.value) {
        return null;
      }

      // limits precision with 2 digits
      if (isFloat && reachedPrecisionLimit) {
        return null;
      }

      // skip unneeded insertion 2.7 => 2.70
      if (isFloat && shouldSkipExcessZero) {
        return null;
      }

      const shouldRemoveLeadingZero = value === '0' && sign !== separator.value;
      const newValue = shouldRemoveLeadingZero ? sign : value.concat(sign);

      return onChange(
        !maxValue || (numeral(newValue).value() || 0) < maxValue
          ? newValue
          : String(maxValue),
      );
    },
    [shouldReplace, separator, value, maxValue],
  );

  const handleDelete = (): void => {
    const countToRemove = value[value.length - 2] === '.' ? 2 : 1;
    const result = value.slice(0, value.length - countToRemove);
    onChange(result || '0');
  };

  const renderButton = ({ digit }): React.ReactElement => {
    return (
      <S.Button
        key={digit}
        onClick={(): void | null => handleChange(String(digit))}
        data-qa={`virtual-keyboard-${digit}`}
        aria-label={`${digit} ${t('common.buttons.button')}`}
      >
        {digit}
      </S.Button>
    );
  };

  return (
    <S.Root
      data-qa="virtual-keyboard-wrapper"
      hasPaymentInfoPopup={hasPaymentInfoPopup}
    >
      {map(range(1, 6), digit => renderButton({ digit }))}
      <S.Button
        onClick={handleDelete}
        data-qa="virtual-keyboard-delete"
        aria-label={t('common.buttons.delete')}
      >
        <Icon name="keyboard-delete" height="26px" width="30px" />
      </S.Button>
      {map(range(6, 10), digit => renderButton({ digit }))}
      <S.Button
        onClick={(): void | null => handleChange('0')}
        data-qa="virtual-keyboard-0"
        aria-label={t('common.buttons.zero')}
      >
        0
      </S.Button>
      <S.Button
        onClick={(): void | null => handleChange(separator.value)}
        data-qa="virtual-keyboard-separator"
        aria-label={t('common.buttons.separator')}
      >
        {separator.label}
      </S.Button>
    </S.Root>
  );
};

export default VirtualKeyboard;
