import filter from 'lodash/filter';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import i18next from 'i18next';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';

import services from '@features/core/services';

import * as cookies from '@common/constants/cookie';
import { MAX_BS_EVENTS } from '@common/constants/config';
import numeral from '@common/helpers/numeralHelper';
import {
  IBettingslip,
  ISelection,
  IRestoreSelection,
} from '@common/interfaces';
import {
  getSelectionsByEvent,
  checkBettingSlipType,
  getEventsCount,
  restoreBettingslipFromList,
  hasSelections,
} from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import {
  calculateLegsCount,
  calculateStake,
} from '@common/helpers/bettingSlipHelper/bettingSlipCalculationModel';
import {
  setBettingSlipData,
  setBettingSlipError,
  setStake,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import fetchEventList from '@common/api/events/fetchEventsList';
import { getLiveStatus, isEnabled } from '@common/helpers/markets/marketModel';
import { hasValidOdds } from '@common/helpers/eventsHelper/predictionModel';

export enum BettingSlipActions {
  REMOVE_SELECTION = 'REMOVE_SELECTION',
  ADD_BANK_EVENT = 'ADD_BANK_EVENT',
  REMOVE_BANK_EVENT = 'REMOVE_BANK_EVENT',
  SET_BETTING_SLIP_TYPE = 'SET_BETTING_SLIP_TYPE',
}

export const makeBS = (
  currentBS: IBettingslip,
  newBS: IBettingslip,
  size: number[],
  actionType?: BettingSlipActions,
  payload?: ISelection,
): IBettingslip => {
  const betSize = getEventsCount(currentBS);
  let { banks } = newBS;
  let newSize = [...size];
  const type = checkBettingSlipType(
    newBS,
    betSize === 1 && getEventsCount(newBS) === 2,
  );
  if (type === 'single') {
    newSize = [1];
  } else if (type === 'combi') {
    newSize = [getSelectionsByEvent(newBS).length];
  }
  if (actionType) {
    if (
      actionType === BettingSlipActions.REMOVE_SELECTION &&
      payload &&
      includes(banks, parseFloat(payload.event.id))
    ) {
      banks = filter(banks, e => e !== parseFloat(payload.event.id));
    } else if (
      actionType === BettingSlipActions.REMOVE_SELECTION &&
      type === 'system' &&
      newBS.size.length >= 1
    ) {
      newSize = uniq(
        reduce(
          newBS.size,
          (acc, currentSize) => {
            if (currentSize > getSelectionsByEvent(newBS).length) {
              return acc.concat([currentSize - 1]);
            }
            return acc.concat([currentSize]);
          },
          [] as number[],
        ),
      );
    } else if (
      type === 'system' &&
      (actionType === BettingSlipActions.SET_BETTING_SLIP_TYPE ||
        actionType === BettingSlipActions.ADD_BANK_EVENT ||
        actionType === BettingSlipActions.REMOVE_BANK_EVENT)
    ) {
      newSize = [];
    }
  }
  const newState = {
    ...newBS,
    type,
    size: newSize,
    banks,
    legsCount: calculateLegsCount({ ...newBS, type, size: newSize, banks }),
  };

  return {
    ...newState,
    ...calculateStake(
      newState,
      numeral(newState.totalStake).format(`0,0.00`),
      true,
    ),
  };
};

export const validateSelections = async (payload: string): Promise<void> => {
  const bettingSlipState = useBettingSlip.getState();
  const key = payload === 'update' ? 'selections' : 'restoredSelections';
  const selectionsToValidate = bettingSlipState[key] as IRestoreSelection[];

  try {
    const eventsIds = reduce(
      selectionsToValidate,
      (ids, selection) => {
        ids.push(selection.event_id || selection.eid || selection.event.id);
        return ids;
      },
      [] as string[],
    );
    if (eventsIds.length) {
      const list = await fetchEventList({ events: eventsIds });
      const restoredSelectionsFull = restoreBettingslipFromList(
        selectionsToValidate,
        list,
      );
      return setBettingSlipData({
        selections: restoredSelectionsFull.selections,
        restoredSelections: [],
      });
    }
  } catch (e) {
    services.logger.error(e as string);
  }
  return setBettingSlipData({});
};

export const handleSubmitBettingSlipError = (response): void => {
  if (
    parseFloat(response.code || '') === 2706 ||
    parseFloat(response.code || '') === 2801
  ) {
    validateSelections('update');
  }

  if (parseFloat(response.code || '') === 2116) {
    // max limits
    const amount =
      response.message.match(/\d+(\.\d+)?/) &&
      response.message.match(/\d+(\.\d+)?/)?.[0];

    setStake({ amount: amount as string });
  }

  if (
    parseFloat(response.code || '') === 2932 ||
    parseFloat(response.code || '') === 2245 ||
    parseFloat(response.code || '') === 2596
  ) {
    response.message = `${i18next.t('bettingslip.err_oasis')}`;
  }

  if (parseFloat(response.code || '') === 2333) {
    response.message = `${i18next.t('bettingslip.err_blocked')}`;
  }

  if (parseFloat(response.code || '') === 1000) {
    response.message = `${i18next.t(
      'bettingslip.too_many_prediction_of_event',
    )}`;
  }
  setBettingSlipError(
    assign(response, {
      data: assign({ fromServer: true, ...(response.data || {}) }),
    }),
  );
};

export const saveBettingSlip = (): void => {
  const {
    size,
    totalStake,
    type,
    selections,
    betPackerSelections,
    restoredSelections,
  } = useBettingSlip.getState();

  try {
    if (hasSelections(selections, betPackerSelections, restoredSelections)) {
      const value = JSON.stringify({
        size,
        totalStake,
        stake: totalStake,
        type,
      });
      services.cookie.set(cookies.BETTING_SLIP, value);
      forEach(new Array(services.config.get(MAX_BS_EVENTS)), (event, index) => {
        if (services.cookie.get(`s_${index}`)) {
          services.cookie.remove(`s_${index}`);
        }
      });
      forEach(selections, (selection, index) => {
        services.cookie.set(
          `s_${index}`,
          JSON.stringify({
            event_id: selection.event.id,
            id: selection.id,
            market_id: selection.market_id || selection.market.id,
          }),
        );
      });
    }
  } catch (e) {
    services.logger.error(e as string);
  }
};

export const restoreBettingSlip = async (): Promise<void> => {
  const savedSlip = services.cookie.get(cookies.BETTING_SLIP);
  if (savedSlip) {
    try {
      const parsedSlip = JSON.parse(savedSlip);
      const restoredSelections: Array<{
        event_id: string;
        id: string;
        market_id: string;
      }[]> = [];
      forEach(new Array(services.config.get(MAX_BS_EVENTS)), (event, index) => {
        const selection = services.cookie.get(`s_${index}`);
        if (selection) {
          restoredSelections.push(JSON.parse(selection));
        }
      });
      try {
        if (parsedSlip.restoredSelections.length) {
          setBettingSlipData({
            ...parsedSlip,
          });
        }
      } catch (e) {
        services.logger.error(e as string);
      }
      if (restoredSelections.length && !parsedSlip.restoredSelections) {
        setBettingSlipData({
          ...parsedSlip,
          restoredSelections,
        });
      }
    } catch (e) {
      services.logger.error(e as string);
    }
  }
  setTimeout(() => validateSelections('restore'));
  forEach(new Array(services.config.get(MAX_BS_EVENTS)), (event, index) => {
    services.cookie.remove(`s_${index}`);
  });
  if (services.cookie.get(cookies.BETTING_SLIP)) {
    services.cookie.remove(cookies.BETTING_SLIP);
  }
};

export const removeDisabledSelectionsHelper = (selections): ISelection[] =>
  filter(
    selections,
    selection =>
      isEnabled(selection.market, getLiveStatus(selection.event)) &&
      hasValidOdds(selection),
  );
