import React, { useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Sidebar } from '@features/app/layout/sidebar';
import { StaticFooter } from '@features/app/layout/footer';
import { BettingSlipBottomNotification } from '@features/bettingslip/components/states';
import { TopNotification } from '@features/app/layout/topNotification';
import { BlockLoader } from '@features/core/commonComponents/loaders';
import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';
import { Detail } from '@features/events/components/detail';

import { WALLET_SWITCHER } from '@common/constants/dialogs';
import * as cookies from '@common/constants/cookie';
import { scrollTo } from '@common/helpers/scrollHelper';
import { isNativeApp, isWebView, isIframe } from '@common/helpers/links';
import {
  ANIMATED_MENU,
  hasFooter,
  hasHeader,
  hasNativeAdv,
  hasStaticFooter,
} from '@common/helpers/appLayoutHelper';
import { getOpenDialog } from '@common/helpers/appState/dialogs';
import { createExpiresStamp, isIOS } from '@common/helpers/deviceUtil';
import { EventListTypes } from '@common/interfaces';
import { useUserState } from '@common/providers/user/useUserState';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { resetBetPackerData } from '@common/providers/bettingslip/useBettingSlip';
import {
  setSportCategoriesFilter,
  useLiveCategories,
} from '@common/providers/events/liveCategories/useLiveCategories';
import {
  addTopAppAdv,
  removeTopAppAdv,
  setTopLayoutHeight,
  toggleAnimatedComponent,
  useAppState,
} from '@common/providers/application/useAppState';
import { isMatchWebRoutes } from '@common/providers/router/helper';
import useElementSize from '@common/hooks/useElementSize';

import { ApplicationAdvBanner } from '../topMobileAppAdvBanner';

import * as S from './AppLayout.styled';
import { IAppLayoutProps } from './AppLayout.types';

const AppLayout: React.FC<IAppLayoutProps> = ({ header, center, footer }) => {
  const mainContentRef = useRef<HTMLDivElement>(null);
  const topLayoutElement = useElementSize();
  const topLayoutRef = topLayoutElement.elementRef;
  const topLayoutHeight = topLayoutElement.elementSize.height || 0;
  const switchAccLoading = useUserState(state => state.switchAccountLoading);
  const openedSidebars = useAppState(state => state.openedSidebars);
  const hasTopAppBanner = useAppState(state => state.hasTopAppBanner);
  const isShowFooter = useAppState(state => state.showFooter);
  const { pathname, search } = useLocation();
  const isBettingSlip = isMatchWebRoutes(PageName.BETTING_SLIP);
  const nativeApp = isNativeApp(search);
  const inIframe = isIframe(search);
  const webView = isWebView(search);
  const currentKey = getOpenDialog();
  const timeFilter = useLiveCategories(s => s.sportCategoriesTree.filter);
  const selectedEvent = useEventsListState(state => state.detail.selectedEvent);
  const isWalletSwitcher = currentKey === WALLET_SWITCHER;
  const history = useHistory();
  const isStaticFooter = hasStaticFooter();
  const hasNativeAdvBanner =
    hasNativeAdv(nativeApp || webView || inIframe) || hasTopAppBanner;

  const onCloseBannerHandler = (): void => {
    services.cookie.set(cookies.IS_TOP_ADV_BANNER_CLOSE, '1', {
      expires: createExpiresStamp(60 * 60 * 24),
    });
    removeTopAppAdv();
  };

  useEffect(() => {
    if (!hasNativeAdvBanner) {
      removeTopAppAdv();
    } else {
      addTopAppAdv();
    }
  }, [hasNativeAdvBanner]);

  useEffect(() => {
    resetBetPackerData();
  }, [history.location]);

  useEffect(() => {
    toggleAnimatedComponent(ANIMATED_MENU);
    if (isIOS()) {
      document.head
        .querySelector('meta[name="viewport"]')
        ?.setAttribute(
          'content',
          'width=device-width, initial-scale=1, maximum-scale=1',
        );
    }
  }, []);

  useEffect(() => {
    if (!isBettingSlip) {
      scrollTo(window, {
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (timeFilter && !isMatchWebRoutes(PageName.EVENTS_CATEGORY)) {
      setSportCategoriesFilter({ filter: '' });
    }
  }, [currentKey]);

  useEffect(() => {
    setTopLayoutHeight(topLayoutHeight);
  }, [topLayoutHeight]);

  return (
    <S.LayoutRoot>
      <S.LayoutTop ref={topLayoutRef} data-qa="layout-header">
        {hasNativeAdvBanner && (
          <S.DownloadAppNotification>
            <ApplicationAdvBanner onClose={onCloseBannerHandler} />
          </S.DownloadAppNotification>
        )}

        {hasHeader() && <S.AppHeader>{header}</S.AppHeader>}

        <S.LayoutNotifications>
          {!openedSidebars.length && !nativeApp && <TopNotification />}
        </S.LayoutNotifications>
      </S.LayoutTop>

      <S.LayoutCenter>
        {currentKey && <Sidebar />}

        <BlockLoader
          childShouldHaveOpacity
          showLoader={!isWalletSwitcher && switchAccLoading}
        >
          <S.MainContent
            ref={mainContentRef}
            data-qa="main-content"
            className="main-content"
          >
            {center}

            {selectedEvent && (
              <S.EventDetailWrapperMob topLayoutHeight={topLayoutHeight}>
                <Detail
                  id={selectedEvent.eventId}
                  listType={EventListTypes.detail}
                />
              </S.EventDetailWrapperMob>
            )}

            <BettingSlipBottomNotification />

            {isStaticFooter && <StaticFooter />}
          </S.MainContent>
        </BlockLoader>
      </S.LayoutCenter>

      <S.LayoutBottom data-qa="layout-footer">
        {hasFooter(isShowFooter) && <S.AppFooter>{footer}</S.AppFooter>}
      </S.LayoutBottom>
    </S.LayoutRoot>
  );
};

export default AppLayout;
