/* eslint sonarjs/prefer-immediate-return: 0 */
import React, { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

import { ICommonScoreHocProps } from '@features/events/components/scoreboards/factoryScoreboards';
import { useEventsModel } from '@features/events/useEventsModel';

import deleteTimeout from '@common/helpers/timeoutHelper';
import { isPeriodNotStarted } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { IScore } from '@common/interfaces';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';

import { IWrappedComponentProps } from './ScoreboardHoc.types';

const scoreboardHocUnwrapped = (
  WrappedComponent: React.FC<IWrappedComponentProps>,
): ((props: ICommonScoreHocProps) => JSX.Element) => {
  const ScoreboardHocComponent = (props: ICommonScoreHocProps): JSX.Element => {
    const { id, listType } = props;
    const { event } = useEventsModel(id, listType);
    const score = useEventsListState(
      s => s[listType]?.data?.events[id]?.score || event.score,
    );
    const [home] = useState(false);
    const [away] = useState(false);
    const timeoutTimersRef = useRef<Array<NodeJS.Timeout>>([]);

    useEffect(() => {
      return () => {
        forEach(timeoutTimersRef.current, timer => deleteTimeout(timer));
      };
    }, []);
    // TODO: refactor beatScore logic

    // const beatScore = (isHome: boolean): void => {
    //   timeoutTimersRef.current = times(10, time => {
    //     if (isHome) {
    //       return setTimeout(() => setHome(time % 2 === 0), time * 500);
    //     }
    //     return setTimeout(() => setAway(time % 2 === 0), time * 500);
    //   });
    // };

    if (!event || !event.timer) {
      return <></>;
    }

    const period = event.timer?.period_idx;

    if (isEmpty(score) || isPeriodNotStarted(event.timer)) {
      return <></>;
    }

    return (
      <WrappedComponent
        {...props}
        home={home}
        away={away}
        event={event}
        period={period as number}
        score={score as IScore}
        // beatScore={beatScore}
      />
    );
  };
  return ScoreboardHocComponent;
};

export default scoreboardHocUnwrapped;
