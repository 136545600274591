import styled from 'styled-components';

const getWithoutTextStyles = ({ theme }) => `
   width: 65px; 
   height: 19px; 
   margin-right: ${theme.space_10};
`;

export const MessageWrapper = styled.div<{ withoutText?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.highlightWrapperBackground};
  border: 1px solid
    ${props =>
      props.theme.isDesktop ? props.theme.accent : props.theme.textSecondary};
  border-radius: 3px;
  padding: ${({ theme }) => theme.space_10};
  margin-right: ${({ theme }) => theme.space_5};

  ${props => props.withoutText && getWithoutTextStyles};
`;

export const IconWrapper = styled.div<{ withoutText?: boolean }>`
  margin-right: ${({ theme }) => theme.isDesktop && theme.space_10};
`;

export const Message = styled.span`
  font-weight: ${({ theme }) => theme.fontBold};
  font-stretch: condensed;
  text-transform: uppercase;
`;
