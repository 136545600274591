import styled from 'styled-components';

import { Icon } from '@features/core/commonComponents/icon';

export const Root = styled.div<{
  isLivePage?: boolean;
}>`
  display: grid;
  grid-auto-flow: ${({ isLivePage }) => (!isLivePage ? 'row' : 'column')};
  background-color: ${({ theme, isLivePage }) =>
    isLivePage ? 'transparent' : theme.main};
`;

export const Item = styled.div<{ isLivePage?: boolean }>`
  margin-right: ${({ theme, isLivePage }) =>
    isLivePage ? theme.space_5 : '0'};
  margin-bottom: ${({ theme }) => theme.space_5};
`;

export const ItemButton = styled.button<{ isLivePage?: boolean }>`
  display: grid;
  grid-auto-flow: ${({ isLivePage }) => (isLivePage ? 'row' : 'column')};
  justify-content: start;
  width: ${({ isLivePage }) => (isLivePage ? 'auto' : '100%')};
  color: ${({ theme }) => theme.textMain};
  background-color: ${({ theme, isLivePage }) =>
    isLivePage ? theme.primaryBackground : theme.elementNuanceColor};
  border-radius: 3px;
  cursor: pointer;
  align-items: center;

  &.selected {
    background-color: ${({ theme, isLivePage }) => isLivePage && theme.accent};
    color: ${({ theme, isLivePage }) =>
      isLivePage ? theme.main : theme.accent};
  }

  p {
    margin: 0;
  }
`;

export const ItemIcon = styled(Icon)``;

export const ItemTitle = styled.span<{
  active?: boolean;
}>`
  font-size: ${({ theme }) => theme.fontLG};
  font-weight: ${({ active }) => (active ? '700' : '400')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemChildren = styled.div``;

export const EventCount = styled.p<{ active?: boolean }>`
  min-height: 12px;
  font-size: ${props => props.theme.fontXS};
  width: 100%;
  text-align: right;
  color: ${({ theme, active }) =>
    active ? theme.elementNuanceColor : theme.buttonMainTextColor};
`;

const getColumnWrapperPadding = (isLivePage, theme) => {
  return isLivePage
    ? `0 ${theme.space_10} ${theme.space_10} ${theme.space_10}`
    : `${theme.space_10}`;
};

export const GridColumnWrapper = styled.div<{ isLivePage: boolean }>`
  display: grid;
  grid-auto-flow: column;
  padding: ${({ isLivePage, theme }) =>
    getColumnWrapperPadding(isLivePage, theme)};
  grid-gap: ${({ theme }) => theme.space_10};
`;
