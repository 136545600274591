import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { TextLarge, TextXLarge } from '@features/core/styles/text.styled';

export const Backdrop = styled.div``;

export const Content = styled.div`
  max-width: 500px;
  padding: ${({ theme }) => theme.space_30} ${({ theme }) => theme.space_15}
    ${({ theme }) => theme.space_15};
  background-color: ${props => props.theme.textMain};
  color: ${props => props.theme.inputDefaultText};
  font-size: ${props => props.theme.fontMD};
  font-stretch: condensed;
  overflow-y: auto;

  div {
    color: inherit;
  }
`;

export const LimitsDialogLink = styled(CustomLink)`
  cursor: pointer;
  display: flex;
  justify-items: center;
  align-items: center;

  .mi-info-circle-solid {
    padding-right: ${({ theme }) => theme.space_10};
  }
`;

export const LimitTitle = styled(TextXLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
  font-stretch: condensed;
  font-style: normal;
  color: ${props => props.theme.inputDefaultText};
  text-transform: uppercase;
`;

export const RadioButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space_20};
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: ${({ theme }) => theme.space_40};

  & #radio-title {
    font-weight: ${({ theme }) => theme.fontRegular};
  }
`;

export const LimitSubTitle = styled.div`
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${props => props.theme.highlightWrapperBackground};
  margin-top: ${({ theme }) => theme.space_5};
  padding-left: ${({ theme }) => theme.space_40};

  a {
    color: ${props => props.theme.textLink};
  }
`;

export const LimitsContainer = styled.div`
  background-color: ${props => props.theme.textActive};
  margin: ${({ theme }) => theme.space_15} 0 0 ${({ theme }) => theme.space_40};

  & > div:nth-child(odd) {
    background-color: ${({ theme }) =>
      theme.isDesktop ? theme.bodyBackground : theme.elementNuanceColor};
  }
`;

export const InputInfoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  grid-template-columns: 25px 1fr;
  margin: ${({ theme }) => theme.space_15} 0;
  font-size: ${({ theme }) => theme.fontMD};
  color: ${({ theme }) => theme.highlightWrapperBackground} !important;
`;

export const LimitRow = styled(TextLarge)`
  display: grid;
  grid-auto-flow: row;
  align-items: ${({ theme }) => (theme.isDesktop ? 'center' : 'baseline')};
  justify-content: space-between;
  padding: ${props => props.theme.space_10};
  color: ${props => props.theme.highlightWrapperBackground};
  position: relative;
  grid-auto-columns: 1fr;
  margin-left: ${({ theme }) => theme.isDesktop && theme.space_30};

  input {
    padding: ${({ theme }) => theme.space_20};
    text-align: end;
  }

  .sufix {
    font-size: ${({ theme }) => theme.fontMD};
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-input-error input {
    height: 40px;
    font-weight: ${({ theme }) => theme.fontBold};
    font-size: ${props => props.theme.fontMD};
  }

  .error-message {
    right: 0;
    ${({ theme }) => theme.isDesktop && 'width: fit-content;'}
  }

  .error-message::before {
    content: '';
    border: 7px solid transparent;
    border-bottom: 7px solid #004560;
    position: absolute;
    right: 12px;
    top: -14px;
    z-index: 1;
  }

  .error-message::after {
    content: '';
    border: 6px solid transparent;
    border-bottom: 6px solid ${({ theme }) => theme.statusError};
    position: absolute;
    right: 13px;
    top: -10px;
    z-index: 2;
  }

  input + div {
    color: ${props => props.theme.textActive};
  }

  .with-errors input + div {
    color: ${props => props.theme.statusError};
  }

  @media (max-width: 280px) {
    & {
      margin-bottom: ${({ theme }) => theme.space_40};
    }

    .error-message {
      width: 260px;
      top: 100%;
      height: max-content;
    }
  }
`;
