import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing/linkAliases';
import { ProcessingMessage } from '@features/user/components/selfLock/components/processingMessage';
import { WarningMessage } from '@features/user/components/selfLock/components/warningMessage';

import { ButtonType } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  resetSelfExclusion,
  useLimitsState,
} from '@common/providers/limits/useLimits';
import { setSelfExclusion } from '@common/providers/limits/helper';
import { isMatchWebRoutes } from '@common/providers/router/helper';
import { useLongPress } from '@common/hooks/useKeyPress';

import * as S from './SelfLock.styled';

const isDesktop = isDesktopView();

const SelfLock: React.FC = () => {
  const { t } = useTranslation();
  const success = useLimitsState(s => s.selfExclusionForm.success);
  const error = useLimitsState(s => s.selfExclusionForm.error);
  const loading = useLimitsState(s => s.selfExclusionForm.loading);
  const isBettingSlip = isMatchWebRoutes(PageName.BETTING_SLIP);

  const backspaceLongPress = useLongPress(
    () =>
      setSelfExclusion({
        period: '24H',
        reason: '01',
      }),
    3000,
    () => resetSelfExclusion(),
    isDesktopView(),
  );

  return (
    <S.SelfLockContainer>
      <S.SelfLockWrap>
        {loading && !success && !error && (
          <ProcessingMessage withoutText={isBettingSlip && !isDesktop} />
        )}
        {!loading && !success && (
          <S.ExclusionButton
            {...backspaceLongPress}
            data-qa="btn-24h-lock"
            buttonType={ButtonType.LightOutlined}
          >
            {t('bettingslip.24h_lock_mob')}
          </S.ExclusionButton>
        )}
        <S.SelfLockText data-qa="bettingslip-24h-lock-text-mob">
          {t('bettingslip.24h_lock_text_mob')}
        </S.SelfLockText>
      </S.SelfLockWrap>
      {success && (
        <WarningMessage
          isError={false}
          title={t('bettingslip.24h_lock_success')}
          text={t('bettingslip.24h_lock_success_text')}
        />
      )}
      {error && (
        <WarningMessage
          isError
          title={t('bettingslip.24h_lock_error')}
          text={t('bettingslip.24h_lock_error_text')}
        />
      )}
    </S.SelfLockContainer>
  );
};

export { SelfLock as Component };

export default SelfLock;
