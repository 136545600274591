import styled from 'styled-components';

import { Icon } from '@features/core/commonComponents/icon';
import { TextNormal } from '@features/core/styles/text.styled';

const getSubtitleWrapperBackground = (isLight, bgColor) => {
  if (!isLight) {
    return '';
  }
  return `background: ${bgColor};`;
};

const accountSummaryStyles = theme => `
  background-color: ${theme.highlightWrapperBackground};
  color: ${theme.main};
`;

export const SubtitleWrapper = styled.div<{
  isLight?: boolean;
  isAccountSummary?: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  justify-items: center;
  padding: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.textMain};
  background-color: ${({ theme }) => theme.categoryTabBackground};

  ${({ isLight, theme }) =>
    getSubtitleWrapperBackground(isLight, theme.textActive)}

  ${({ isAccountSummary, theme }) =>
    isAccountSummary && accountSummaryStyles(theme)}
`;

export const LabelBlock = styled(TextNormal)`
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.space_10};
  margin-top: ${({ theme }) => theme.space_1};
  align-self: center;
  height: 12px;
`;

export const IconStyled = styled(Icon)`
  fill: ${props => props.theme.textMain};
`;
