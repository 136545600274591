import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import last from 'lodash/last';
import split from 'lodash/split';
import isNaN from 'lodash/isNaN';
import includes from 'lodash/includes';
import i18n from 'i18next';

import services from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';

import { HAS_OASIS } from '@common/constants/config';
import { isWithDrawalPage } from '@common/helpers/links';
import isKioskUser from '@common/helpers/userHelper/isKioskUser';
import { useUserState } from '@common/providers/user/useUserState';
import { isMatchWebRoutes } from '@common/providers/router/helper';
import getImageBasePath from '@common/helpers/getImageBasePath';

import * as S from './AccountHeader.styled';

const AccountHeader: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathName = split(location.pathname, '/');
  const isBets = isMatchWebRoutes(PageName.USER_BETS);
  const isChangePassword = isMatchWebRoutes(PageName.USER_CHANGE_PASSWORD);
  const isWithdrawalPage = isWithDrawalPage();

  const isAccountLockPage = isMatchWebRoutes(PageName.USER_LIMITS_LOCK);
  const penultEl = pathName.slice(-2, -1);
  const lastPathname = last(pathName);
  const user = useUserState(state => state.data);
  const isShopUserPage = user && isKioskUser(user);

  const getHeaderText = (): string | null => {
    if (!isNaN(parseInt(lastPathname || '', 10))) {
      if (includes(lastPathname, '.') || includes(lastPathname, ',')) {
        return `${t('app-layout.navigation.bank_withdrawal')} ${last(
          pathName,
        )} euro`;
      }
      if (includes(penultEl, 'qr')) {
        return null;
      }

      return `${t('app-layout.navigation.bet')} #${lastPathname}`;
    }

    if (
      lastPathname === 'success' ||
      lastPathname === 'fail' ||
      lastPathname === 'prepared'
    ) {
      const key = `app-layout.navigation.${penultEl}`;
      return t(key);
    }
    if (lastPathname === 'notifications') {
      return t(`user.notifications.notifications_settings`);
    }
    if (lastPathname === 'bonuses') {
      return t(`user.bonuses.header`);
    }

    if (lastPathname === 'bank' || isShopUserPage) {
      return null;
    }
    if (isWithdrawalPage && services.domainLang === 'de') {
      return t(`app-layout.navigation.${pathName[2]}`);
    }

    if (isAccountLockPage) {
      return t('app-layout.user-menu.limits-lock', {
        oasis: services.config.get(HAS_OASIS)
          ? i18n.t('app-layout.user-menu.limits-lock-oasis')
          : '',
      });
    }
    return t(`app-layout.navigation.${lastPathname}`);
  };

  const getHeaderImage = (): React.ReactElement => {
    let src = '';
    let alt = '';
    if (includes(penultEl, 'qr')) {
      src = '/images/payment-aircash.svg';
      alt = 'aircash-logo';
    }
    if (src.length > 0) {
      return (
        <S.ImageWrapper>
          <S.ImageStyled
            src={getImageBasePath(src)}
            alt={alt}
            height="100%"
            width="50%"
          />
        </S.ImageWrapper>
      );
    }
    return <></>;
  };

  return getHeaderText() || getHeaderImage() ? (
    <S.Wrapper
      isBets={isBets && !/\d/.test(location.pathname)}
      isChangePassword={isChangePassword}
      isQRcode={includes(penultEl, 'qr')}
      className="accountHeader"
    >
      <S.HighlightBoldHeaderLabel>{getHeaderText()}</S.HighlightBoldHeaderLabel>
      {getHeaderImage()}
    </S.Wrapper>
  ) : null;
};

export default AccountHeader;
