import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';
import { TextSM } from '@features/core/styles/text.styled';
import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { VERIFICATION_NOTICE } from '@common/constants/notifications';
import { VERIFICATION_NOTIFICATION_REMOVE } from '@common/constants/cookie';
import { ButtonType } from '@common/interfaces';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import {
  removeNotification,
  useAppState,
} from '@common/providers/application/useAppState';

import * as S from '../TopNotification.styled';

const Verification: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const notifications = useAppState(state => state.notifications);
  const accountVerification = isMatchWebRoutes(PageName.USER_VERIFICATION);
  const emailVerification = isMatchWebRoutes(
    PageName.USER_EMAIL_VERIFICATION_SUCCESS,
  );
  const yearlyVerification = isMatchWebRoutes(PageName.YEARLY_DATA_CHECK);

  useEffect(() => {
    if (services.cookie.get(VERIFICATION_NOTIFICATION_REMOVE)) {
      removeNotification(VERIFICATION_NOTICE);
    }
  }, [notifications]);

  if (accountVerification || emailVerification || yearlyVerification) {
    return <></>;
  }

  return (
    <S.NotificationWrapper id="verification">
      <S.NotificationHeader>
        <Icon width="20" height="20" name="info-circle" />
        <S.HighlightBoldTitle className="title">
          {t('user.notifications.verification_not_passed_header')}
        </S.HighlightBoldTitle>
      </S.NotificationHeader>
      <S.GridWrapper>
        <TextSM>
          <span
            dangerouslySetInnerHTML={{
              __html: t('user.notifications.verification_not_passed'),
            }}
          />
        </TextSM>
        <S.ButtonContainer>
          <StyledButton
            onClick={(): void => {
              setLocation(PageName.USER_VERIFICATION);
              removeNotification(VERIFICATION_NOTICE);
            }}
            label="user.notifications.to_verification_page"
            buttonType={ButtonType.Blue}
          />
          <StyledButton
            onClick={(): void => {
              removeNotification(VERIFICATION_NOTICE);
              services.cookie.set(VERIFICATION_NOTIFICATION_REMOVE, '1', {
                expires: null,
              });
            }}
            label="user.notifications.verify_later_btn"
            buttonType={ButtonType.DarkOutlined}
            className="verify-later"
          />
        </S.ButtonContainer>
      </S.GridWrapper>
    </S.NotificationWrapper>
  );
};

export default Verification;
