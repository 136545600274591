import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';
import CustomLink from '@features/core/routing/CustomLink';

import * as S from './Pager.styled';
import { IPager } from './Pager.types';

const Pager: React.FC<IPager> = props => {
  const { t } = useTranslation();
  const { prevUrl, nextUrl, currentPage, totalPages } = props;

  return (
    <S.Container className="panel">
      <S.SideLeft>
        {!!prevUrl && (
          <CustomLink
            href={prevUrl}
            ariaLabel={t('common.links.previous_page')}
            dataQa="chevron-left"
          >
            <S.IconContainer>
              <Icon
                className="static"
                name="chevron-left"
                width="12"
                height="12"
              />
            </S.IconContainer>
          </CustomLink>
        )}
      </S.SideLeft>
      {currentPage && totalPages && (
        <S.Center>{`${currentPage} ${t('helpers.of')} ${totalPages}`}</S.Center>
      )}
      <S.SideRight>
        {!!nextUrl && (
          <CustomLink
            href={nextUrl}
            ariaLabel={t('common.links.next_page')}
            dataQa="chevron-right"
          >
            <S.IconContainer>
              <Icon
                className="static"
                name="chevron-right"
                width="12"
                height="12"
              />
            </S.IconContainer>
          </CustomLink>
        )}
      </S.SideRight>
    </S.Container>
  );
};

export default Pager;
