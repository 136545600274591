import React from 'react';
import { useTranslation } from 'react-i18next';

import { VirtualInput } from '@features/core/commonComponents/virtual';
import { StyledButton } from '@features/core/commonComponents/buttons';
import {
  ButtonSize,
  ButtonType,
} from '@features/core/commonComponents/buttons/Button.styled';

import { cashoutDefaultButtonValue } from '@common/helpers/cashoutHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

import { IInputsProps } from './Inputs.types';
import * as S from './Inputs.styled';

const Inputs: React.FC<IInputsProps> = ({
  value,
  formattedValue,
  setValue,
  disabledBtn,
  onReadySubmit,
  isLock,
  inputType,
  focus,
  setFocus,
  maxValue = 15000,
  focused,
}) => {
  const { t } = useTranslation();
  const buttonLabel =
    inputType === 'max' ? t('cashout.max_cashout') : t('cashout.cashout');

  const handleSubmit = (): void => {
    if (disabledBtn) {
      return;
    }
    onReadySubmit();
    if (setFocus) {
      setFocus(false);
    }
  };

  return inputType === 'max' || inputType === 'max_blue' ? (
    <>
      <StyledButton
        onClick={onReadySubmit}
        label={
          isLock
            ? `${t('cashout.cashout_unavailable')}`
            : `${formatBalance(formattedValue)} ${buttonLabel}`
        }
        disabled={
          disabledBtn || isLock || formattedValue === cashoutDefaultButtonValue
        }
        size={inputType === 'max_blue' ? ButtonSize.Small : ButtonSize.Big}
        buttonType={
          inputType === 'max_blue' ? ButtonType.LightBlue : ButtonType.Yellow
        }
      />
    </>
  ) : (
    <>
      <S.CashoutLabel>{t('cashout.cashout_label')}</S.CashoutLabel>
      <div
        className="input"
        role="presentation"
        onClick={(): void => {
          if (setFocus) {
            setFocus(!focus);
          }
        }}
      >
        <S.VirtualInputWrapper>
          <VirtualInput
            currentValue={value}
            value={value}
            onFocus={(): void => {
              if (setValue) {
                setValue(value);
              }
            }}
            onBlur={(val): void => {
              if (setValue) {
                setValue(val);
              }
            }}
            active={Boolean(focus)}
            disabled={!isDesktopView()}
            maxValue={maxValue}
            focused={focused}
          />
        </S.VirtualInputWrapper>
      </div>

      <StyledButton
        disabled={disabledBtn}
        onClick={handleSubmit}
        type="submit"
        dataQa="cashout-btn-ok"
        aria-label={
          isLock ? t('common.buttons.disable') : t('common.buttons.submit')
        }
        label={isLock ? '' : 'helpers.ok'}
        icon={
          isLock
            ? {
                name: 'lock-outlined',
                width: 10,
                height: 10,
                isSingle: true,
              }
            : undefined
        }
        size={ButtonSize.Big}
      />
    </>
  );
};

export default Inputs;
