import React from 'react';

import { Icon } from '@features/core/commonComponents/icon';
import { PageName } from '@features/core/routing/linkAliases';
import { useThemeScheme } from '@features/core/theming/contexTheme';

import { PageType } from '@common/interfaces/events/IEventList';
import {
  toggleCategoryHeader,
  useAppState,
} from '@common/providers/application/useAppState';
import { isMatchWebRoutes } from '@common/providers/router/helper';

import ButtonWrapper from './ToggleCategoryHeadersButton.styled';

const ToggleCategoryHeadersButton = ({
  pageType,
}: {
  pageType: string;
}): JSX.Element => {
  const { theme } = useThemeScheme();
  const isInCategoryEventsPage =
    isMatchWebRoutes(PageName.EVENTS_CATEGORY) ||
    isMatchWebRoutes(PageName.SPORT);
  const hasCategoryHeader = useAppState(state => state.hasCategoryHeader);

  const hideCategoryHeadersHandler = (): void => {
    toggleCategoryHeader();
  };

  return (
    <ButtonWrapper
      isLive={pageType === PageType.LIVE}
      isInCategoryEventsPage={isInCategoryEventsPage}
      isHomePage={pageType === PageType.HOME}
      onClick={hideCategoryHeadersHandler}
      data-qa={hasCategoryHeader ? 'show-headers' : 'hide-headers'}
    >
      <Icon
        name={hasCategoryHeader ? 'show-headers' : 'hide-headers'}
        fill={theme.textMain}
      />
    </ButtonWrapper>
  );
};
export default ToggleCategoryHeadersButton;
