import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { TransactionSortedList } from '@features/transactions/components/transactionSortedList';
import { Icon } from '@features/core/commonComponents/icon';

import { useTransactions } from '@common/providers/transactions/useTransactions';
import getTransactionsAction from '@common/helpers/transactions/getTransactionsAction';

import * as S from './TransactionSummary.styled';

const TransactionsSummary: React.FC = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const offset = useTransactions(s => s.offset);
  const currentPage = useTransactions(s => s.currentPage);
  const totalPages = useTransactions(s => s.totalPages);
  const loading = useTransactions(s => s.loading);
  const data = useTransactions(s => s.data);
  const showLoader = currentPage < totalPages;

  const toggleExpanded = (): void => setExpanded(prevState => !prevState);

  const getTransactions = (): void => {
    getTransactionsAction({
      type: 'all',
      offset,
      max_age_days: 30,
    });
  };

  return (
    <S.Root>
      <S.ExpandContainer
        expanded={expanded}
        onClick={toggleExpanded}
        data-qa="transaction-summary"
      >
        <S.GridBox>
          <S.IconContainer>
            <Icon className="icon" name="stock" />
          </S.IconContainer>
          <S.Title>{t('user.summary.account_movements')}</S.Title>
        </S.GridBox>
        <S.IconContainer>
          <Icon width="10" height="10" name={expanded ? 'minus' : 'plus'} />
        </S.IconContainer>
      </S.ExpandContainer>
      {expanded &&
        (data.length ? (
          <S.TransactionsContainer data-qa="transaction-container">
            <TransactionSortedList isAccountSummary data={data} />
            {showLoader && (
              <S.Loader>
                {!loading && <Waypoint onEnter={getTransactions} />}
                {t('common.labels.loading')}
              </S.Loader>
            )}
          </S.TransactionsContainer>
        ) : (
          <S.EmptyContainer>
            {t('user.summary.no_transactions_available')}
          </S.EmptyContainer>
        ))}
    </S.Root>
  );
};

export default TransactionsSummary;
