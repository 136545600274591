import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import services, { useServices } from '@features/core/services';
import { UserLinks } from '@features/app/layout/menuTab';
import { Icon } from '@features/core/commonComponents/icon';
import { PageName } from '@features/core/routing/linkAliases';
import { LugasTimer } from '@features/user/components/lugasTimer';
import { useThemeScheme } from '@features/core/theming/contexTheme';

import { LUGAS_TIMER } from '@common/constants/cookie';
import { LUGAS_INACTIVE } from '@common/constants/dialogs';
import {
  HAS_BONUS_CODES,
  HAS_BONUS_WALLETS,
  HAS_DEPOSITS,
  HAS_INBOX,
  HAS_LIMITS,
  HAS_OASIS,
  HAS_STATIC_PAGES,
  HAS_UPLOADS,
  HAS_WITHDRAWALS,
  HAS_MY_FAVORITO,
} from '@common/constants/config';
import { setLocation } from '@common/providers/router/helper';
import logoutUser from '@common/providers/user/actions/logoutUser';
import { getVerificationLink } from '@common/helpers/paymentsHelper/paymentModel';
import { IUser } from '@common/interfaces';
import { useUserState } from '@common/providers/user/useUserState';
import { openSidebar } from '@common/providers/application/useAppState';

import * as S from './AccountNavigation.styled';

const AccountMenu: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const { cookie } = useServices();
  const { theme } = useThemeScheme();
  const user = useUserState(state => state.data);

  const hasLugasTimer = cookie.get(LUGAS_TIMER);

  const goToHome = (): void => setLocation(PageName.HOME);

  const logout = async (): Promise<void> => {
    if (hasLugasTimer) {
      openSidebar({ type: LUGAS_INACTIVE });
    } else {
      await logoutUser();
      setLocation(PageName.HOME);
    }
  };

  return (
    <S.Wrapper>
      <S.IconWrapper>
        <Icon
          width="14"
          height="13"
          name="close"
          fill={theme.textMain}
          onClick={goToHome}
        />
      </S.IconWrapper>
      <LugasTimer type="activity" />
      <UserLinks
        links={[
          {
            key: 'my-bets',
            title: t('app-layout.user-menu.gained_bets'),
            location: PageName.USER_BETS,
          },
          {
            key: 'transactions',
            title: t('app-layout.user-menu.transactions'),
            location: PageName.USER_OVERVIEW,
          },
          ...(services.config.get(HAS_DEPOSITS)
            ? [
                {
                  key: 'deposit',
                  title: t('payments.deposit'),
                  location: PageName.USER_PAYIN,
                },
              ]
            : []),
          ...(services.config.get(HAS_WITHDRAWALS)
            ? [
                {
                  key: 'withdrals',
                  title: t('payments.withdrawal'),
                  location: PageName.USER_PAYOUT,
                },
              ]
            : []),
          ...(services.config.get(HAS_MY_FAVORITO)
            ? [
                {
                  key: 'my-favorito',
                  title: t('app-layout.user-menu.my-favorito'),
                  location: PageName.MY_FAVORITO,
                },
              ]
            : []),
          ...(services.config.get(HAS_INBOX)
            ? [
                {
                  key: 'my-inbox',
                  title: t('app-layout.user-menu.inbox'),
                  location: PageName.USER_INBOX,
                },
              ]
            : []),
          ...(services.config.get(HAS_LIMITS)
            ? [
                {
                  key: 'limit',
                  title: t('app-layout.user-menu.limits'),
                  location: PageName.USER_LIMITS,
                },
              ]
            : []),
          {
            key: 'notifications',
            title: t('app-layout.navigation.notifications'),
            location: PageName.USER_NOTIFICATIONS,
          },
          ...(services.config.get(HAS_BONUS_CODES) ||
          services.config.get(HAS_BONUS_WALLETS)
            ? [
                {
                  key: 'bonus',
                  title: t('user.bonuses.header'),
                  location: PageName.USER_BONUSES,
                },
              ]
            : []),
          {
            key: 'profile',
            title: t('app-layout.user-menu.profile'),
            location: PageName.USER_PROFILE,
          },
          {
            key: 'change-password',
            title: t('app-layout.navigation.change-password'),
            location: PageName.USER_CHANGE_PASSWORD,
          },
          ...(services.config.get(HAS_STATIC_PAGES)
            ? [
                {
                  key: 'verification',
                  title: t('app-layout.user-menu.verification'),
                  location: getVerificationLink(user as IUser),
                },
              ]
            : []),
          ...(services.config.get(HAS_LIMITS)
            ? [
                {
                  key: 'limits-lock',
                  title: t('app-layout.user-menu.limits-lock', {
                    oasis: services.config.get(HAS_OASIS)
                      ? i18n.t('app-layout.user-menu.limits-lock-oasis')
                      : '',
                  }),
                  location: PageName.USER_LIMITS_LOCK,
                },
              ]
            : []),
          {
            key: 'delete-account',
            title: t('app-layout.user-menu.delete-account'),
            location: PageName.USER_DELETE_ACCOUNT,
          },
          ...(services.config.get(HAS_UPLOADS)
            ? [
                {
                  key: 'uploads',
                  title: t('app-layout.user-menu.upload_documents'),
                  location: PageName.USER_UPLOADS,
                },
              ]
            : []),
          {
            key: 'logout',
            title: t('app-layout.user-menu.logout'),
            onClick: logout,
          },
        ]}
      />
    </S.Wrapper>
  );
};

export default AccountMenu;
