import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Toggle } from '@features/core/commonComponents/toggle';

import { BetView } from '@common/providers/bettingslip/types';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  toggleBSMode,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';

import BetPackerExplanation from './BetPackerExplanation';
import BetPackerIcon from './BetPackerIcon';
import * as S from './BetPacker.styled';
import { IBetPackerIconSize } from './BetPacker.types';

const BetPacker: React.FC = (): JSX.Element => {
  const bsMode = useBettingSlip(state => state.bsMode);
  const { t } = useTranslation();
  const isDesktop = isDesktopView();
  const [hasExplanationPopup, toggleExplanationPopup] = useState(false);
  const handleExplanationPopup = (): void => {
    toggleExplanationPopup(true);
  };
  const [toggle, setToggle] = useState(false);

  const toggleMode = (): void => {
    setToggle(!toggle);
    if (bsMode === BetView.NORMAL) {
      toggleBSMode(BetView.BETPACKER);
    } else {
      toggleBSMode(BetView.NORMAL);
    }
  };

  return (
    <S.BetPackerWrapper>
      <BetPackerIcon size={IBetPackerIconSize.normal} />
      <S.BetPackerLabel>
        <S.BetPackerTitle>
          {bsMode === BetView.NORMAL
            ? t('bets.bet_packer.tooltip_header_activate')
            : t('bets.bet_packer.tooltip_header_disable')}
        </S.BetPackerTitle>

        <S.BetPackerExplanation onClick={handleExplanationPopup}>
          {t('bets.bet_packer.explanation_title')}
        </S.BetPackerExplanation>
        {hasExplanationPopup && (
          <BetPackerExplanation
            hasExplanationPopup={hasExplanationPopup}
            toggleExplanationPopup={toggleExplanationPopup}
          />
        )}
      </S.BetPackerLabel>
      <Toggle
        onClick={toggleMode}
        width="60px"
        height="28px"
        checkedState={toggle}
      />
      {hasExplanationPopup && !isDesktop && <S.BackgroundDarkening />}
    </S.BetPackerWrapper>
  );
};

export default BetPacker;
