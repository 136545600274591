import styled from 'styled-components';

import { Image } from '@features/core/commonComponents/image';

export const FlagWrap = styled.div`
  min-width: ${props => (!props.theme.isDesktop ? '28px' : '20px')};
  display: grid;
  align-items: center;
`;

export const Flag = styled(Image)`
  height: 10px;
  width: 15px;
  margin-right: ${({ theme }) => !theme.isDesktop && theme.space_10};
`;
