import React from 'react';
import { useTranslation } from 'react-i18next';
import split from 'lodash/split';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';

import { Icon } from '@features/core/commonComponents/icon';
import {
  FavoritesButton,
  FavoritesButtonType,
} from '@features/core/commonComponents/favoritesButton';
import services from '@features/core/services';
import CategoryFlag from '@features/core/commonComponents/categoryFlag/CategoryFlag';

import { HAS_CATEGORIES_FAVORITES } from '@common/constants/config';
import {
  addCategoryAbbreviations,
  collapsedCategoriesId,
  getAllChildrenIds,
  getCategoryLabelPrefix,
} from '@common/helpers/categories/categoriesModel';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { isLongTerm } from '@common/helpers/eventsHelper/eventStatusHelper';

import * as S from './CategoryTreeItem.styled';
import {
  ICategoryTreeItem,
  ICheckboxStatus,
  TReturnCheckboxIconName,
} from './CategoryTreeItem.types';

const isDesktop = isDesktopView();

const CategoryTreeItem: React.FC<ICategoryTreeItem> = (
  props,
): React.ReactElement => {
  const {
    item,
    selectedItems = [],
    filter,
    onClick,
    onSelect,
    opened = true,
    onExpand,
    isHighlight,
  } = props;
  const countEvents = filter ? item[`count_${filter}`] : item.count;
  const { t } = useTranslation();
  const isLongTermCategory = isLongTerm(item);
  const hasChildren = (item.children?.length || 0) > 0;
  const hasFavorite = services.config.get(HAS_CATEGORIES_FAVORITES) as boolean;
  const hasAllGamesLink = Boolean(
    (collapsedCategoriesId[item.id] || (isHighlight && hasChildren)) &&
      !isDesktop,
  );

  const childrenCategories: string[] = getAllChildrenIds(item);

  const getCheckboxStatus = (): string => {
    if (!intersection(selectedItems, childrenCategories).length) {
      return ICheckboxStatus.unChecked;
    }

    if (
      intersection(selectedItems, childrenCategories).length ===
      childrenCategories.length
    ) {
      return ICheckboxStatus.checked;
    }

    return ICheckboxStatus.partChecked;
  };

  const checkboxStatus = getCheckboxStatus();

  const active =
    isDesktop &&
    (includes(selectedItems, item.id) || checkboxStatus === 'checked');

  const prepareLabel = (): JSX.Element => {
    const splitted = split(item.path || '', '/');
    const pathAbbr = addCategoryAbbreviations(item.detail);
    const path = addCategoryAbbreviations(splitted[1]);
    const samePathAbbr: boolean = pathAbbr === path;
    return (
      <S.CategoryLabel title={pathAbbr}>
        {(!isDesktop || isHighlight) &&
          !collapsedCategoriesId[item.id] &&
          !samePathAbbr &&
          !isLongTermCategory && (
            <b
              title={`${path}: ${pathAbbr}`}
              className="title"
            >{`${path}: `}</b>
          )}
        <S.ItemLabelPrefix>{getCategoryLabelPrefix(item.id)}</S.ItemLabelPrefix>
        <S.ItemSubLabel>{`${pathAbbr}`}</S.ItemSubLabel>
      </S.CategoryLabel>
    );
  };

  const getCheckboxIconName = (
    checkboxItem: string,
  ): TReturnCheckboxIconName => {
    if (checkboxItem === ICheckboxStatus.checked) {
      return 'check';
    }
    if (checkboxItem === ICheckboxStatus.partChecked) {
      return 'minus';
    }
    return '';
  };

  const onClickCheckbox = (): void => {
    onClick(item);
  };

  const onClickChevron = (): void => {
    if (onExpand) {
      onExpand();
    }
  };

  const onClickLabel = (disabled: boolean): void => {
    if (disabled) {
      return;
    }
    if (hasAllGamesLink) {
      onClickChevron();
    } else {
      onSelect(item);
    }
  };

  const renderAllGames = (): React.ReactElement => (
    <S.AllGames onClick={(): void => onSelect(item)}>
      {t('common.labels.all_games')}
    </S.AllGames>
  );

  const renderChevron = (): React.ReactElement => (
    <S.ChevronWrap onClick={onClickChevron}>
      <S.Chevron>
        <Icon
          width="10"
          height="10"
          name={opened ? 'chevron-up' : 'chevron-down'}
          fill="currentColor"
          className="chevron"
        />
      </S.Chevron>
    </S.ChevronWrap>
  );

  const renderCheckbox = (): React.ReactElement => (
    <S.CheckboxWrap onClick={onClickCheckbox}>
      {hasChildren ? (
        <S.CustomCheckbox
          checkboxStatus={checkboxStatus}
          data-qa={`category-tree-checkbox checkbox-status-${checkboxStatus}`}
        >
          <Icon
            width="10"
            height="10"
            name={getCheckboxIconName(checkboxStatus)}
            fill="#004560"
          />
        </S.CustomCheckbox>
      ) : (
        <S.CustomCheckbox
          checkboxStatus={active ? 'checked' : 'unChecked'}
          data-qa={`category-tree-checkbox checkbox-status-${
            active ? 'checked' : 'unChecked'
          }`}
        >
          {active && (
            <Icon width="10" height="10" name="check" fill="#004560" />
          )}
        </S.CustomCheckbox>
      )}
    </S.CheckboxWrap>
  );

  return (
    <S.Root dataQa="category-tree-item">
      <S.LeftSide onClick={(): void => onClickLabel(isDesktop)}>
        <S.LeftSideWrapper>
          {isDesktop && renderCheckbox()}
          <S.ItemLabel
            isSelected={active}
            onClick={(): void => onClickLabel(!isDesktop)}
          >
            <CategoryFlag
              isLongTermCategory={isLongTermCategory}
              categoryPath={item.path}
            />
            {prepareLabel()}
          </S.ItemLabel>
        </S.LeftSideWrapper>
      </S.LeftSide>
      {hasChildren && renderChevron()}
      {hasAllGamesLink && renderAllGames()}
      {hasFavorite ? (
        <S.RightSide hasFavorite={hasFavorite} withoutPadding>
          <FavoritesButton
            categoryId={item.id}
            parentId={item.parent_id}
            favoritesType={FavoritesButtonType.sport}
          />
        </S.RightSide>
      ) : (
        <S.RightSide hasFavorite={hasFavorite}>
          <S.CountEvens>{countEvents}</S.CountEvens>
        </S.RightSide>
      )}
    </S.Root>
  );
};

export default CategoryTreeItem;
