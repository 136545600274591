import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div<{ leftBorder?: boolean; rightBorder?: boolean }>`
  width: 50px;
  height: 50px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  color: ${(props): string => props.theme.primaryBackground};
  border-width: 1.4px;
  border-left-style: ${({ leftBorder }): string =>
    leftBorder ? 'solid' : 'none'};
  border-right-style: ${({ rightBorder }): string =>
    rightBorder ? 'solid' : 'none'};
  box-sizing: border-box;
  border-image: linear-gradient(to top, #003b73, rgb(0 0 0 / 0%)) 1 100%;
`;
