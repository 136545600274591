import React from 'react';
import { useTranslation } from 'react-i18next';

import CountdownDoubleWhiteButton from '@features/bettingslip/components/buttons/CountdownDoubleWhiteButton';
import { ButtonSize } from '@features/core/commonComponents/buttons/Button.styled';

import { formatCashoutOffer } from '@common/helpers/cashoutHelper';

import { IDisabledProps } from './Disabled.types';

const Disabled: React.FC<IDisabledProps> = ({
  cashoutOffer,
  cashoutDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <CountdownDoubleWhiteButton
      label={formatCashoutOffer(cashoutOffer, cashoutDisabled)}
      disabled
      isWhite
      isLockIcon
      areaLabelCountdown={t('common.buttons.lock')}
      size={ButtonSize.Small}
    />
  );
};

export default Disabled;
