import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import QRCode from 'react-qr-code';

import CustomLink from '@features/core/routing/CustomLink';
import { PageName, StaticSlugPageName } from '@features/core/routing';
import { createFullLink } from '@features/core/routing/linkAliases';

import { getApplicationLink } from '@common/helpers/appLayoutHelper';
import getImageBasePath from '@common/helpers/getImageBasePath';

import * as S from './ApplicationLinksSection.styled';

const ApplicationLinksSection: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const appLinks = ['ios', 'android'];

  return (
    <S.ApplicationLinksSection>
      <S.LeftLinkContent>
        <S.ApplicationLinksSectionTitle>
          {t('home_page.app_links_section.title')}
        </S.ApplicationLinksSectionTitle>
        <S.LinksSection>
          {map(appLinks, item => {
            return (
              <CustomLink
                href={getApplicationLink(item)}
                ariaLabel={`app-link-${item}`}
                className="link"
                key={item}
              >
                <S.StyledImage
                  src={getImageBasePath(`/images/app-link-${item}.svg`)}
                  alt={`app-link-${item}`}
                />
              </CustomLink>
            );
          })}
        </S.LinksSection>
      </S.LeftLinkContent>
      <div>
        <QRCode
          value={createFullLink(
            PageName.INFORMATION,
            StaticSlugPageName.APP_LINKS,
          )}
          size={70}
          bgColor="#004560"
          fgColor="#FFFFFF"
        />
      </div>
    </S.ApplicationLinksSection>
  );
};

export default ApplicationLinksSection;
