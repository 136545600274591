import React from 'react';
import replace from 'lodash/replace';
import map from 'lodash/map';
import split from 'lodash/split';
import includes from 'lodash/includes';

import {
  iconPaths,
  IIconProps,
} from '@features/core/commonComponents/icon/index';

const CollectionIcon: React.FC<IIconProps> = ({
  name,
  onClick,
  height,
  width,
  className,
  hidden,
  fill,
}) => {
  const symbols = map(split(name || '', ','), (singleName, i) => {
    const iconName =
      singleName && includes(singleName, 'mi-')
        ? replace(singleName, /mi-/g, '')
        : singleName;
    if (!iconName) {
      return null;
    }

    const icon = iconPaths[iconName as string] || iconPaths.default;
    return icon ? (
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        viewBox={
          icon.viewBox ||
          (icon[iconName as string] ? '0 0 1008 1008' : '0 0 1024')
        }
        id={singleName}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        onClick={onClick}
      >
        {icon.g ? (
          <g transform={icon.g?.transform}>
            {icon && <path d={icon.paths.join(' ')} fill={fill} />}
          </g>
        ) : (
          <path d={icon.paths.join(' ')} fill={fill} />
        )}
      </symbol>
    ) : (
      ''
    );
  });

  return (
    <svg
      height={height || 0}
      width={width || 0}
      className={className}
      style={{ display: hidden ? 'none' : 'inline-block' }}
      onClick={onClick}
    >
      {symbols}
    </svg>
  );
};

export default CollectionIcon;
