import React from 'react';

import { ValidateStatus } from '@features/core/form/interfaces/validateStatus';

import * as S from './inputStateIcon.styled';
import IInputStateIcon from './inputStateIcon.types';

const InputStateIcon = (props: IInputStateIcon): JSX.Element => {
  const { state } = props;

  const name =
    state === ValidateStatus.success ? 'check-circle' : 'info-circle';
  const fill = state === ValidateStatus.success ? '#00a651' : '#FF0000';

  return (
    <S.StateIcon
      className="state-icon"
      name={name}
      fill={fill}
      width="20"
      height="20"
    />
  );
};

export default InputStateIcon;
