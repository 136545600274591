import styled from 'styled-components';

import { Icon } from '@features/core/commonComponents/icon';
import { TextLarge, TextNormal } from '@features/core/styles/text.styled';

export const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }): string => theme.space_20};
  background-color: white;
  position: relative;
  gap: ${({ theme }): string => theme.space_10};
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }): string => theme.space_10};
`;

export const Title = styled(TextLarge)`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.tertiaryBackground};
  text-transform: uppercase;
`;

export const Text = styled(TextNormal)`
  width: 100%;
  margin-bottom: ${({ theme }): string => theme.space_10};
  color: ${({ theme }) => theme.tertiaryBackground};
`;

export const InfoIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }): string => theme.space_15};
  right: ${({ theme }): string => theme.space_15};
  width: 12px;
  height: 12px;
  color: inherit;
  cursor: pointer;
`;
