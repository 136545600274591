import find from 'lodash/find';
import reduce from 'lodash/reduce';

import services from '@features/core/services';

import { IEventDetailWidgets } from '@common/interfaces';

// 0.5s * 8 = 4s
export const MAX_EFFORTS = 8;
// 0.5s * 4 = 2s
export const MAX_EFFORTS_LM = 4;

export const sportRadarClientId = '0d6a461c137aa0230f44072ac6917bf2';

export const DETAIL_PAGE_SCRIPT_ID = 'detail_page_script';
export const CATEGORY_PAGE_SCRIPT_ID = 'category_page_script';

export const evalScript = (scripId: string): void => {
  const el = (document && document.getElementById(scripId)) || '';
  const script: string = el && el.innerHTML;
  try {
    window.eval(script);
  } catch (err) {
    services.logger.log(String(err));
  }
};

export const removeWidget = (widgetClassName: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w: Record<string, any> = window;
  if (w.SIR) {
    w.SIR('removeWidget', widgetClassName);
  }
};

// https://widgets.sir.sportradar.com/docs/Widgets.html
export const widgetsStateHandler = (
  state: string,
  data,
  cbLMTWidget,
  cbLastMatchesWidget?,
): void => {
  const isStateError = state === 'license_error';
  const { error } = data;

  if (data.component === IEventDetailWidgets.LmtAndTable) {
    cbLMTWidget(!(isStateError || error));
  }

  if (
    cbLastMatchesWidget &&
    data.component === IEventDetailWidgets.LastMatches
  ) {
    cbLastMatchesWidget(!(isStateError || error));
  }
};

const EMPTY_TABLE_HEIGHT = 28;
export const calculateTableHeight = (
  selector: string,
  constantHeight: number,
): { notEmpty: boolean; calculatedHeight: string | null } => {
  const scrollbars = document.querySelectorAll(selector);
  const notEmpty = !!find(scrollbars, e => e.clientHeight > EMPTY_TABLE_HEIGHT);
  if (notEmpty) {
    const maxTable = 0;
    const contentHeight = reduce(
      scrollbars,
      (acc, el) => {
        if (el.clientHeight > acc) {
          return el.clientHeight;
        }
        return acc;
      },
      maxTable,
    );
    return {
      notEmpty,
      calculatedHeight: `${contentHeight + constantHeight}px`,
    };
  }
  return { notEmpty, calculatedHeight: null };
};
