import React, { ReactElement } from 'react';

import SubmitHoc from '@features/cashout/SubmitHoc';
import { Inputs } from '@features/cashout/components/stateInputs';
import { Queued } from '@features/cashout/components/states/queued';
import { Confirm } from '@features/cashout/components/states/confirm';
import { Disabled } from '@features/cashout/components/states/disabled';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';

import { ICashoutButtonProps, StatusBet } from '@common/interfaces';
import {
  STATE_ACCEPTED,
  STATE_ERROR,
  STATE_QUEUED,
} from '@common/providers/cashout/state';
import {
  cashoutDefaultButtonValue,
  formatCashoutButtonValue,
} from '@common/helpers/cashoutHelper';
import { useCashoutState } from '@common/providers/cashout/useCashout';

import * as S from './CashoutButton.styled';

const CashoutButton: React.FC<ICashoutButtonProps> = ({
  betId,
  stateStatus,
  partCashout,
  submit,
  setStateStatus,
  onSubmitCashoutQueued,
}): ReactElement | null => {
  const minCashout = useCashoutState(state => state.min_cashout);
  const closeSubmit = (): void => {
    setStateStatus(StatusBet.DEFAULT);
  };
  const {
    cashoutOffer,
    requestedCashout,
    cashoutDisabled,
    unavailableSelectionId,
  } = useCashoutItemModel(betId);
  const state = requestedCashout && requestedCashout.state;

  return (
    <S.ButtonWrapper>
      {stateStatus === StatusBet.DEFAULT &&
        (state !== STATE_ACCEPTED || partCashout) &&
        state !== STATE_QUEUED &&
        state !== STATE_ERROR && (
          <S.MaxCashoutButton>
            <Inputs
              formattedValue={formatCashoutButtonValue(cashoutOffer)}
              value={cashoutOffer}
              disabledBtn={
                parseFloat(cashoutOffer) < minCashout ||
                cashoutOffer === cashoutDefaultButtonValue
              }
              onReadySubmit={(): void => setStateStatus(StatusBet.CONFIRM)}
              inputType="max_blue"
              isLock={
                parseFloat(cashoutOffer) < minCashout ||
                !!unavailableSelectionId
              }
            />
          </S.MaxCashoutButton>
        )}

      {(stateStatus === StatusBet.DISABLED || state === STATE_ERROR) &&
        state !== STATE_QUEUED && (
          <Disabled
            cashoutDisabled={cashoutDisabled}
            cashoutOffer={cashoutOffer}
          />
        )}

      {stateStatus === StatusBet.CONFIRM &&
        state !== STATE_ACCEPTED &&
        state !== STATE_QUEUED &&
        state !== STATE_ERROR && (
          <Confirm
            closeSubmit={closeSubmit}
            cashoutDisabled={cashoutDisabled}
            submit={(): void => {
              submit(true, +cashoutOffer, true);
              setStateStatus(StatusBet.DISABLED);
            }}
            cashoutOffer={cashoutOffer}
          />
        )}

      {state === STATE_QUEUED && (
        <Queued
          betId={betId}
          closeSubmit={closeSubmit}
          timerCallBack={onSubmitCashoutQueued}
        />
      )}
    </S.ButtonWrapper>
  );
};

export default SubmitHoc(CashoutButton);
