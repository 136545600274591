import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import head from 'lodash/head';
import map from 'lodash/map';

import { GenericNotification } from '@features/core/commonComponents/genericNotification';
import { PageName } from '@features/core/routing/linkAliases';
import { INotificationIcon } from '@features/core/commonComponents/genericNotification/GenericNotification.types';

import { TERMS_LAYER, MENU_PANEL } from '@common/constants/dialogs';
import {
  HOME_NOTICE,
  RETRY_MESSAGE,
  ONLINE_ACCOUNT_NOTICE,
  VERIFICATION_NOTICE,
  YEARLY_VERIFICATION_NOTICE,
  RESEND_SUCCESS,
  EMAIL_NOTICE,
} from '@common/constants/notifications';
import { isAccountPages } from '@common/helpers/links';
import { isOpenDialog } from '@common/helpers/appState/dialogs';
import {
  addNotification,
  useAppState,
} from '@common/providers/application/useAppState';
import { isMatchWebRoutes } from '@common/providers/router/helper';

import * as S from './TopNotification.styled';
import Verification from './components/Verification';
import RetryMessage from './components/RetryMessage';
import OnlineAccountNotice from './components/OnlineAccountNotice';
import EmailNotice from './components/EmailNotice';
import YearVerification from './components/YearVerification';

const TopNotification: React.FC = () => {
  const home_notifications = useAppState(
    s => s.staticPageContent.home_notifications,
  );
  const notifications = useAppState(state => state.notifications);
  const { pathname } = useLocation();
  const isMyAccount = isAccountPages(pathname);
  const { t } = useTranslation();
  const isMenuOpen = isOpenDialog(MENU_PANEL);
  const isBettingSlipOpen = isMatchWebRoutes(PageName.BETTING_SLIP);
  const isTermsLayerOpen = isOpenDialog(TERMS_LAYER);

  const loadHomeNotice = (): void => {
    if (home_notifications?.body) {
      addNotification({ type: HOME_NOTICE });
    }
  };

  useEffect(loadHomeNotice, []);
  useEffect(loadHomeNotice, [home_notifications]);

  const getNotificationBody = (type: string): React.ReactElement | null => {
    switch (type) {
      case HOME_NOTICE: {
        return (
          <GenericNotification>{home_notifications?.body}</GenericNotification>
        );
      }
      case RETRY_MESSAGE: {
        return <RetryMessage />;
      }
      case ONLINE_ACCOUNT_NOTICE: {
        return <OnlineAccountNotice />;
      }
      case YEARLY_VERIFICATION_NOTICE: {
        return <YearVerification />;
      }
      case VERIFICATION_NOTICE: {
        return <Verification />;
      }
      case EMAIL_NOTICE: {
        return <EmailNotice />;
      }
      case RESEND_SUCCESS: {
        return (
          <GenericNotification iconType={INotificationIcon.success}>
            {t('user.notifications.resend_success')}
          </GenericNotification>
        );
      }
      default:
        return null;
    }
  };

  const noticeType = head(map(notifications, 'type')) || '';

  return (
    <>
      {!isTermsLayerOpen && noticeType && (
        <S.Root
          isMyAccount={isMyAccount}
          isNotificationVisible={isBettingSlipOpen || isMenuOpen}
          className="notifications"
          id="top-notification"
        >
          {getNotificationBody(noticeType)}
        </S.Root>
      )}
    </>
  );
};

export default TopNotification;
