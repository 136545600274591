import React from 'react';
import { useTranslation } from 'react-i18next';
import head from 'lodash/head';

import * as S from '@pages/login/Login.styled';

import { InfoBlock } from '@features/profile/components/infoBlock';
import { Icon } from '@features/core/commonComponents/icon';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { DYNAMIC_NOTE_LAYER } from '@common/constants/dialogs';
import { ButtonType, SidebarSide } from '@common/interfaces';
import {
  closeSidebar,
  useAppState,
} from '@common/providers/application/useAppState';

const DynamicNoteLayer = (): React.ReactElement => {
  const { t } = useTranslation();
  const openedSidebars = useAppState(state => state.openedSidebars);
  const sidebar = head(openedSidebars);
  const data = sidebar?.data;

  const handleClose = (): void => {
    closeSidebar(sidebar?.type as string);
  };

  return (
    <S.Backdrop className="info-limit">
      <S.ContentInner>
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />
        <S.HeaderWrapper>
          <S.IconWrap>
            <Icon name="info-circle" width="28px" height="28px" />
          </S.IconWrap>
          <S.Header>{data?.title || t('modal.attention')}</S.Header>
        </S.HeaderWrapper>
        <S.TextWrapper>
          <InfoBlock message={data?.message || ''} />
        </S.TextWrapper>
        <StyledButton
          onClick={handleClose}
          label={data?.button || 'modal.close'}
          buttonType={ButtonType.Blue}
        />
      </S.ContentInner>
    </S.Backdrop>
  );
};

DynamicNoteLayer.meta = {
  side: SidebarSide.center,
  key: DYNAMIC_NOTE_LAYER,
  props: { type: '' },
};

export { DynamicNoteLayer as Component };

export default DynamicNoteLayer;
