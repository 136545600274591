import React, { useEffect, useRef } from 'react';

import { Icon } from '@features/core/commonComponents/icon';

import { elementScrollIntoView } from '@common/helpers/scrollHelper';

import * as S from './WarningMessage.styled';
import { IWarningMessageProps } from './WarningMessage.types';

const WarningMessage: React.FC<IWarningMessageProps> = props => {
  const { title, text, isError } = props;
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageContainerRef.current) {
      elementScrollIntoView(messageContainerRef.current, {
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);

  return (
    <S.Message isError={isError} ref={messageContainerRef}>
      <Icon
        name={isError ? 'info-circle' : 'check-circle'}
        width="24"
        height="24"
      />
      <S.ContentWrapper>
        <S.Title>{title}</S.Title>
        <S.TextContent>{text}</S.TextContent>
      </S.ContentWrapper>
    </S.Message>
  );
};

export default WarningMessage;
