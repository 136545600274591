import React, { useEffect, useRef, useState } from 'react';

import { DualRingLoader } from '@features/core/commonComponents/dualRingLoader';
import services from '@features/core/services';

import deleteTimeout from '@common/helpers/timeoutHelper';
import { TTimeout } from '@common/interfaces';

import * as S from './StatisticsIframeTab.styled';
import IStatisticsIframeTab from './StatisticsIframeTab.types';

const StatisticsIframeTab = (
  props: IStatisticsIframeTab,
): React.ReactElement => {
  const { betradarId } = props;
  const [iframeReady, setIframeReady] = useState(false);
  const timeoutFrameElmRef = useRef<TTimeout>(null);
  const src = `https://s5.sir.sportradar.com/sportinfowoheader/${services.domainLang}/match/${betradarId}`;

  useEffect(() => {
    return () => {
      deleteTimeout(timeoutFrameElmRef.current);
    };
  }, []);

  const onLoadFrame = (): void => {
    timeoutFrameElmRef.current = setTimeout(setIframeReady, 2000, true);
  };

  return (
    <>
      {betradarId && src && !iframeReady && <DualRingLoader isLight />}
      {src && (
        <S.Frame
          src={src}
          data-qa="statistics-iframe-tab"
          key={betradarId}
          onLoad={onLoadFrame}
          isLoading={!iframeReady}
        />
      )}
    </>
  );
};

export default StatisticsIframeTab;
