import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { UserLinks } from '@features/app/layout/menuTab';
import { Icon } from '@features/core/commonComponents/icon';
import { PageName } from '@features/core/routing/linkAliases';
import { LugasTimer } from '@features/user/components/lugasTimer';
import services from '@features/core/services';

import {
  HAS_BONUS_CODES,
  HAS_BONUS_WALLETS,
  HAS_DEPOSITS,
  HAS_INBOX,
  HAS_LIMITS,
  HAS_OASIS,
  HAS_STATIC_PAGES,
  HAS_UPLOADS,
  HAS_WITHDRAWALS,
  HAS_MY_FAVORITO,
} from '@common/constants/config';
import isTestUser from '@common/helpers/userHelper/isTestUser';
import { getVerificationLink } from '@common/helpers/paymentsHelper/paymentModel';
import { useUserState } from '@common/providers/user/useUserState';
import { IUser } from '@common/interfaces';

import * as S from './UserSection.styled';

const UserSection: React.FC = () => {
  const { t } = useTranslation();
  const user = useUserState(state => state.data);

  return (
    <S.Root>
      <S.ExpandContainer data-qa="menu-btn-showUserSection">
        <S.Avatar className="avatar">
          <Icon name="user" />
        </S.Avatar>
        <div>
          <S.UserNameLabel className="user-info">
            {user?.username}
          </S.UserNameLabel>
          <LugasTimer type="activity" />
        </div>
      </S.ExpandContainer>
      <S.LinksContainer data-qa="menu-user-nav">
        <UserLinks
          links={[
            {
              key: 'my-bets',
              title: t('app-layout.user-menu.gained_bets'),
              location: PageName.USER_BETS,
            },
            {
              key: 'transactions',
              title: t('app-layout.user-menu.transactions'),
              location: PageName.USER_OVERVIEW,
            },
            ...(services.config.get(HAS_DEPOSITS) ||
            services.config.get(HAS_WITHDRAWALS)
              ? [
                  {
                    key: 'deposit',
                    title: t('app-layout.user-menu.deposit-and-withdrawal'),
                    location: PageName.USER_PAYIN,
                  },
                ]
              : []),
            ...(services.config.get(HAS_MY_FAVORITO)
              ? [
                  {
                    key: 'my-favorito',
                    title: t('app-layout.user-menu.my-favorito'),
                    location: PageName.MY_FAVORITO,
                  },
                ]
              : []),
            ...(services.config.get(HAS_INBOX)
              ? [
                  {
                    key: 'my-inbox',
                    title: t('app-layout.user-menu.inbox'),
                    location: PageName.USER_INBOX,
                  },
                ]
              : []),
            ...(services.config.get(HAS_LIMITS)
              ? [
                  {
                    key: 'limit',
                    title: t('app-layout.user-menu.limits'),
                    location: PageName.USER_LIMITS,
                  },
                ]
              : []),
            {
              key: 'notifications',
              title: t('app-layout.navigation.notifications'),
              location: PageName.USER_NOTIFICATIONS,
            },
            ...(services.config.get(HAS_BONUS_CODES) ||
            services.config.get(HAS_BONUS_WALLETS)
              ? [
                  {
                    key: 'bonus',
                    title: t('user.bonuses.header'),
                    location: PageName.USER_BONUSES,
                  },
                ]
              : []),
            {
              key: 'profile',
              title: t('app-layout.user-menu.profile'),
              location: PageName.USER_PROFILE,
            },
            {
              key: 'change-password',
              title: t('app-layout.navigation.change-password'),
              location: PageName.USER_CHANGE_PASSWORD,
            },
            ...(services.config.get(HAS_STATIC_PAGES)
              ? [
                  {
                    key: 'verification',
                    title: t('app-layout.user-menu.verification'),
                    location: getVerificationLink(user as IUser),
                  },
                ]
              : []),
            ...(services.config.get(HAS_LIMITS)
              ? [
                  {
                    key: 'limit-lock',
                    title: t('app-layout.user-menu.limits-lock', {
                      oasis: services.config.get(HAS_OASIS)
                        ? i18n.t('app-layout.user-menu.limits-lock-oasis')
                        : '',
                    }),
                    location: PageName.USER_LIMITS_LOCK,
                  },
                ]
              : []),
            {
              key: 'delete-account',
              title: t('app-layout.user-menu.delete-account'),
              location: PageName.USER_DELETE_ACCOUNT,
            },
            ...(isTestUser()
              ? [
                  {
                    key: 'test-page',
                    title: 'Test page',
                    location: PageName.TEST_PAGE,
                  },
                ]
              : []),
            ...(services.config.get(HAS_UPLOADS)
              ? [
                  {
                    key: 'uploads',
                    title: t('app-layout.user-menu.upload_documents'),
                    location: PageName.USER_UPLOADS,
                  },
                ]
              : []),
          ]}
        />
      </S.LinksContainer>
    </S.Root>
  );
};

export default UserSection;
