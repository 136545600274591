/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import * as S from '@pages/login/Login.styled';

import { InfoBlock } from '@features/profile/components/infoBlock';
import { Icon } from '@features/core/commonComponents/icon';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { GCMS_FORM_SUBMISSION } from '@common/constants/dialogs';
import { ButtonType, SidebarSide } from '@common/interfaces';
import { closeSidebar } from '@common/providers/application/useAppState';

const AlleFurMalleLayer = (): React.ReactElement => {
  const { t } = useTranslation();
  const { statusSuccess } = useTheme();

  const handleClose = (): void => {
    closeSidebar(GCMS_FORM_SUBMISSION);
  };

  return (
    <S.Content className="alle-popup-wrapper">
      <S.ContentInner className="alle-popup-inner">
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />
        <S.TitleWrapper>
          <S.IconWrap color={statusSuccess}>
            <Icon name="check-circle" width="28px" height="28px" />
          </S.IconWrap>
          <S.Header color={statusSuccess}>
            {t('alleFurMallePopup.title')}
          </S.Header>
        </S.TitleWrapper>
        <InfoBlock message={t('alleFurMallePopup.content')} />
      </S.ContentInner>
      <S.ButtonInner bgColor={statusSuccess}>
        <StyledButton
          className="alle-popup-button"
          onClick={(e): void => {
            e.preventDefault();
            handleClose();
          }}
          label="helpers.ok"
          buttonType={ButtonType.Blue}
        />
      </S.ButtonInner>
    </S.Content>
  );
};

AlleFurMalleLayer.meta = {
  side: SidebarSide.center,
  key: GCMS_FORM_SUBMISSION,
};

export { AlleFurMalleLayer as Component };

export default AlleFurMalleLayer;
