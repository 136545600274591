import React from 'react';

import { Icon } from '@features/core/commonComponents/icon';

import {
  StatusCode,
  IBetStatusSquare,
  TReturnIconName,
} from './BetStatusSquare.types';
import StatusSquare from './BetStatusSquare.styled';

const BetStatusSquare: React.FC<IBetStatusSquare> = props => {
  const { status, size } = props;
  const fill = status === StatusCode.MATRIX_OPEN ? { fill: 'inherit' } : {};
  const getIconNameByStatus = (): TReturnIconName => {
    switch (status) {
      case StatusCode.WON:
        return 'check';
      case StatusCode.CANCELLED:
      case StatusCode.MATRIX_OPEN:
        return 'minus';
      case StatusCode.OPEN:
        return 'empty-circle';
      case StatusCode.LOST:
        return 'close';
      default:
        return '';
    }
  };

  return (
    <StatusSquare colorSquare={status}>
      <Icon
        width={size || '12'}
        height={size || '12'}
        name={getIconNameByStatus()}
        {...fill}
      />
    </StatusSquare>
  );
};

export default BetStatusSquare;
