import { IUser } from '@common/interfaces';

/**
 * isKioskUser
 * checks if user is registered in shop;
 *
 * @param {IUser | null} user
 * @returns {boolean} isKioskUser
 */
export default (user: IUser | null): boolean => {
  return user?.isKioskUser === 1;
};
