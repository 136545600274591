import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';

import * as S from './StyledButton.styled';
import IButtonProps from './StyledButton.types';

const StyledButton: React.FC<IButtonProps> = ({
  disabled = false,
  onClick,
  showLoader = false,
  label,
  size,
  dataQa = null,
  tabIndex,
  buttonType,
  className = '',
  type = 'button',
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <S.StyledButton
      onClick={onClick}
      size={size}
      disabled={disabled}
      data-qa={dataQa}
      tabIndex={tabIndex}
      buttonType={buttonType}
      className={`${className} ${icon?.name ? 'with-icon' : ''} ${
        icon?.isSingle ? 'single-icon' : ''
      }`}
      type={type}
    >
      {!showLoader && !icon?.isSingle && <span>{t(label)}</span>}
      {showLoader && <div className="loader" />}
      {icon?.name && (
        <Icon
          width={`${icon.width}`}
          height={`${icon.height}`}
          name={icon.name}
          className={icon?.className || ''}
        />
      )}
    </S.StyledButton>
  );
};

export default StyledButton;
