import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import split from 'lodash/split';
import join from 'lodash/join';
import replace from 'lodash/replace';

import { PageName } from '@features/core/routing/linkAliases';
import { useServices } from '@features/core/services';
import { colorSchema } from '@features/core/theming/contexTheme';
import TransactionIconsWrapper from '@features/transactions/components/transactionItem/transactionIcon/TransactionIconsWrapper';
import { Icon } from '@features/core/commonComponents/icon';

import { elementScrollIntoView } from '@common/helpers/scrollHelper';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { setLocation } from '@common/providers/router/helper';
import ScrollTopElement from '@common/helpers/scrollTopElement';
import { showFooter } from '@common/providers/application/useAppState';
import {
  setSelectedTransactionId,
  useTransactions,
} from '@common/providers/transactions/useTransactions';
import { setSelectedBetId } from '@common/providers/bets/useBets';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

import * as S from '../Transaction.styled';
import { ITransactionItem, SuccessType } from '../Transaction.types';

const isDesktop = isDesktopView();

const TransactionItem: React.FC<ITransactionItem> = props => {
  const { transaction, isAccountSummary } = props;
  const { t } = useTranslation();
  const { domainLang } = useServices();
  const selectedTransactionId = useTransactions(s => s.selectedTransactionId);

  const getTransactionProfitType = (): SuccessType => {
    if (parseFloat(transaction.amount) > 0) {
      return 'win';
    }
    if (parseFloat(transaction.amount) < 0) {
      return 'loss';
    }
    return 'none';
  };
  const transactionProfitType = getTransactionProfitType();

  const getTransactionDescription = (): string => {
    if (transaction.description === t('transactions.bet_internal')) {
      const isWinEvent = parseFloat(transaction.amount) > 0;
      if (isWinEvent) {
        return t('common.labels.profit');
      }

      return t('transactions.bet');
    }
    if (/\(/.test(transaction.description || '')) {
      return join(split(transaction.description, '('), '<br/>(');
    }

    return transaction.description || '';
  };

  const description = useMemo(() => getTransactionDescription(), [
    transaction.description,
    transactionProfitType,
  ]);

  const hasStorno = !!parseFloat(transaction.canceled);

  const selectItem = (): void => {
    if (isAccountSummary) {
      return;
    }
    const betId = transaction.bet_id;
    const detailEl = document.getElementById('btn-to-bet-detail');
    if (isDesktop) {
      if (detailEl) {
        elementScrollIntoView(detailEl, {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
      ScrollTopElement('main-container');
      setSelectedBetId(betId);
      setSelectedTransactionId(transaction.transaction_id);
      return;
    }
    if (betId) {
      showFooter();
      setLocation(PageName.USER_BET_ITEM, { id: betId });
    }
  };

  const getColor = (): string => {
    const { textMain, accent } = colorSchema;
    if (isAccountSummary) {
      return '#004560';
    }
    return parseFloat(transaction.amount) > 0 ? accent : textMain;
  };

  // todo temporary, till backend fixes
  const fixCustomerCardDescription = (text): string => {
    return replace(
      text,
      /Aircash Marketplace|Aircash|Customer Card plus/g,
      t('payments.payment_methods.shop_money_label'),
    );
  };

  return (
    <S.Container
      role="presentation"
      onClick={selectItem}
      isAccountSummary={isAccountSummary}
      className={
        transaction.transaction_id === selectedTransactionId &&
        transaction.bet_id &&
        !isAccountSummary
          ? 'active'
          : ''
      }
    >
      <S.Wrapper>
        {!isDesktop && (
          <S.ColIcon hasStorno={hasStorno}>
            <TransactionIconsWrapper
              transaction={transaction}
              isAccountSummary={isAccountSummary}
            />
          </S.ColIcon>
        )}
        <S.ColTitle isAccountSummary={isAccountSummary} hasStorno={hasStorno}>
          <span className="hours" data-qa="account-summary-hours">
            {domainLang === 'de'
              ? `${formatDate(transaction.localeZoneDate, 'HH:mm')} Uhr`
              : `${formatDate(transaction.localeZoneDate, 'HH:mm')}`}
          </span>
          <S.ColTitleLabel isAccountSummary={isAccountSummary}>
            {transaction.is_bonus === '1' && (
              <Icon
                name="gift"
                width="13"
                height="16"
                fill={isAccountSummary ? '#004560' : '#fff'}
              />
            )}
            {transaction.is_shop_emoney === '1' && (
              <Icon
                name="credit-card"
                width="13"
                height="16"
                fill={isAccountSummary ? '#004560' : '#fff'}
              />
            )}
            <S.ColTitleWrapper
              isAccountSummary={!!isAccountSummary}
              className="label"
              data-qa="account-summary-cashout"
              dangerouslySetInnerHTML={{
                __html: fixCustomerCardDescription(description),
              }}
            />
          </S.ColTitleLabel>
        </S.ColTitle>
      </S.Wrapper>
      <S.ColAmount
        hasStorno={hasStorno}
        data-qa="account-summary-amount"
        amountColor={getColor()}
      >
        <S.Sum>
          {Number(transaction.amount) < 0 ? '- ' : '+ '}
          {formatBalance(
            Number(transaction.amount) < 0
              ? transaction.amount.slice(1)
              : transaction.amount,
          )}
        </S.Sum>
      </S.ColAmount>
    </S.Container>
  );
};

export default React.memo(TransactionItem);
