import React, { useCallback } from 'react';

import { ScoreboardHoc } from '@features/events/components/scoreboards/scores';
import {
  ItemDiv,
  ScoreboardDiv,
  ScoreDiv,
  ScoreActiveDiv,
  ICommonScoreProps,
} from '@features/events/components/scoreboards/factoryScoreboards';
import AnimatedScoreValue from '@features/events/components/scoreboards/scores/common/AnimatedScoreValue';

import {
  getHome,
  getAway,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';

const GenericScore = (props: ICommonScoreProps): React.ReactElement => {
  const { score, sportName } = props;
  const hasPenalty = Boolean(score?.periods?.PT);
  const hasOvertime = Boolean(score?.periods['1H_OT']);
  const homeScore = getHome(score);
  const awayScore = getAway(score);

  // TODO: refactor beatScore logic
  // const homeScore = usePrevious(getHome(score)) || getHome(score);
  // const awayScore = usePrevious(getAway(score)) || getAway(score);
  // useEffect(() => {
  //   if (homeScore !== getHome(score) && beatScore) {
  //     beatScore(true);
  //   }
  //   if (awayScore !== getAway(score) && beatScore) {
  //     beatScore(false);
  //   }
  // }, [score]);

  const renderPenaltyScore = useCallback((): JSX.Element | null => {
    if (score?.periods?.PT) {
      return (
        <ItemDiv>
          <ScoreActiveDiv>
            <AnimatedScoreValue score={score.periods.PT[0]} />
          </ScoreActiveDiv>
          <ScoreActiveDiv>
            <AnimatedScoreValue score={score.periods.PT[1]} />
          </ScoreActiveDiv>
        </ItemDiv>
      );
    }

    return null;
  }, [score]);

  const renderOvertimeScore = useCallback(
    (): JSX.Element => (
      <ItemDiv>
        <ScoreActiveDiv total>
          <AnimatedScoreValue score={homeScore} />
        </ScoreActiveDiv>
        <ScoreActiveDiv total>
          <AnimatedScoreValue score={awayScore} />
        </ScoreActiveDiv>
      </ItemDiv>
    ),
    [homeScore, awayScore],
  );

  const renderRegularScore = useCallback(
    (): JSX.Element => (
      <>
        <ItemDiv>
          <ScoreActiveDiv total>
            <AnimatedScoreValue score={homeScore} />
          </ScoreActiveDiv>
          <ScoreActiveDiv total>
            <AnimatedScoreValue score={awayScore} />
          </ScoreActiveDiv>
        </ItemDiv>

        {score.periods && score.periods['2H'] && score.periods['1H'] && (
          <ItemDiv>
            <ScoreDiv className="period">
              <AnimatedScoreValue score={score.periods['1H'][0]} isPeriod />
            </ScoreDiv>
            <ScoreDiv className="period">
              <AnimatedScoreValue score={score.periods['1H'][1]} isPeriod />
            </ScoreDiv>
          </ItemDiv>
        )}
      </>
    ),
    [homeScore, awayScore, score.periods],
  );

  if (!score.score) {
    return <></>;
  }

  return (
    <ScoreboardDiv className={`generic-scoreboard ${sportName}`}>
      {hasPenalty && renderPenaltyScore()}
      {hasOvertime && !hasPenalty && renderOvertimeScore()}
      {!hasOvertime && !hasPenalty && renderRegularScore()}
    </ScoreboardDiv>
  );
};

export default ScoreboardHoc(GenericScore);
