import React from 'react';

import { DualRingLoader } from '@features/core/commonComponents/dualRingLoader';

import useNotification from '@common/hooks/useNotification';
import { useUserState } from '@common/providers/user/useUserState';
import { useAppState } from '@common/providers/application/useAppState';

import * as S from './BlockLoader.styled';
import IBlockLoader from './BlockLoader.types';

const BlockLoader: React.FC<IBlockLoader> = ({
  children,
  showLoader,
  childShouldHaveOpacity,
}) => {
  const isFooter = useAppState(state => state.showFooter);
  const isTopNotification = useNotification();
  const { switchAccountLoading } = useUserState.getState();

  if (!showLoader) {
    return <>{children}</>;
  }

  return (
    <S.Container
      isTopNotification={isTopNotification}
      isFooter={isFooter}
      switchAccountLoading={switchAccountLoading}
    >
      <S.LoaderContainer>
        <DualRingLoader />
      </S.LoaderContainer>
      <S.Children childShouldHaveOpacity={childShouldHaveOpacity}>
        {children}
      </S.Children>
    </S.Container>
  );
};

export default BlockLoader;
