import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';
import { TextSM } from '@features/core/styles/text.styled';
import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { ONLINE_ACCOUNT_NOTICE_REMOVE } from '@common/constants/cookie';
import { ONLINE_ACCOUNT_NOTICE } from '@common/constants/notifications';
import { ButtonType } from '@common/interfaces';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import {
  createExpiresStamp,
  isDesktopView,
  ONE_WEEK_IN_SEK,
} from '@common/helpers/deviceUtil';
import { isAccountPages } from '@common/helpers/links';
import { removeNotification } from '@common/providers/application/useAppState';

import * as S from '../TopNotification.styled';

const isDesktop = isDesktopView();

const OnlineAccountNotice: React.FC = (): React.ReactElement => {
  const { pathname } = useLocation();
  const isOnlineAccountPage =
    isMatchWebRoutes(PageName.CREATE_ONLINE_WALLET) ||
    isMatchWebRoutes(PageName.UPDATE_ONLINE_ACCOUNT);
  const isMyAccount = isAccountPages(pathname);
  const hasRightCol = isMyAccount && isDesktop;

  const { t } = useTranslation();
  if (isOnlineAccountPage) {
    return <></>;
  }

  const onClose = (): void => {
    removeNotification(ONLINE_ACCOUNT_NOTICE);
    services.cookie.set(ONLINE_ACCOUNT_NOTICE_REMOVE, '1', {
      expires: createExpiresStamp(ONE_WEEK_IN_SEK),
    });
  };

  return (
    <S.NotificationWrapper hasRightCol={hasRightCol} id="online-account">
      <S.NotificationHeader>
        <Icon
          width="27"
          height="27"
          name="info-circle"
          className="info-circle"
        />
        <S.HighlightBoldTitle>
          {t('user.create_online_account.notification.title')}
        </S.HighlightBoldTitle>
        <Icon
          width="15"
          height="15"
          name="close"
          className="close-icon"
          onClick={onClose}
        />
      </S.NotificationHeader>
      <S.GridWrapper>
        <TextSM>
          <span
            dangerouslySetInnerHTML={{
              __html: t('user.create_online_account.notification.body'),
            }}
          />
        </TextSM>
        <StyledButton
          onClick={(): void => setLocation(PageName.CREATE_ONLINE_WALLET)}
          dataQa="btn-create-online-wallet"
          className="emailBtn"
          buttonType={ButtonType.Blue}
          label="user.create_online_account.notification.button"
        />
      </S.GridWrapper>
    </S.NotificationWrapper>
  );
};

export default OnlineAccountNotice;
