import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import constant from 'lodash/constant';

import { useServices } from '@features/core/services';
import { Toggle } from '@features/core/commonComponents/toggle';
import { IToggleValues } from '@features/app/layout/acceptCookieLayer/AcceptCookie.types';
import mockedContentData from '@features/app/layout/acceptCookieLayer/mockedContentData.json';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { ACCEPTED_COOKIE } from '@common/constants/cookie';
import { ACCEPT_COOKIE_LAYER } from '@common/constants/dialogs';
import { ButtonSize, ButtonType, SidebarSide } from '@common/interfaces';
import {
  closeSidebar,
  setStaticPageContent,
  useAppState,
} from '@common/providers/application/useAppState';
import {
  getAddTagEventDataCookie,
  getCookieConfig,
  getToggleData,
  initialToggle,
  saveCookieHandler,
} from '@common/helpers/acceptCookieLayerHelper';
import { addGTagEvent } from '@common/helpers/marketing';
import { useUserState } from '@common/providers/user/useUserState';

import * as S from './AcceptCookieLayer.styled';

const AcceptCookieLayer = (): React.ReactElement => {
  const isLoggedIn = useUserState(s => s.isLoggedIn);
  const { domainLang, cookie } = useServices();
  const staticPageContent = useAppState(state => state.staticPageContent);
  const slug = 'internal/cookie-consent-data-2';
  let content = mockedContentData;
  try {
    content = JSON.parse(String(staticPageContent?.[slug]?.body));
  } catch (e) {
    content = mockedContentData;
  }

  const acceptCookie = cookie.get(ACCEPTED_COOKIE);

  const cookieConfig = getCookieConfig(content?.groups);

  const [isArrowInfoOpen, setArrowInfoOpen] = useState({
    '1': false,
    '2': false,
    '3': false,
  });

  const [isToggleEnabled, setIsToggleEnabled] = useState(initialToggle);

  const onArrowClickHandler = (index): void => {
    setArrowInfoOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const onToggleEnabledHandler = (id: string): void => {
    if (id === 'essential') {
      return;
    }
    setIsToggleEnabled(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const onSubmitHandler = (type): void => {
    const getCookieValue = (): IToggleValues => {
      switch (type) {
        case 'decline':
          return initialToggle;
        case 'acceptAll':
          return mapValues(initialToggle, constant(true));
        default:
          return isToggleEnabled;
      }
    };

    if (type !== 'save') {
      setIsToggleEnabled(getCookieValue());
    }
    const event = getAddTagEventDataCookie(content, getCookieValue());
    saveCookieHandler(event);
    addGTagEvent(event);
    closeSidebar(ACCEPT_COOKIE_LAYER);
  };

  useEffect(() => {
    if (acceptCookie) {
      setIsToggleEnabled(getToggleData(content, acceptCookie));
    }
  }, [acceptCookie, staticPageContent?.[slug]?.body]);

  const getToggleColours = (id: string, isToggle?: boolean): string => {
    if (isToggle) {
      if (isToggleEnabled[id]) {
        return id === 'essential' ? '#99d7e4' : '#009cbb';
      }

      return '#ccdadd';
    }

    return isToggleEnabled[id] ? 'white' : '#004560';
  };

  useEffect(() => {
    if (isLoggedIn) {
      setStaticPageContent({
        lang: domainLang,
        types: [slug],
      });
    }
  }, []);

  return (
    <S.ContentWrapper>
      <S.TitleWrapper className="top-container">
        <S.Title
          dangerouslySetInnerHTML={{
            __html: content?.cookie_title,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: content?.cookie_info,
          }}
        />
      </S.TitleWrapper>
      <div className="options">
        {map(cookieConfig?.policies, (item, elementIndex) => {
          return (
            <S.MainInfoContentItem key={elementIndex + item.id} id={item.id}>
              <S.ItemContentWrapper>
                <S.ItemHeaderSection>
                  <div className="header">{item.name}</div>
                  <div className="subheader">{item.title}</div>
                </S.ItemHeaderSection>
                <S.ItemSwitcher>
                  <Toggle
                    onClick={(): void => onToggleEnabledHandler(item.id)}
                    width="50px"
                    height="25px"
                    toggleBg={getToggleColours(item.id, true)}
                    pointerBg={getToggleColours(item.id)}
                    checkedState={isToggleEnabled[item.id]}
                    enabled={item.id !== 'essential'}
                  />
                </S.ItemSwitcher>
                <S.ItemArrow
                  onClick={(): void => onArrowClickHandler(elementIndex)}
                >
                  <i
                    className={`arrow-${
                      isArrowInfoOpen[elementIndex] ? 'up' : 'down'
                    }`}
                  />
                </S.ItemArrow>
              </S.ItemContentWrapper>
              <div
                className={`info-content info-${
                  isArrowInfoOpen[elementIndex] ? 'up' : 'down'
                }`}
              >
                <div className="border" />
                <S.ItemContentArrowInfo>
                  {map(item.info, element => {
                    return (
                      <div
                        className="info-content-item"
                        key={element}
                        dangerouslySetInnerHTML={{
                          __html: element,
                        }}
                      />
                    );
                  })}
                </S.ItemContentArrowInfo>
              </div>
            </S.MainInfoContentItem>
          );
        })}
      </div>
      <S.StickyButtonsWrapper>
        <div className="border" />
        <S.ButtonsWrapper>
          {map(cookieConfig?.permissionLabels, buttonEl => {
            return (
              <StyledButton
                onClick={(): void => {
                  onSubmitHandler(buttonEl.id);
                }}
                label={buttonEl.title}
                key={buttonEl.id}
                className={buttonEl.id}
                buttonType={
                  buttonEl.id === 'acceptAll'
                    ? ButtonType.Blue
                    : ButtonType.DarkOutlined
                }
                size={ButtonSize.Big}
              />
            );
          })}
        </S.ButtonsWrapper>
      </S.StickyButtonsWrapper>
    </S.ContentWrapper>
  );
};

AcceptCookieLayer.meta = {
  side: window.innerWidth > 481 ? SidebarSide.center : SidebarSide.bottom,
  key: ACCEPT_COOKIE_LAYER,
  preventDarkening: false,
  fullScreen: true,
};

export { AcceptCookieLayer as Component };

export default AcceptCookieLayer;
