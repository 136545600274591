import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoBlock } from '@features/profile/components/infoBlock';
import { Icon } from '@features/core/commonComponents/icon';
import { getPageURL, PageName } from '@features/core/routing';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { LUGAS_COOLDOWN } from '@common/constants/dialogs';
import { ButtonType, SidebarSide } from '@common/interfaces';
import { closeSidebar } from '@common/providers/application/useAppState';

import * as S from './LugasCooldown.styled';

const LugasCooldown = (): React.ReactElement => {
  const { t } = useTranslation();

  const handleClose = (): void => {
    closeSidebar(LUGAS_COOLDOWN);
  };

  return (
    <S.Backdrop className="info-limit">
      <S.Content>
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />
        <S.TitleInner>
          <Icon name="info-circle" width="30px" height="30px" />
          <S.Title>{t('user.lugas.cooldown_header_title')}</S.Title>
        </S.TitleInner>
        <InfoBlock message={t('user.lugas.cooldown_main_text')} />
        <StyledButton
          onClick={handleClose}
          label="user.lugas.close"
          buttonType={ButtonType.Blue}
        />
        <StyledButton
          onClick={(): void => {
            handleClose();
            window.open(getPageURL(PageName.FAQ_LUGAS));
          }}
          label="user.lugas.what_is_lugas"
          className="what-is-lugas-btn"
          buttonType={ButtonType.DarkOutlined}
        />
      </S.Content>
    </S.Backdrop>
  );
};

LugasCooldown.meta = {
  side: SidebarSide.center,
  key: LUGAS_COOLDOWN,
};

export { LugasCooldown as Component };

export default LugasCooldown;
