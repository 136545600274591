import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useServices } from '@features/core/services';
import DualRingLoader from '@features/core/commonComponents/dualRingLoader/DualRingLoader';
import GenericNotification from '@features/core/commonComponents/genericNotification/GenericNotification';

import deleteTimeout from '@common/helpers/timeoutHelper';
import { IEventDetailWidgetTabs, TTimeout } from '@common/interfaces';
import {
  calculateTableHeight,
  CATEGORY_PAGE_SCRIPT_ID,
  evalScript,
  MAX_EFFORTS,
  MAX_EFFORTS_LM,
  removeWidget,
} from '@common/helpers/eventsHelper/eventDetailWidgetTabHelper';
import { isNativeApp } from '@common/helpers/links';

import * as S from './WidgetsTabWrapper.styled';
import IWidgetsTabWrapper from './WidgetsTabWrapper.types';

const WidgetsTabWrapper: React.FC<IWidgetsTabWrapper> = ({
  children,
  currentTab,
  qa,
  scriptId,
}) => {
  const { domainLang } = useServices();
  const { t } = useTranslation();
  const location = useLocation();
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const showTableTitle = urlParams.get('showtabletitle');
  const nativeApp = isNativeApp(search);

  const showTitle =
    showTableTitle === 'true' || scriptId === CATEGORY_PAGE_SCRIPT_ID;

  const timerButton = useRef<TTimeout>(null);
  const counterButton = useRef(0);

  const maxEffortsButton =
    currentTab === IEventDetailWidgetTabs.table ? MAX_EFFORTS : MAX_EFFORTS_LM;

  const [tableHasContent, setTableHasContent] = useState<boolean | null>(null);
  const [tableHeight, setTableHeight] = useState<string | null>(null);
  const scrollbarSelector = `.livematchtracker_${scriptId} .sr-scrollbars__inner`;
  const tableHeader = 24;
  const tableTitle = 36;
  const constantHeight = tableHeader + tableTitle;

  const activateTable = (): void => {
    const button = document.querySelector(
      `.livematchtracker_${scriptId} [data-test-tab="standings"]`,
    );
    if (button) {
      (button as HTMLElement).click();
      setTimeout(() => {
        const { notEmpty, calculatedHeight } = calculateTableHeight(
          scrollbarSelector,
          constantHeight,
        );
        setTableHasContent(notEmpty);
        setTableHeight(calculatedHeight);
      }, 500);
    } else if (counterButton.current <= maxEffortsButton) {
      counterButton.current += 1;
      timerButton.current = setTimeout(activateTable, 500);
      if (counterButton.current > maxEffortsButton) {
        setTableHasContent(false);
      }
    }
  };

  useEffect(() => {
    activateTable();
  }, []);

  useEffect(() => {
    evalScript(scriptId);
  }, [domainLang]);

  useEffect(() => {
    return () => {
      removeWidget(`.livematchtracker_${scriptId}`);
      deleteTimeout(timerButton.current);
    };
  }, []);

  return (
    <S.Wrapper
      data-qa={qa}
      height={scriptId === CATEGORY_PAGE_SCRIPT_ID ? tableHeight : 'unset'}
    >
      <>
        {counterButton.current < maxEffortsButton &&
          tableHasContent === null && (
            <DualRingLoader backgroundColor="inherit" />
          )}
        {children}
        {currentTab === 'table' && (
          <>
            {counterButton.current <= maxEffortsButton && (
              <>
                {tableHasContent && showTitle && (
                  <S.TableTitle nativeApp={nativeApp}>
                    {t('common.labels.table')}
                  </S.TableTitle>
                )}
                <S.TableWrapper
                  className={`livematchtracker_${scriptId}`}
                  tableHasContent={tableHasContent}
                  nativeApp={nativeApp}
                />
              </>
            )}
            {tableHasContent === false && (
              <GenericNotification>{t('events.no_table')}</GenericNotification>
            )}
          </>
        )}
      </>
    </S.Wrapper>
  );
};

export default WidgetsTabWrapper;
