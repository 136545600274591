import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from '@features/core/commonComponents/icon';
import { ButtonSize } from '@features/core/commonComponents/buttons/Button.styled';
import CustomLink from '@features/core/routing/CustomLink';
import services from '@features/core/services';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { HAS_SMART_APP_BANNER } from '@common/constants/config';
import { getApplicationLink } from '@common/helpers/appLayoutHelper';
import { isNativeApp, isWebView } from '@common/helpers/links';
import getImageBasePath from '@common/helpers/getImageBasePath';

import * as S from './ApplicationAdvBanner.styled';
import { IHeaderProps } from './ApplicationAdvBanner.types';

export { ButtonSize } from '@features/core/commonComponents/buttons/Button.styled';

const ApplicationAdvBanner: React.FC<IHeaderProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const onCloseHandler = (): void => {
    onClose();
  };

  if (
    isNativeApp(search) ||
    isWebView(search) ||
    !services.config.get(HAS_SMART_APP_BANNER)
  ) {
    return null;
  }

  return (
    <S.Root id="application-adv-banner">
      <Icon
        name="close"
        fill="#004560"
        onClick={onCloseHandler}
        width="16"
        height="16"
        className="closeIcon"
      />
      <S.CenterSection>
        <S.ImageStyled
          src={getImageBasePath('/images/icon_pwa/x64.png')}
          alt="logo"
        />
        <S.TextWrapper>
          <S.TextContent>
            {t('application_adv_mob_banner.content_title')}
          </S.TextContent>
          <S.TextContent>
            {t('application_adv_mob_banner.content_text')}
          </S.TextContent>
        </S.TextWrapper>
      </S.CenterSection>
      <S.RightSection>
        <CustomLink href={getApplicationLink()} ariaLabel="app-link">
          <StyledButton
            onClick={onCloseHandler}
            label="common.buttons.to_application_store"
            size={ButtonSize.Big}
            className="adv-banner-btn"
          />
        </CustomLink>
      </S.RightSection>
    </S.Root>
  );
};

export default ApplicationAdvBanner;
