import forEach from 'lodash/forEach';

export type TTimeout = NodeJS.Timeout | null | number;

// eslint-disable-next-line
export const deleteInterval = (...intervalList: TTimeout[]) => {
  // eslint-disable-next-line
  forEach(intervalList, (interval: TTimeout) => {
    if (interval) {
      clearInterval(interval as NodeJS.Timeout);
    }
  });
};
