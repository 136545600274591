import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoBlock } from '@features/profile/components/infoBlock';
import { Icon } from '@features/core/commonComponents/icon';
import { PageName } from '@features/core/routing';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { LUGAS_INACTIVE, MENU_PANEL } from '@common/constants/dialogs';
import { ButtonType, SidebarSide } from '@common/interfaces';
import logoutUser from '@common/providers/user/actions/logoutUser';
import { setLocation } from '@common/providers/router/helper';
import { closeSidebar } from '@common/providers/application/useAppState';

import * as S from './LugasInactive.styled';

const LugasInactive = (): React.ReactElement => {
  const { t } = useTranslation();

  const handleClose = (): void => {
    closeSidebar(LUGAS_INACTIVE);
  };

  const handleLogout = async (): Promise<void> => {
    handleClose();
    closeSidebar(MENU_PANEL);
    await logoutUser();
    setLocation(PageName.HOME);
  };

  return (
    <S.Backdrop className="info-limit">
      <S.Content>
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />
        <S.TitleInner>
          <Icon name="info-circle" width="30px" height="30px" />
          <S.Title>{t('user.lugas.inactive_header_title')}</S.Title>
        </S.TitleInner>
        <InfoBlock message={t('user.lugas.inactive_main_text')} />

        <StyledButton
          label="user.lugas.stay_logged_in"
          onClick={handleClose}
          buttonType={ButtonType.Blue}
        />
        <StyledButton
          label="app-layout.user-menu.logout"
          onClick={handleLogout}
          buttonType={ButtonType.DarkOutlined}
          className="lugas-inactive-logout-btn"
        />
      </S.Content>
    </S.Backdrop>
  );
};

LugasInactive.meta = {
  side: SidebarSide.center,
  key: LUGAS_INACTIVE,
};

export { LugasInactive as Component };

export default LugasInactive;
