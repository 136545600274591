import includes from 'lodash/includes';

const preventTypingSpecialSymbols = (event): void => {
  // Allow: backspace, delete, tab, escape, left arrow, right arrow Numpad numbers(0-9)
  const charactersCode = [
    8,
    46,
    9,
    27,
    37,
    39,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105,
  ];
  if (includes(charactersCode, event.which)) {
    // Let it happen, don't do anything
    return;
  }

  // Ensure that it is a number and stop the keypress
  if (event.which < 48 || event.which > 57 || event.shiftKey) {
    event.preventDefault();
  }
};

export default preventTypingSpecialSymbols;
