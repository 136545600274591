import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import services, { useServices } from '@features/core/services';
import { DetailEvent } from '@features/detail/components/detailEvent';
import { getPageURL, PageName } from '@features/core/routing/linkAliases';
import { PageHeader } from '@features/core/commonComponents/pageHeader';
import { DualRingLoader } from '@features/core/commonComponents/dualRingLoader';
import { GenericNotification } from '@features/core/commonComponents/genericNotification';
import { useEventsModel } from '@features/events/useEventsModel';

import { HAS_EVENT_DETAIL_POPUP } from '@common/constants/config';
import { EventListTypes, ICategory } from '@common/interfaces';
import { getCategoryById } from '@common/helpers/categories/categoriesModel';
import { goBack } from '@common/providers/router/helper';
import {
  getEventsList,
  resetEventsList,
  setSelectedEvent,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';
import { toggleContentScroll } from '@common/providers/application/useAppState';
import { closeEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';
import {
  getDetailEventStage,
  EventDetailStage,
} from '@common/helpers/eventsHelper/eventDetailStageHelper';
import { isEndedEvent } from '@common/helpers/eventsHelper/eventStatusHelper';
import { IEndedEvent } from '@common/interfaces/events/IEvents';

import { IDetail } from './Detail.types';
import * as S from './Detail.styled';

const DEFAULT_CATEGORY = { id: '', label: '' };

const Detail: React.FC<IDetail> = props => {
  const { t } = useTranslation();
  const { id, listType } = props;
  const { domainLang, isDesktopView } = useServices();
  const { event, category: cat } = useEventsModel(id, listType);
  const eventDetailState = useEventsListState(s => s.detail);
  const categoriesTree = useLiveCategories(s => s.sportCategoriesTree.data);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const queryId = urlParams.get('events');
  const isEventDetailPopup = services.config.get(HAS_EVENT_DETAIL_POPUP);
  const isEventEnded = isEndedEvent(event);

  useEffect(() => {
    if (!queryId) {
      setSelectedEvent(null);
    }
  }, [search]);

  useEffect(() => {
    return () => resetEventsList(EventListTypes.detail);
  }, []);

  useEffect(() => {
    if (!isDesktopView) {
      toggleContentScroll('disable');
    }
    return () => {
      if (!isDesktopView) {
        toggleContentScroll('enable');
      }
    };
  }, []);

  const detailWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (detailWrapperRef?.current) {
      detailWrapperRef?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [detailWrapperRef, id]);

  const category = useMemo(() => {
    if (!event?.category_id) {
      return DEFAULT_CATEGORY;
    }

    return (
      cat ||
      getCategoryById(categoriesTree, event.category_id) ||
      DEFAULT_CATEGORY
    );
  }, [event]) as ICategory;

  const onCloseNotificationHandler = (): void => {
    closeEventDetail();
    if (isEventDetailPopup) {
      goBack();
    }
  };

  useEffect(() => {
    if (!isDesktopView || listType === EventListTypes.detail) {
      getEventsList({
        events: id,
        listType,
        lang: domainLang,
      });
    }
  }, [id, isEventEnded]);

  const eventDetailStage = getDetailEventStage(event, eventDetailState);
  if (eventDetailStage === EventDetailStage.LOADING) {
    return <DualRingLoader />;
  }

  if (eventDetailStage === EventDetailStage.NOT_FOUND) {
    return (
      <S.NotificationWrapper>
        <GenericNotification onClose={onCloseNotificationHandler}>
          {t('live.no_events_title')}
        </GenericNotification>
      </S.NotificationWrapper>
    );
  }

  const path = getPageURL(PageName.EVENTS_CATEGORY, {
    categoryId: category?.id || event?.category_id || '',
    filter: 'all',
  });

  const getPageHeaderTitle = (): string => {
    if (isEventEnded) {
      return (event as IEndedEvent).detail;
    }
    return category?.label;
  };

  return (
    <div ref={detailWrapperRef}>
      {!isDesktopView && (
        <PageHeader
          title={getPageHeaderTitle()}
          path={path}
          isBackButton={!isEventDetailPopup}
          isCrossButton={!!isEventDetailPopup}
          isFavorites
        />
      )}
      <DetailEvent listType={listType} eventId={id} />
    </div>
  );
};

export default Detail;
