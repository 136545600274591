import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import keys from 'lodash/keys';
import split from 'lodash/split';
import trim from 'lodash/trim';
import replace from 'lodash/replace';
import parse from 'date-fns/parse';

import services from '@features/core/services';
import { COMPLETE_ISO_DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';

import { ODDS_COMPARISON_PROVIDERS } from '@common/constants/config';
import { IOddsComparisonData, IRestoreSelection } from '@common/interfaces';
import { validateSelections } from '@common/providers/bettingslip/helper';
import { setBettingSlipData } from '@common/providers/bettingslip/useBettingSlip';
import { IBettingslip } from '@common/interfaces/bettingslip/IBettingslip';
import { useOddsComparison } from '@common/providers/oddsComparison/useOddsComparison';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { getDateLabel } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import convertToTimeZone from '@common/helpers/timeHelper/convertToTimeZone';

const availableOdds = services.config.get(ODDS_COMPARISON_PROVIDERS) as string;

export const setSelectionToBS = (
  sectionEvents: IOddsComparisonData[],
): void => {
  const restoredSelections = map(sectionEvents, ({ pid, mid, event_id }) => {
    return { event_id, market_id: mid, id: pid };
  }) as IRestoreSelection[];
  const { totalStake } = useOddsComparison.getState();

  const parsedSlip: {
    totalStake: number;
    type: 'combi';
  } = { totalStake, type: 'combi' };

  if (restoredSelections.length) {
    setBettingSlipData({
      ...parsedSlip,
      restoredSelections,
    } as Partial<IBettingslip>);

    setTimeout(() => validateSelections('restore'));
  }
};

export const getCompletedOddsProviders = (): string[] => [
  'swde',
  ...map(split(availableOdds, ','), trim),
];

export const validateOddsComparisonData = (
  section: IOddsComparisonData[],
): IOddsComparisonData[] =>
  map(section, item => {
    const result = {} as IOddsComparisonData;
    forEach(keys(item), key => {
      result[toLower(key)] = item[key];
    });
    return result;
  });

export const getWinChance = (section: IOddsComparisonData[]): string[] => {
  const { totalStake } = useOddsComparison.getState();
  const completedAvailableOdds = getCompletedOddsProviders();
  return reduce(
    map(completedAvailableOdds),
    (acc, oddsProvider) => {
      let winChance = totalStake;
      forEach(section, item => {
        winChance *= item[oddsProvider] || 0;
      });
      acc.push(`${String(replace(winChance.toFixed(2), '.', ','))}€`);
      return acc;
    },
    [] as string[],
  );
};

export const getItemLabel = (label: string, favTeamPosition: string): string =>
  split(label, ' - ')[Number(favTeamPosition) - 1];

export const getFormattedItemSubtitle = (
  subtitle: string | undefined,
  dateString: string | undefined,
  isFullSection: boolean,
): string => {
  if (!subtitle) {
    return '';
  }
  if (!dateString || !isFullSection) {
    return subtitle;
  }
  const date = parse(dateString, COMPLETE_ISO_DATE_TEMPLATE, new Date());
  const convertedDateLabel = getDateLabel(date.getTime() / 1000, true);
  const convertedDate = formatDate(convertToTimeZone(date.getTime()), 'HH:mm');

  return `${convertedDateLabel} ${convertedDate} | ${subtitle}`;
};
