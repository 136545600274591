import React, { useRef } from 'react';

import { Icon } from '@features/core/commonComponents/icon';

import useOnClickOutside from '@common/hooks/useOnClickOutside';

import * as S from './InfoTooltip.styled';
import IInfoTooltip from './InfoTooltip.types';

const InfoTooltip: React.FC<IInfoTooltip> = ({
  title,
  text,
  onClose,
  className,
  withoutClose,
  withoutInfoCircle,
  closeOnClickOutside = true,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    if (closeOnClickOutside) {
      onClose();
    }
  });

  return (
    <S.Container className={className || ''} ref={containerRef}>
      <S.ContentContainer>
        <S.TitleWrapper className="title-wrap">
          {!withoutInfoCircle && (
            <S.IconWrapper className="icon-wrap">
              <Icon name="info-circle" fill="#004560" height="20" width="20" />
            </S.IconWrapper>
          )}
          <div className="content-wrap">
            {title && <S.Title className="text">{title}</S.Title>}
            <S.ContentMessage
              dangerouslySetInnerHTML={{ __html: text as string }}
              className="tooltip-content-message"
            />
          </div>
        </S.TitleWrapper>
        {!withoutClose && (
          <Icon
            onClick={onClose}
            name="close"
            width="10px"
            height="10px"
            className="close-icon"
          />
        )}
      </S.ContentContainer>
    </S.Container>
  );
};

export default InfoTooltip;
