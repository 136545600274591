import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import BetDetail from '@pages/betDetail/BetDetail';
import Cashout from '@pages/cashout/Cashout';

import { Empty } from '@features/app/layout/tabSide/empty';
import { BettingslipWrapper } from '@features/bettingslip/components/bettingslipWrapper';
import { SelfLock } from '@features/user/components/selfLock';
import services from '@features/core/services';

import { HAS_OASIS } from '@common/constants/config';
import { ISelection, IBetDetail } from '@common/interfaces';
import { getSelections } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { useUserState } from '@common/providers/user/useUserState';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { setSelectedBetId, useBets } from '@common/providers/bets/useBets';
import usePrevious from '@common/hooks/usePrevious';

import * as S from './TabSide.styled';
import { IStateTab } from './TabSide.types';

const CASHOUT_TYPE = 'cashout_type';
const BETINGSLIP_TYPE = 'bettingslip_type';

const TabSide: React.FC = (): React.ReactElement => {
  const openBets = useBets(s => s.openBets);
  const selectedBetId = useBets(s => s.selectedBetId);
  const isLoggedIn = useUserState(state => state.isLoggedIn);
  const { t } = useTranslation();
  const slipSelections = useBettingSlip(state => state.selections);
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const user = useUserState(state => state.data);
  const isCashout = openBets.length ? <Cashout /> : null;
  const BettingSlipWrap = <BettingslipWrapper />;
  const currentSelections = getSelections(slipSelections, betPackerSelections);
  const prevSelectionsLength = usePrevious(currentSelections?.length || 0);
  const [active, setActive] = useState(BETINGSLIP_TYPE);
  const getTabsData = (
    isLogged: boolean,
    bets: string | IBetDetail[],
    selections: string | ISelection[],
    activeTab: string,
  ): IStateTab[] => {
    return [
      {
        name: t('footer.cashout'),
        type: CASHOUT_TYPE,
        component: isCashout,
        active: activeTab === CASHOUT_TYPE,
        availableForRender: isLogged,
        isEmpty: bets.length || 0,
      },
      {
        name: t('footer.betting-slip'),
        type: BETINGSLIP_TYPE,
        component: BettingSlipWrap,
        active: activeTab === BETINGSLIP_TYPE,
        availableForRender: true,
        isEmpty: selections?.length,
      },
    ];
  };

  useEffect(() => {
    return () => {
      setSelectedBetId('');
    };
  }, []);

  // cashout widget
  useEffect(() => {
    if (openBets.length > 0 && currentSelections?.length === 0) {
      setActive(CASHOUT_TYPE);
    }
  }, [isLoggedIn, openBets]);

  // bettinslip widget
  useEffect(() => {
    if (
      (currentSelections?.length === 0 || prevSelectionsLength === undefined) &&
      openBets.length
    ) {
      setActive(CASHOUT_TYPE);
    }
    if (
      currentSelections?.length !== prevSelectionsLength &&
      currentSelections?.length !== 0
    ) {
      setActive(BETINGSLIP_TYPE);
    }
  }, [slipSelections, prevSelectionsLength, betPackerSelections]);

  const toggleTab = (idxKey: string): void => {
    setActive(idxKey);
  };

  return (
    <>
      {selectedBetId ? (
        <S.BetDetailWrapper id="bet-detail">
          <BetDetail id={selectedBetId} />
        </S.BetDetailWrapper>
      ) : (
        <S.Tab>
          <S.TabItemList>
            {map(
              getTabsData(isLoggedIn, openBets, currentSelections, active),
              item => (
                <S.TabItem
                  key={item.name}
                  active={item.active}
                  disabled={!item.availableForRender}
                >
                  <S.TabHeader
                    onClick={(): void => toggleTab(item.type)}
                    disabled={!item.availableForRender}
                    active={item.active}
                  >
                    {item.name}
                    {item.isEmpty ||
                    (!isLoggedIn && item.isEmpty) ||
                    (isLoggedIn && item.name === 'Cashout')
                      ? ` (${item.isEmpty})`
                      : ''}
                  </S.TabHeader>
                </S.TabItem>
              ),
            ).reverse()}
          </S.TabItemList>
          {map(
            getTabsData(isLoggedIn, openBets, currentSelections, active),
            (item, idx) =>
              item.availableForRender &&
              item.active && (
                <S.TabContent key={idx}>
                  {!item.isEmpty ? (
                    <>
                      {isLoggedIn &&
                        user?.countryCode === 'DE' &&
                        services.config.get(HAS_OASIS) && <SelfLock />}
                      <Empty type={item.type} />
                    </>
                  ) : null}
                  {item.component || null}
                </S.TabContent>
              ),
          )}
        </S.Tab>
      )}
    </>
  );
};

export { TabSide as Component };

export default TabSide;
