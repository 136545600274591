import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { TextNormal } from '@features/core/styles/text.styled';

export const UserLetter = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: solid 1px ${props => props.theme.accent};
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.inputDefaultText};
  border-radius: 50px;
`;

const logoLoggedInStyles = props => `
  margin: 0 0 ${props.theme.space_10} 0;
  width: 147px;
  height: 26px;
`;

export const LogoContainer = styled(CustomLink)<{ loggedIn?: boolean }>`
  margin-bottom: ${({ theme }) => theme.space_15};
  width: 184px;
  height: 24px;

  > svg {
    width: 170px;
    height: 40px;
  }
  ${({ loggedIn }) => loggedIn && logoLoggedInStyles};

  @media screen and (max-width: 300px) {
    width: 170px;
  }
`;

export const LoginBtn = styled.button`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 60px;
  border-radius: 0 0 0 35px;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.main};

  & svg {
    padding-left: ${({ theme }) => theme.space_15};
    top: -2px;
    position: relative;
    left: -2px;
  }
`;

export const AgeBox = styled(TextNormal)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  padding: ${({ theme }) => theme.space_5};
  border-radius: 50%;
  border: solid 2px ${({ theme }) => theme.textMain};
  background-color: ${({ theme }) => theme.highlightsHeaderBackground};
  color: ${({ theme }) => theme.textMain};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const LoginInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: ${({ theme }) => theme.space_10};
`;
