import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useServices } from '@features/core/services';
import { Icon } from '@features/core/commonComponents/icon';
import { TextNormal } from '@features/core/styles/text.styled';
import { StyledButton } from '@features/core/commonComponents/buttons';
import { PageName } from '@features/core/routing/linkAliases';

import { EMAIL_NOTICE, RESEND_SUCCESS } from '@common/constants/notifications';
import { RESEND_CONFIRMATION } from '@common/constants/cookie';
import { ButtonType, TTimeout } from '@common/interfaces';
import deleteTimeout from '@common/helpers/timeoutHelper';
import { createExpiresStamp, isDesktopView } from '@common/helpers/deviceUtil';
import { isAccountPages } from '@common/helpers/links';
import {
  addNotification,
  removeNotification,
} from '@common/providers/application/useAppState';
import { resendEmailConfirmation } from '@common/providers/account/helper';
import { isMatchWebRoutes } from '@common/providers/router/helper';

import * as S from '../TopNotification.styled';

const isDesktop = isDesktopView();

const EmailNotice: React.FC = (): React.ReactElement => {
  const timeoutMessageRef = useRef<TTimeout>(null);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { cookie } = useServices();
  const isMyAccount = isAccountPages(pathname);
  const isRegisterSuccess =
    isMatchWebRoutes(PageName.REGISTRATION_SUCCESS) ||
    isMatchWebRoutes(PageName.USER_EMAIL_VERIFICATION_SUCCESS);
  const hasRightCol = isMyAccount && isDesktop;

  useEffect(() => {
    return () => deleteTimeout(timeoutMessageRef.current);
  }, []);

  const onEmailNotice = (): void => {
    removeNotification(EMAIL_NOTICE);
    resendEmailConfirmation();

    cookie.set(RESEND_CONFIRMATION, RESEND_CONFIRMATION, {
      expires: createExpiresStamp(60 * 60 * 24 * 365),
    });

    addNotification({ type: RESEND_SUCCESS });
    timeoutMessageRef.current = setTimeout(
      removeNotification,
      10000,
      RESEND_SUCCESS,
    );
  };

  if (isRegisterSuccess) {
    return <></>;
  }

  return (
    <S.NotificationWrapper hasRightCol={hasRightCol} id="verification">
      <S.NotificationHeader>
        <Icon width="20" height="20" name="info-circle" />
        <S.HighlightBoldTitle className="title">
          {t('user.notifications.verification_not_passed_header')}
        </S.HighlightBoldTitle>
      </S.NotificationHeader>
      <S.GridWrapper>
        <TextNormal>{t('user.notifications.emailConfirm')}</TextNormal>
        <StyledButton
          onClick={(): void => onEmailNotice()}
          dataQa="btn-resend-confirmation"
          className="emailBtn"
          buttonType={ButtonType.Blue}
          label="user.notifications.resendConfirmation"
        />
      </S.GridWrapper>
    </S.NotificationWrapper>
  );
};

export default EmailNotice;
