import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';

import * as S from './CollabsibleContainer.styled';
import ICollabsibleContainerProps from './CollabsibleContainer.types';

const CollabsibleContainer: React.FC<ICollabsibleContainerProps> = props => {
  const {
    title,
    hasButton,
    handleClick,
    children,
    titleClassName,
    className,
  } = props;
  const { t } = useTranslation();

  const [extended, setExtended] = useState<boolean>(true);
  return (
    <S.Root className={className}>
      <S.Title className={titleClassName}>
        <span>{title}</span>
        {hasButton && (
          <S.AllEventsLink onClick={(): void => handleClick?.()}>
            {t('common.labels.all_games')}
          </S.AllEventsLink>
        )}
        <S.ChevronWrapper onClick={(): void => setExtended(!extended)}>
          <Icon
            name={extended ? 'chevron-up' : 'chevron-down'}
            width="12"
            height="12"
          />
        </S.ChevronWrapper>
      </S.Title>

      {extended && children}
    </S.Root>
  );
};

export default CollabsibleContainer;
