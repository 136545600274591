import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CountdownDoubleWhiteButton from '@features/bettingslip/components/buttons/CountdownDoubleWhiteButton';
import { ButtonSize } from '@features/core/commonComponents/buttons/Button.styled';

import WithCountDown from '@common/hocs/WithCountDown';
import { formatCashoutOffer } from '@common/helpers/cashoutHelper';

import useCashoutItemModel from '../../../useCashoutItemModel/useCashoutItemModel';

import { IQueuedProps } from './Queued.types';

const Queued: React.FC<IQueuedProps> = ({
  betId,
  startCountDown,
  countdown,
  closeSubmit,
  timerCallBack,
}) => {
  const {
    cashoutOffer,
    requestedCashout,
    cashoutDisabled,
  } = useCashoutItemModel(betId);

  useEffect(() => {
    startCountDown(
      () => timerCallBack(requestedCashout),
      requestedCashout.queue_delay as number,
    );
  }, []);

  const { t } = useTranslation();

  return (
    <CountdownDoubleWhiteButton
      label={formatCashoutOffer(cashoutOffer, cashoutDisabled)}
      isWhite
      counterOnClick={closeSubmit}
      countdown={countdown}
      aria-label={t('common.buttons.close')}
      size={ButtonSize.Small}
      disabled
    />
  );
};

export default WithCountDown(Queued, {});
