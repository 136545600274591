import React from 'react';

import FormLayout from '@features/registration/components/FormLayout.styled';
import { Icon } from '@features/core/commonComponents/icon';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { ButtonSize } from '@common/interfaces';

import * as S from './RegistrationStepSubmitWrapper.styled';
import { IRegistrationStepSubmitWrapper } from './RegistrationStepSubmitWrapper.types';

const RegistrationStepSubmitWrapper = ({
  isInfo,
  infoText,
  onSubmit,
  submitText,
  disabled,
  dataQa,
  isLoader,
  tabIndex,
}: IRegistrationStepSubmitWrapper): JSX.Element => {
  return (
    <S.FooterWrapper>
      {isInfo && (
        <S.InfoSection>
          <S.InfoIcon>
            <Icon name="info-circle" width="18" height="18" />
          </S.InfoIcon>

          <S.InfoSectionText>{infoText}</S.InfoSectionText>
        </S.InfoSection>
      )}

      <FormLayout.SubmitRow>
        <StyledButton
          dataQa={dataQa}
          disabled={!!disabled}
          onClick={onSubmit}
          showLoader={!!isLoader}
          label={submitText}
          size={ButtonSize.Big}
          tabIndex={tabIndex}
        />
      </FormLayout.SubmitRow>
    </S.FooterWrapper>
  );
};

export default RegistrationStepSubmitWrapper;
