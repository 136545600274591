import React from 'react';
import filter from 'lodash/filter';
import compact from 'lodash/compact';
import map from 'lodash/map';
import loadable from '@loadable/component';

import { ILANG, Module } from '@features/app/modules/IModule.types';
import { ROUTERS_MAPPER } from '@features/core/routing/routerMapper';
import { DualRingLoader } from '@features/core/commonComponents/dualRingLoader';
import services from '@features/core/services';
import { PageName } from '@features/core/routing';

import {
  ALLOWED_LANGUAGES,
  DEFAULT_LANGUAGE,
  HAS_BONUS_CODES,
  HAS_BONUS_WALLETS,
  HAS_CASINO,
  HAS_CREATE_ONLINE_ACCOUNT,
  HAS_DEPOSITS,
  HAS_FULL_REGISTRATION,
  HAS_GDPR,
  HAS_HOME_PAGE,
  HAS_INBOX,
  HAS_LIMITS,
  HAS_LMT_PAGE,
  HAS_MANUAL_BARCODE,
  HAS_ODDS_COMPARISON,
  HAS_STATIC_PAGES,
  HAS_TICKET_SCANNER,
  HAS_UPLOADS,
  HAS_WITHDRAWALS,
  HAS_YEARLY_DATA_CHECK,
  IS_EMBEDDED_SOLUTION,
  HAS_MY_FAVORITO,
} from '@common/constants/config';
import { isDesktopView } from '@common/helpers/deviceUtil';

// eslint-disable-next-line
const blueTheme = require(`@features/core/theming/colorSchema-Sportwetten-blue`);

const createPathList = (language: ILANG): string[] => {
  return map(Object.entries(language), item => item[1] as string);
};

const LoadableHome = loadable(() => import('@pages/homePage/HomePage'), {
  fallback: <DualRingLoader />,
});

const LoadableCasino = loadable(() => import('@pages/casino/Casino'), {
  fallback: <DualRingLoader />,
});

const CategoriesPanelLoadable = loadable(
  () => import('@pages/categoriesPanel/CategoriesPanel'),
  {
    fallback: <DualRingLoader />,
  },
);

const CategoryLoadable = loadable(() => import('@pages/category/Category'), {
  fallback: <DualRingLoader />,
});

const LiveLoadable = loadable(() => import('@pages/live/Live'), {
  fallback: <DualRingLoader />,
});

const DetailLoadable = loadable(() => import('@pages/detail/DetailPage'), {
  fallback: <DualRingLoader />,
});

const PaymentLoadable = loadable(() => import('@pages/payment/PaymentPage'), {
  fallback: <DualRingLoader isLight />,
});

const DepositSuccessLoadable = loadable(
  () => import('@pages/payment/DepositSuccess'),
  { fallback: <DualRingLoader isLight /> },
);

const TicketScannerLoadable = loadable(
  () => import('@pages/cashoutScanner/CashoutScanner'),
  { fallback: <DualRingLoader isLight /> },
);

const ManualBarcodeEntryLoadable = loadable(
  () => import('@pages/manualBarcodeEntry/ManualBarcodeEntry'),
  { fallback: <DualRingLoader isLight /> },
);

const DepositFailLoadable = loadable(
  () => import('@pages/payment/DepositFail'),
  { fallback: <DualRingLoader isLight /> },
);

const DepositPreparedLoadable = loadable(
  () => import('@pages/payment/DepositPrepared'),
  { fallback: <DualRingLoader isLight /> },
);

const DepositInfoLoadable = loadable(
  () => import('@pages/payment/depositInfo/DepositInfo'),
  { fallback: <DualRingLoader isLight /> },
);

const DepositCCLoadable = loadable(
  () => import('@pages/payment/depositCC/DepositCC'),
  { fallback: <DualRingLoader isLight /> },
);

const QRcodePage = loadable(
  () => import('@pages/payment/qrCodePage/QRcodePage'),
  { fallback: <DualRingLoader isLight /> },
);

const WithdrawalSuccessLoadable = loadable(
  () => import('@pages/payment/WithdrawalSuccess'),
  { fallback: <DualRingLoader isLight /> },
);

const WithdrawalFailLoadable = loadable(
  () => import('@pages/payment/WithdrawalFail'),
  { fallback: <DualRingLoader isLight /> },
);

const WithdrawalPreparedLoadable = loadable(
  () => import('@pages/payment/WithdrawalPrepared'),
  { fallback: <DualRingLoader isLight /> },
);

const WithdrawalBankLoadable = loadable(
  () => import('@pages/payment/withdrawalBank/WithdrawalBank'),
  { fallback: <DualRingLoader isLight /> },
);

const LimitsLoadable = loadable(() => import('@pages/limits/Limits'), {
  fallback: <DualRingLoader isLight />,
});

const LimitsLockLoadable = loadable(
  () => import('@pages/accountLockTab/AccountLockTab'),
  { fallback: <DualRingLoader isLight /> },
);

const BetsLoadable = loadable(() => import('@pages/bets/Bets'), {
  fallback: <DualRingLoader isLight />,
});

const BetDetailLoadable = loadable(() => import('@pages/betDetail/BetDetail'), {
  fallback: <DualRingLoader isLight />,
});

const GdprLoadable = loadable(() => import('@pages/gdpr/Gdpr'), {
  fallback: <DualRingLoader backgroundColor={blueTheme.main} />,
});

const LMTLoadable = loadable(() => import('@pages/lmtPage/LMTPage'), {
  fallback: <DualRingLoader backgroundColor={blueTheme.main} />,
});

const UserBonusesLoadable = loadable(() => import('@pages/bonuses/Bonuses'), {
  fallback: <DualRingLoader />,
});

const RegistrationLoadable = loadable(
  () => import('@pages/registration/Registration'),
  { fallback: <DualRingLoader /> },
);

const FullRegistrationLoadable = loadable(
  () => import('@pages/registration/FullRegistration'),
  { fallback: <DualRingLoader /> },
);

const CreateOnlineWalletLoadable = loadable(
  () => import('@pages/onlineAccount/createOnlineWallet/CreateOnlineWallet'),
  { fallback: <DualRingLoader /> },
);

const UpdateOnlineAccountLoadable = loadable(
  () => import('@pages/onlineAccount/updateOnlineAccount/UpdateOnlineAccount'),
  { fallback: <DualRingLoader /> },
);

const YearlyDataCheckLoadable = loadable(
  () => import('@pages/yearlyDataCheck/YearlyDataCheck'),
  { fallback: <DualRingLoader /> },
);

const ChangePasswordLoadable = loadable(
  () => import('@pages/passwordRecovery/PasswordRecovery'),
  { fallback: <DualRingLoader isLight /> },
);

const ForgotPasswordLoadable = loadable(
  () => import('@pages/forgotPassword/ForgotPassword'),
  { fallback: <DualRingLoader /> },
);

const TransactionsLoadable = loadable(
  () => import('@pages/trasnsactions/Transactions'),
  { fallback: <DualRingLoader isLight /> },
);

const ProfileLoadable = loadable(() => import('@pages/profile/Profile'), {
  fallback: <DualRingLoader />,
});

const UserNotificationsLoadable = loadable(
  () => import('@pages/userNotifications/UserNotifications'),
  { fallback: <DualRingLoader isLight /> },
);

const RegistrationBonusLoadable = loadable(
  () => import('@pages/registrationBonus/RegistrationBonus'),
  {
    fallback: <DualRingLoader />,
  },
);

const UploadsLoadable = loadable(() => import('@pages/uploads/Uploads'), {
  fallback: <DualRingLoader />,
});

const NewsLoadable = loadable(() => import('@pages/news/News'), {
  fallback: <DualRingLoader isLight />,
});

const PressLoadable = loadable(() => import('@pages/press/Press'), {
  fallback: <DualRingLoader isLight />,
});

const NewsDetailLoadable = loadable(
  () => import('@pages/newsDetail/NewsDetail'),
  {
    fallback: <DualRingLoader isLight />,
  },
);

const LoginLoadable = loadable(() => import('@pages/login/Login'), {
  fallback: <DualRingLoader />,
});

const ThemeLoadable = loadable(() => import('@pages/themePage/ThemePage'), {
  fallback: <DualRingLoader />,
});

const TestPageLoadable = loadable(() => import('@pages/testPage/TestPage'), {
  fallback: <DualRingLoader />,
});

const StaticLoadable = loadable(() => import('@pages/staticPage/StaticPage'), {
  fallback: <DualRingLoader backgroundColor="inherit" />,
});

const SupportLoadable = loadable(() => import('@pages/support/Support'), {
  fallback: <DualRingLoader />,
});

const NotFoundLoadable = loadable(() => import('@pages/notFound/NotFound'), {
  fallback: <DualRingLoader isLight />,
});

const BettingSlipLoadable = loadable(
  () => import('@pages/bettingslip/BettingSlip'),
  {
    fallback: <DualRingLoader isLight />,
  },
);

const MigrationLoadable = loadable(
  () => import('@pages/migrationPage/MigrationPage'),
  { fallback: <DualRingLoader /> },
);

const DeleteAccountLoadable = loadable(
  () => import('@pages/deleteAccount/DeleteAccount'),
  { fallback: <DualRingLoader /> },
);
const InboxLoadable = loadable(() => import('@pages/inbox/Inbox'), {
  fallback: <DualRingLoader />,
});
const InboxMessageLoadable = loadable(
  () => import('@pages/inboxMessage/InboxMessage'),
  { fallback: <DualRingLoader /> },
);

const OddsComparisonLoadable = loadable(
  () => import('@pages/oddsComparison/OddsComparisonPage'),
  { fallback: <DualRingLoader /> },
);

const MyFavoritoLoadable = loadable(
  () => import('@pages/myFavorito/MyFavorito'),
  { fallback: <DualRingLoader /> },
);

const modules: Module[] = [];

const lang = `/:lang(${filter(
  services.config.get(ALLOWED_LANGUAGES) as Array<string>,
  e => e !== services.config.get(DEFAULT_LANGUAGE),
).join('|')})?`;

const registrationModule = {
  routes: {
    [PageName.REGISTER]: {
      path: createPathList(ROUTERS_MAPPER.REGISTER),
      component: RegistrationLoadable,
      exact: true,
    },
    // RegistrationSuccess: {
    //   path: createPathList(ROUTERS_MAPPER.REGISTER_SUCCESS),
    //   component: RegistrationSuccessLoadable,
    //   exact: true,
    // },
  },
};

const fullRegistrationModule = {
  routes: {
    [PageName.FULL_REGISTER]: {
      path: createPathList(ROUTERS_MAPPER.FULL_REGISTER),
      component: FullRegistrationLoadable,
      exact: true,
    },
  },
};

const yearlyDataCheckModule = {
  routes: {
    [PageName.YEARLY_DATA_CHECK]: {
      path: createPathList(ROUTERS_MAPPER.YEARLY_DATA_CHECK),
      component: YearlyDataCheckLoadable,
      exact: true,
    },
  },
};

const createOnlineAccountModule = {
  routes: {
    [PageName.CREATE_ONLINE_WALLET]: {
      path: createPathList(ROUTERS_MAPPER.CREATE_ONLINE_WALLET),
      component: CreateOnlineWalletLoadable,
      exact: true,
    },
    [PageName.UPDATE_ONLINE_ACCOUNT]: {
      path: createPathList(ROUTERS_MAPPER.UPDATE_ONLINE_ACCOUNT),
      component: UpdateOnlineAccountLoadable,
      exact: true,
    },
  },
};

const homePageModule = {
  routes: {
    [PageName.HOME]: {
      path: compact([
        services.domainLang !== services.config.get(DEFAULT_LANGUAGE) &&
          `${lang}/home`,
        services.domainLang !== services.config.get(DEFAULT_LANGUAGE) && lang,
        '/',
      ]),
      component: LoadableHome,
      exact: true,
    },
  },
};

const casinoModule = {
  routes: {
    [PageName.CASINO]: {
      path: createPathList(ROUTERS_MAPPER.CASINO),
      component: LoadableCasino,
      exact: true,
    },
  },
};

const detailModule = {
  routes: {
    [PageName.EVENTS_ITEM]: {
      path: createPathList(ROUTERS_MAPPER.EVENTS_ITEM),
      component: DetailLoadable,
      exact: true,
    },
  },
};

const categoryModule = {
  routes: {
    [PageName.EVENTS_CATEGORY]: {
      path: createPathList(ROUTERS_MAPPER.EVENTS_CATEGORY),
      component: CategoryLoadable,
      exact: true,
    },
  },
};

const liveModule = {
  routes: {
    [PageName.LIVE]: {
      path: createPathList(ROUTERS_MAPPER.LIVE),
      component: LiveLoadable,
      exact: true,
    },
  },
};

const depositsModule = {
  routes: {
    [PageName.USER_PAYIN_SUCCESS]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYIN_SUCCESS),
      component: DepositSuccessLoadable,
      exact: true,
    },
    [PageName.USER_PAYIN_FAILURE]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYIN_FAILURE),
      component: DepositFailLoadable,
      exact: true,
    },
    [PageName.USER_PAYIN_PREPARED]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYIN_PREPARED),
      component: DepositPreparedLoadable,
      exact: true,
    },
    [PageName.USER_PAYIN_INFO_METHOD]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYIN_INFO_METHOD),
      component: DepositInfoLoadable,
      exact: true,
    },
    [PageName.USER_PAYIN_CARD_AMOUNT]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYIN_CARD_AMOUNT),
      component: DepositCCLoadable,
      exact: true,
    },
    [PageName.USER_PAYIN_QR]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYIN_QR),
      component: QRcodePage,
      exact: true,
    },
  },
};

const paymentModule = {
  routes: {
    [PageName.USER_PAYMENTS]: {
      path: [
        ...createPathList(ROUTERS_MAPPER.USER_PAYOUT),
        ...createPathList(ROUTERS_MAPPER.USER_PAYIN),
      ], // /user/(payin|payout)`,
      component: PaymentLoadable,
      exact: true,
    },
  },
};

const withdrawalsModule = {
  routes: {
    [PageName.USER_PAYOUT_SUCCESS]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYOUT_SUCCESS),
      component: WithdrawalSuccessLoadable,
      exact: true,
    },
    [PageName.USER_PAYOUT_FAILURE]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYOUT_FAILURE),
      component: WithdrawalFailLoadable,
      exact: true,
    },
    [PageName.USER_PAYOUT_PREPARED]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYOUT_PREPARED),
      component: WithdrawalPreparedLoadable,
      exact: true,
    },
    [PageName.USER_PAYOUT_BANK_AMOUNT]: {
      path: createPathList(ROUTERS_MAPPER.USER_PAYOUT_BANK_AMOUNT),
      component: WithdrawalBankLoadable,
      exact: true,
    },
  },
};

const overviewModule = {
  routes: {
    [PageName.USER_OVERVIEW]: {
      path: createPathList(ROUTERS_MAPPER.USER_OVERVIEW),
      component: TransactionsLoadable,
      exact: true,
    },
  },
};

const ticketScannerModule = {
  routes: {
    [PageName.TICKET_SCANNER]: {
      path: createPathList(ROUTERS_MAPPER.TICKET_SCANNER),
      component: TicketScannerLoadable,
      exact: true,
    },
  },
};

const scanResultModule = {
  routes: {
    [PageName.SCANNER_RESULT]: {
      path: createPathList(ROUTERS_MAPPER.SCANNER_RESULT),
      component: BetDetailLoadable,
      exact: true,
    },
  },
};

const cashoutScanResultModule = {
  routes: {
    [PageName.CASHOUT_SCANNER_RESULT]: {
      path: createPathList(ROUTERS_MAPPER.CASHOUT_SCANNER_RESULT),
      component: BetDetailLoadable,
      exact: true,
    },
  },
};

const manualBarcodeEntryModule = {
  routes: {
    [PageName.MANUAL_BARCODE_ENTRY]: {
      path: createPathList(ROUTERS_MAPPER.MANUAL_BARCODE_ENTRY),
      component: ManualBarcodeEntryLoadable,
      exact: true,
    },
  },
};

const limitsModule = {
  routes: {
    [PageName.USER_LIMITS]: {
      path: [
        ...createPathList(ROUTERS_MAPPER.USER_LIMITS),
        ...createPathList(ROUTERS_MAPPER.USER_LIMITS_HISTORY),
      ],
      component: LimitsLoadable,
      exact: true,
    },
  },
};

const limitsLockModule = {
  routes: {
    [PageName.USER_LIMITS_LOCK]: {
      path: createPathList(ROUTERS_MAPPER.USER_LIMITS_LOCK),
      component: LimitsLockLoadable,
      exact: true,
    },
  },
};

const myBets = {
  routes: {
    [PageName.USER_BETS]: {
      path: [
        ...createPathList(ROUTERS_MAPPER.USER_BETS),
        ...createPathList(ROUTERS_MAPPER.USER_CASHOUT),
        ...createPathList(ROUTERS_MAPPER.USER_CASHOUT_ITEM),
      ],
      component: BetsLoadable,
      exact: true,
    },
    [PageName.USER_BET_ITEM]: {
      path: createPathList(ROUTERS_MAPPER.USER_BET_ITEM),
      component: BetDetailLoadable,
      exact: true,
    },
  },
};

const changePassword = {
  routes: {
    [PageName.USER_CHANGE_PASSWORD]: {
      path: createPathList(ROUTERS_MAPPER.USER_CHANGE_PASSWORD),
      component: ChangePasswordLoadable,
      exact: true,
    },
  },
};

const deleteAccount = {
  routes: {
    [PageName.USER_DELETE_ACCOUNT]: {
      path: createPathList(ROUTERS_MAPPER.USER_DELETE_ACCOUNT),
      component: DeleteAccountLoadable,
      exact: true,
    },
  },
};

const gdprModule = {
  routes: {
    [PageName.USER_GDPR]: {
      path: createPathList(ROUTERS_MAPPER.USER_GDPR),
      component: GdprLoadable,
      exact: true,
    },
  },
};

const uploadsModule = {
  routes: {
    [PageName.USER_UPLOADS]: {
      path: createPathList(ROUTERS_MAPPER.USER_UPLOADS),
      component: UploadsLoadable,
      exact: true,
    },
  },
};

const forgotPassword = {
  routes: {
    [PageName.FORGOT_PASSWORD]: {
      path: createPathList(ROUTERS_MAPPER.FORGOT_PASSWORD),
      component: ForgotPasswordLoadable,
      exact: true,
    },
  },
};

const profileModule = {
  routes: {
    [PageName.USER_PROFILE]: {
      path: createPathList(ROUTERS_MAPPER.USER_PROFILE),
      component: ProfileLoadable,
      exact: true,
    },
  },
};

const notificationsModule = {
  routes: {
    [PageName.USER_NOTIFICATIONS]: {
      path: createPathList(ROUTERS_MAPPER.USER_NOTIFICATIONS),
      component: UserNotificationsLoadable,
      exact: true,
    },
  },
};

const userBonusesModule = {
  routes: {
    [PageName.USER_BONUSES]: {
      path: createPathList(ROUTERS_MAPPER.USER_BONUSES),
      component: UserBonusesLoadable,
      exact: true,
    },
  },
};

const newsModule = {
  routes: {
    [PageName.NEWS_CATEGORY]: {
      path: createPathList(ROUTERS_MAPPER.NEWS_ITEM_CATEGORY),
      component: NewsLoadable,
      exact: true,
    },
    [PageName.NEWS_ITEM]: {
      path: createPathList(ROUTERS_MAPPER.NEWS_CATEGORY_DETAIL),
      component: NewsDetailLoadable,
      exact: true,
    },
  },
};

const lmtModule = {
  routes: {
    [PageName.EVENTS_ITEM_LMT]: {
      path: createPathList(ROUTERS_MAPPER.EVENTS_ITEM_LMT),
      component: LMTLoadable,
      exact: true,
    },
  },
};

const pressModule = {
  routes: {
    [PageName.PRESSE]: {
      path: createPathList(ROUTERS_MAPPER.UNTERNEHMEN_PRESSE),
      component: PressLoadable,
      exact: true,
    },
    [PageName.UNTERNEHMEN_PRESSE_DETAIL]: {
      path: createPathList(ROUTERS_MAPPER.UNTERNEHMEN_PRESSE_DETAIL),
      component: NewsDetailLoadable,
      exact: true,
    },
  },
};

const loginModule = {
  routes: {
    [PageName.LOGIN]: {
      path: createPathList(ROUTERS_MAPPER.LOGIN),
      component: LoginLoadable,
      exact: true,
    },
  },
};

const migrationModule = {
  routes: {
    [PageName.MIGRATION]: {
      path: createPathList(ROUTERS_MAPPER.MIGRATION),
      component: MigrationLoadable,
      exact: true,
    },
  },
};

const testModule = {
  routes: {
    [PageName.TEST_PAGE]: {
      path: `${lang}/test/theme`,
      component: ThemeLoadable,
      exact: true,
    },
  },
};

const testPageModule = {
  routes: {
    [PageName.TEST_PAGE]: {
      path: createPathList(ROUTERS_MAPPER.TEST_PAGE),
      component: TestPageLoadable,
      exact: true,
    },
  },
};

const supportModule = {
  routes: {
    [PageName.SUPPORT]: {
      path: createPathList(ROUTERS_MAPPER.SUPPORT),
      component: SupportLoadable,
      exact: true,
    },
  },
};

const bettingSlipModule = {
  routes: {
    [PageName.BETTING_SLIP]: {
      path: createPathList(ROUTERS_MAPPER.BETTING_SLIP),
      component: BettingSlipLoadable,
      exact: true,
    },
  },
};

const staticPageModule = {
  routes: {
    [PageName.STATIC]: {
      path: `${lang}/*`,
      component: StaticLoadable,
      exact: true,
    },
  },
};

const registrationBonusPageModule = {
  routes: {
    [PageName.REGISTRATION_BONUS]: {
      path: createPathList(ROUTERS_MAPPER.REGISTRATION_BONUS),
      component: RegistrationBonusLoadable,
      exact: true,
    },
  },
};

const sportModule = {
  routes: {
    [PageName.SPORT]: {
      path: createPathList(ROUTERS_MAPPER.SPORT),
      component: isDesktopView() ? CategoryLoadable : CategoriesPanelLoadable,
      exact: true,
    },
  },
};

const notFoundModule = {
  routes: {
    [PageName.NOT_FOUND]: {
      path: '*',
      component: NotFoundLoadable,
      exact: true,
    },
  },
};

const inboxModule = {
  routes: {
    [PageName.USER_INBOX]: {
      path: createPathList(ROUTERS_MAPPER.USER_INBOX),
      component: InboxLoadable,
      exact: true,
    },
    [PageName.USER_INBOX_MESSAGE]: {
      path: createPathList(ROUTERS_MAPPER.USER_INBOX_MESSAGE),
      component: InboxMessageLoadable,
      exact: true,
    },
  },
};

const oddsComparisonModule = {
  routes: {
    [PageName.ODDS_COMPARISON]: {
      path: createPathList(ROUTERS_MAPPER.ODDS_COMPARISON),
      component: OddsComparisonLoadable,
      exact: true,
    },
  },
};

const myFavoritoModule = {
  routes: {
    [PageName.MY_FAVORITO]: {
      path: createPathList(ROUTERS_MAPPER.MY_FAVORITO),
      component: MyFavoritoLoadable,
      exact: true,
    },
  },
};

if (services.config.get(HAS_HOME_PAGE)) {
  modules.push(homePageModule);
}

if (services.config.get(HAS_DEPOSITS) && services.config.get(HAS_WITHDRAWALS)) {
  modules.push(paymentModule);
}

if (services.config.get(HAS_DEPOSITS)) {
  modules.push(depositsModule);
}

if (services.config.get(HAS_WITHDRAWALS)) {
  modules.push(withdrawalsModule);
}

if (services.config.get(HAS_LIMITS)) {
  modules.push(limitsModule);
}

if (services.config.get(HAS_CASINO)) {
  modules.push(casinoModule);
}

if (services.config.get(HAS_GDPR)) {
  modules.push(gdprModule);
}

if (
  services.config.get(HAS_BONUS_CODES) ||
  services.config.get(HAS_BONUS_WALLETS)
) {
  modules.push(userBonusesModule);
}

if (services.config.get(HAS_UPLOADS)) {
  modules.push(uploadsModule);
}

if (services.config.get(HAS_LMT_PAGE)) {
  modules.push(lmtModule);
}

if (services.config.get(HAS_FULL_REGISTRATION)) {
  modules.push(fullRegistrationModule);
}

if (services.config.get(HAS_CREATE_ONLINE_ACCOUNT)) {
  modules.push(createOnlineAccountModule);
}

if (services.config.get(HAS_YEARLY_DATA_CHECK)) {
  modules.push(yearlyDataCheckModule);
}

if (services.config.get(HAS_INBOX)) {
  modules.push(inboxModule);
}

if (services.config.get(HAS_TICKET_SCANNER)) {
  modules.push(ticketScannerModule);
}

modules.push(registrationModule);
modules.push(categoryModule);
modules.push(liveModule);
modules.push(detailModule);
modules.push(myBets);
modules.push(sportModule);
modules.push(changePassword);
modules.push(overviewModule);
modules.push(scanResultModule);
modules.push(cashoutScanResultModule);
if (services.config.get(HAS_MANUAL_BARCODE)) {
  modules.push(manualBarcodeEntryModule);
}
modules.push(profileModule);
modules.push(notificationsModule);
modules.push(testModule);
modules.push(bettingSlipModule);
if (services.config.get(HAS_STATIC_PAGES)) {
  modules.push(staticPageModule);
}
if (!services.config.get(IS_EMBEDDED_SOLUTION)) {
  modules.push(newsModule);
  modules.push(pressModule);
  modules.push(loginModule);
  modules.push(migrationModule);
  modules.push(supportModule);
  modules.push(forgotPassword);
  modules.push(deleteAccount);
  modules.push(registrationBonusPageModule);
}
modules.push(notFoundModule);
modules.push(limitsLockModule);
modules.push(testPageModule);

if (services.config.get(HAS_ODDS_COMPARISON)) {
  modules.push(oddsComparisonModule);
}

if (services.config.get(HAS_MY_FAVORITO)) {
  modules.push(myFavoritoModule);
}

export default modules;
