import React from 'react';
import map from 'lodash/map';
import isArray from 'lodash/isArray';

import {
  iconPaths,
  ISIconProps,
} from '@features/core/commonComponents/icon/index';

const SingleIcon: React.FC<ISIconProps> = ({
  name,
  className,
  width,
  height,
  hidden,
  style,
  onClick,
  fill,
  viewBox,
  isMultiplyAttrs,
}) => {
  if (!name) {
    return null;
  }

  const icon = iconPaths[name as string];

  if (!icon) {
    return null;
  }
  const paths = map(icon.paths, (path, i) => {
    return (
      <path
        d={path}
        key={`${name}-${i}`}
        fill={
          (isMultiplyAttrs && icon.dAttrs?.[i]?.fill) ||
          (isArray(fill) ? fill[i] : fill)
        }
        transform={isMultiplyAttrs ? icon.dAttrs?.[i].transform : ''}
      />
    );
  });

  const SVGContainer = (): JSX.Element => {
    return <g transform={icon.g?.transform}>{paths}</g>;
  };

  return (
    <svg
      className={`mi-${name} ${className}`}
      width={width}
      height={height}
      viewBox={viewBox || icon ? icon?.viewBox : '0 0 1024 1024'}
      style={{
        display: hidden ? 'none' : 'inline-block',
        ...style,
      }}
      onClick={onClick}
    >
      {icon.g ? <SVGContainer /> : paths}
    </svg>
  );
};

export default SingleIcon;
