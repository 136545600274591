import React, { useEffect, useRef } from 'react';

import { InfoBlock } from '@features/profile/components/infoBlock';
import { StyledButton } from '@features/core/commonComponents/buttons';

import { toggleContentScroll } from '@common/providers/application/useAppState';
import { ButtonType } from '@common/interfaces';
import useOnClickOutside from '@common/hooks/useOnClickOutside';

import * as S from './LugasDepositInfo.styled';
import { ILugasDepositInfo } from './LugasDepositInfo.types';
import { ContentIconWrapper } from './LugasDepositInfo.styled';

const InfoLimit: React.FC<ILugasDepositInfo> = (props): React.ReactElement => {
  const { onClose, title, text } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, e => {
    e.preventDefault();
    onClose();
  });

  useEffect(() => {
    toggleContentScroll('disable');
    return () => toggleContentScroll('enable');
  }, []);

  return (
    <S.Backdrop className="info-limit" data-qa="info-limit">
      <S.Content ref={containerRef}>
        <ContentIconWrapper>
          <S.ContentIcon
            className="closeIcon"
            onClick={onClose}
            name="close"
            fill="currentColor"
          />
        </ContentIconWrapper>

        <S.Title>{title}</S.Title>

        <InfoBlock message={text} />
        <StyledButton
          onClick={(e): void => {
            e.preventDefault();
            onClose();
          }}
          label="registration.step4.limit.close"
          buttonType={ButtonType.Blue}
        />
      </S.Content>
    </S.Backdrop>
  );
};

export default InfoLimit;
