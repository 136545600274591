import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';

import * as S from './Empty.styled';
import { IEmptyProps } from './Empty.types';

const Empty: React.FC<IEmptyProps> = props => {
  const { type } = props;
  const { t } = useTranslation();

  return (
    <S.WrapperEmpty>
      <Icon name="info-circle" width="24" height="24" />
      <S.ErrorMessage>{t(`common.empty_tip_keys.${type}`)}</S.ErrorMessage>
    </S.WrapperEmpty>
  );
};

export { Empty as Component };

export default Empty;
