import startsWith from 'lodash/startsWith';
import includes from 'lodash/includes';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import split from 'lodash/split';

import history from '@features/app/router/history';
import services from '@features/core/services';
import isExternalLink from '@features/core/routing/isExternalLink';
import {
  getPageURL,
  PageName,
  StaticSlugPageName,
  StaticSlug,
} from '@features/core/routing';

import { DEFAULT_LANGUAGE, DEFAULT_ROUTE } from '@common/constants/config';
import { matchWebRoutes } from '@common/helpers/matchWebRoutes';

import { changeRoute, useRouterState } from './useRouterState';
import { IParams } from './types';

export const appendLanguage = (url: string): string => {
  // ignore if language prefix is included already
  if (startsWith(url, `/${services.domainLang}`)) {
    return url;
  }

  const prefix =
    services.domainLang !== (services.config.get(DEFAULT_LANGUAGE) as string)
      ? `/${services.domainLang}`
      : '';
  return `${prefix}${url}`;
};

export const redirectToSpecificRoute = (url: string): void => {
  if (isExternalLink(url)) {
    window.open(url, '_blank')?.focus();
  } else {
    changeRoute({
      url,
      history,
    });
  }
};

export const getDefaultRouteName = (): PageName => {
  const defaultRoute = services.config.get(DEFAULT_ROUTE) as string;

  const path = matchWebRoutes(defaultRoute, '')?.route;

  return path ? PageName[path] : PageName.HOME;
};

export const setLocation = (
  path: PageName,
  query?: Record<string, string>,
  params?: IParams,
): void => {
  const actualUrl = getPageURL(
    PageName[path === PageName.HOME ? getDefaultRouteName() : path],
    query,
  );
  const { search: searchLocation } = window.location;
  const resultSearch = includes(searchLocation, 'testlist')
    ? searchLocation
    : params?.search;
  changeRoute({
    url: actualUrl,
    history,
    forceReload: params?.forceReload,
    search: resultSearch,
  });
};

export const goBack = (): void => {
  const hasPrevPage = window.history.state !== null;
  if (!hasPrevPage) {
    return setLocation(PageName.HOME);
  }
  return window.history.back();
};

/**
 * isMatchWebRoutes
 *
 * Check if exact current path is equal needed page path
 *
 * @returns {boolean} isMatchWebRoutes
 * @param pageName
 */
export const isMatchWebRoutes = (pageName: PageName | PageName[]): boolean => {
  const { currentWebRoute } = useRouterState.getState();
  if (isArray(pageName)) {
    return some(pageName, name => {
      return currentWebRoute?.route === name;
    });
  }
  return currentWebRoute?.route === pageName;
};

export const isMatchWebStaticRoutes = (
  path: string,
  lang: string,
  staticPageName: StaticSlugPageName,
): boolean => {
  const urlParts = split(path, '?');
  const [routePath, queryParamsString] = urlParts;
  return (
    matchWebRoutes(routePath, queryParamsString)?.params?.slug ===
    StaticSlug[staticPageName][lang]
  );
};
