import React from 'react';

import { Accepted } from '@features/bettingslip/components/states';

import { STATE_ACCEPTED } from '@common/providers/bettingslip/state';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';

const BettingSlipBottomNotification: React.FC = () => {
  const selections = useBettingSlip(state => state.selections);
  const bet_id = useBettingSlip(state => state.bet_id);
  const state = useBettingSlip(s => s.state);

  if (state === STATE_ACCEPTED) {
    return (
      <div
        className="betting-slip frozen"
        id="betting-slip frozen"
        data-qa="betting-slip-frozen"
      >
        <Accepted id={bet_id || ''} selections={selections} />
      </div>
    );
  }
  return null;
};

export { BettingSlipBottomNotification as Component };

export default BettingSlipBottomNotification;
