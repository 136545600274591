import React from 'react';

import { Icon } from '@features/core/commonComponents/icon';
import { useEventsModel } from '@features/events/useEventsModel';

import { getSportName } from '@common/helpers/categories/categoriesModel';
import { getServing } from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';

import * as S from './BallPossession.styled';
import { IBallPossession } from './BallPossession.types';

const ICON_NAMES = {
  darts: 'dartarrow',
  volleyball: 'volleyball',
  tennis: 'tennisBall',
  badminton: 'badminton',
  american: 'american-football',
  tabletennis: 'tennisBall',
};

export const BallPossession = (props: IBallPossession): React.ReactElement => {
  const { id, team, iconSize, topCategoryId, listType } = props;
  const isTeamHome = team === 'home';

  const sportName = getSportName(topCategoryId);
  const iconName = ICON_NAMES[sportName];
  const { event } = useEventsModel(id, listType);

  const iconAttrs = {
    width: `${iconSize}px`,
    height: `${iconSize}px`,
  };

  if (!event || !event.score) {
    return <S.Possession listType={listType} />;
  }

  const isServing = getServing(event.score, isTeamHome);

  if (!iconName || !isServing) {
    return <></>;
  }

  return (
    <S.Possession listType={listType}>
      <S.Serving
        americanFootball={sportName === 'american'}
        volleyball={sportName === 'volleyball'}
        darts={sportName === 'darts'}
      >
        <Icon name={iconName} {...iconAttrs} />
      </S.Serving>
    </S.Possession>
  );
};

export default BallPossession;
