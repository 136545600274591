import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';

import WithCountDown from '@common/hocs/WithCountDown';

import * as S from './Accepted.styled';
import { IAcceptedProps } from './Accepted.types';

const Accepted: React.FC<IAcceptedProps> = ({
  partCashout,
  callBack,
  startCountDown,
  paidStatus,
  isAnonymousCashout,
}): ReactElement | null => {
  const { t } = useTranslation();
  const delay = isAnonymousCashout ? 7 : 5;
  const successMsg =
    paidStatus === 0 ? 'successCreditResponce' : 'successResponse';
  const successPartMsg =
    paidStatus === 0 ? 'successCreditPartResponse' : 'successPartResponse';
  let message = t(`cashout.${successMsg}`);

  if (isAnonymousCashout) {
    message = t('cashout.successAnonymousResponse');
  } else if (!partCashout) {
    message = t(`cashout.${successPartMsg}`);
  }

  useEffect(() => {
    if (callBack) {
      startCountDown(callBack, delay);
    }
  }, []);

  return (
    <S.WrapperInfo>
      <S.Header>
        <Icon name="check-circle" width="27" height="27" />
        <S.Title>{message}</S.Title>
      </S.Header>
    </S.WrapperInfo>
  );
};

export { Accepted as Component };

export default WithCountDown(Accepted, {});
