import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';

import WithCountDown from '@common/hocs/WithCountDown';

import * as S from './Rejected.styled';
import { IRejected } from './Rejected.types';

const Rejected: React.FC<IRejected> = (props): ReactElement | null => {
  const { message, onClose, startCountDown, isAnonymousCashout } = props;
  const { t } = useTranslation();
  const delay = isAnonymousCashout ? 7 : 5;

  useEffect(() => {
    startCountDown(onClose, delay);
  }, []);

  return (
    <S.WrapperRejected>
      <S.IconWrapper>
        <Icon name="info-circle" width="27" height="27" />
      </S.IconWrapper>
      <S.Content>
        <S.Title>{t('cashout.rejectedTitle')}</S.Title>
        <S.ErrorMessage>{message || t('cashout.rejected')}</S.ErrorMessage>
      </S.Content>
    </S.WrapperRejected>
  );
};

export { Rejected as Component };

export default WithCountDown(Rejected, {});
