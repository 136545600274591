import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';

import { isDesktopView } from '@common/helpers/deviceUtil';

import * as S from './ProcessingMessage.styled';
import { IProcessingMessageProps } from './ProcessingMessage.types';

const isDesktop = isDesktopView();

const ProcessingMessage: React.FC<IProcessingMessageProps> = ({
  withoutText,
}) => {
  const { t } = useTranslation();

  return (
    <S.MessageWrapper withoutText={withoutText}>
      <S.IconWrapper withoutText={withoutText}>
        <Icon
          width={isDesktop ? '16px' : '12px'}
          height={isDesktop ? '16px' : '12px'}
          name="sync"
          fill={isDesktop ? '#ffed00' : '#fff'}
        />
      </S.IconWrapper>
      {!withoutText && (
        <S.Message>{t('bettingslip.24h_lock_processing')}</S.Message>
      )}
    </S.MessageWrapper>
  );
};

export default ProcessingMessage;
