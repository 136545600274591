import React from 'react';

const SwLogoFlat: React.FC = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 412.4 105.1"
  >
    <style type="text/css">
      {`.st0{display:none;fill:#0D1728;}
			.st1{fill:#FFFFFF;}
			.st2{fill:#E4BC00;}
			.st3{fill:url(#XMLID_00000025431800637331709170000001159919175084874647_);}
			.st4{fill:url(#XMLID_00000093136144676735229310000008185419245212964258_);}`}
    </style>
    <rect x="-28.1" y="0" className="st0" width="440.5" height="186.3" />
    <image
      style={{ display: 'none', overflow: 'visible' }}
      width="1361"
      height="621"
      transform="matrix(0.3587 0 0 0.3587 -47.962 -21.3206)"
    />
    <g id="XMLID_1_">
      <g id="XMLID_2_">
        <g id="XMLID_182_">
          <path
            id="XMLID_183_"
            className="st1"
            d="M31.8,40l-9.3,52.6c-1.7,9.8-7.8,12.5-15.1,12.5c-1.7,0-4.7-0.1-6.5-0.3
			   c-0.5-0.1-0.9-0.4-0.9-1c0-0.1,0-0.1,0-0.2l1.3-7.3c0.1-0.5,0.5-1,1-1h5.6c1.7,0,2-0.8,2.3-2.6L19.5,40c0.1-0.8,0.9-1.4,1.7-1.4
			   h9.4c0.7,0,1.2,0.5,1.2,1.2C31.8,39.9,31.8,39.9,31.8,40z"
          />
          <path
            id="XMLID_185_"
            className="st1"
            d="M67.6,52.1c1.7,0.4,2.1,0.7,1.9,2.2l-6.3,36.4c-0.1,0.8-0.5,1.4-1.7,1.4h-7.3
			   c-0.8,0-1.4-0.5-1.2-1.4l0.3-2.1c-3.9,3-7.3,4.2-11.2,4.2C33.5,92.8,30,86.2,30,79c0-1.4,0.1-2.7,0.4-4c0,0,0.9-5.4,1.2-6.8
			   c2.2-12.5,9.9-17.9,21.2-17.9C57.6,50.3,63,50.9,67.6,52.1z M51.2,59.9c-5.1,0-7.2,3-8.2,8.9l-1.3,7.6c-0.1,0.8-0.2,1.6-0.2,2.4
			   c0,2.4,0.9,4.4,4.1,4.4c2.4,0,5.3-1.2,7.6-2.5l3.5-20.2C55.5,60.1,53.2,59.9,51.2,59.9z"
          />
          <path
            id="XMLID_188_"
            className="st1"
            d="M100.5,51.4c1.3,0.4,1.4,0.7,1.2,1.7l-1.2,6.4c-0.1,0.8-0.3,1.4-1.7,1.2
			   c-2.7-0.3-4.6-0.5-8.7-0.5c-3.5,0-6.1,1.7-7.3,8.4l-1.1,6c-0.2,1.4-0.4,2.5-0.4,3.5c0,3.9,1.8,4.9,4.8,4.9c4.1,0,6.1-0.2,8.9-0.5
			   c1.1-0.1,1.4,0.4,1.3,1.2l-1.2,6.4c-0.2,1.1-0.6,1.4-1.8,1.7c-2.1,0.5-5.3,1.1-9.6,1.1c-8.4,0-14.2-5-14.2-14
			   c0-1.4,0.1-2.7,0.4-4.2l1.1-6c2.4-13.5,9.5-18.2,19.9-18.2C95.4,50.3,98.5,50.9,100.5,51.4z"
          />
          <path
            id="XMLID_190_"
            className="st1"
            d="M141.9,52.4l-17.3,18.2l10.2,20c0.3,0.6,0.1,1.5-0.9,1.5h-10.6c-0.8,0-1.4-0.6-1.8-1.5
			   l-7.3-16.4l-2.9,16.4c-0.1,0.8-0.9,1.5-1.7,1.5h-8.7c-0.8,0-1.4-0.5-1.2-1.5l9.2-52.3c0.1-0.8,0.6-1.4,1.7-1.4h8.7
			   c1.2,0,1.4,0.6,1.2,1.4l-5.2,29.2L128.4,52c0.7-0.9,1.5-1.1,2.1-1.1h10.9c0.6,0,0.9,0.3,0.9,0.6S142.2,52.1,141.9,52.4z"
          />
          <path
            id="XMLID_192_"
            className="st1"
            d="M178.5,68.7l-0.9,5.6c-2.2,13.4-9.8,18.4-20.7,18.4c-2,0-4.2-0.2-6.4-0.5l-2.2,11.5
			   c-0.1,0.8-0.9,1.4-1.7,1.4h-8.7c-0.7,0-1.3-0.5-1.3-1.2c0-0.1,0-0.1,0-0.2l8.9-49.5c0.2-1.2,0.6-1.7,2.6-2.1
			   c4.3-1,10.1-1.9,15.6-1.9c9.6,0,15.2,5.8,15.2,14.5C178.8,66.1,178.7,67.4,178.5,68.7z M162,59.9c-2.4,0-4.5,0.1-6,0.4l-4,22.5
			   c2,0.3,3.8,0.4,5.4,0.4c4.5,0,7.2-1.7,8.4-8.9l0.9-5.6c0.2-1.4,0.4-2.7,0.4-3.7C167.1,61.7,165.9,59.9,162,59.9z"
          />
          <path
            id="XMLID_195_"
            className="st1"
            d="M217.8,69l-0.9,5c-2.3,12.2-8.6,18.7-21.3,18.7c-9.8,0-15.1-6.2-15.1-14.7
			   c0-1.3,0.1-2.7,0.4-4l0.9-5c2.4-13,9.4-18.7,21.3-18.7c9.7,0,15.2,5.8,15.2,14.5C218.2,66.2,217.9,68.2,217.8,69z M201.3,60.2
			   c-4.2,0-6.8,2.4-8,8.8l-0.9,5c-0.2,1.3-0.4,2.4-0.4,3.5c0,3.7,1.7,5.4,5.3,5.4c4.7,0,6.8-3,7.9-8.9l0.9-5
			   c0.2-1.2,0.4-2.4,0.4-3.3C206.5,62,204.8,60.2,201.3,60.2z"
          />
          <path
            id="XMLID_198_"
            className="st1"
            d="M246.2,52.3l-1.1,5.8c-0.1,0.8-0.9,1.4-1.7,1.4h-5.9l-3.7,21c-0.1,0.9-0.2,1.5-0.2,2
			   c0,1.1,0.5,1.4,2,1.4h3.5c0.7,0,1.3,0.3,1.3,1c0,0.1,0,0.1,0,0.2l-1,5.6c-0.1,0.7-0.6,1.3-1.6,1.4c-2.2,0.4-3.7,0.6-5.8,0.6
			   c-6.6,0-10.2-2.4-10.2-8.6c0-1.1,0.1-2.3,0.4-3.7l3.7-20.9l-5.6-0.9c-0.7-0.1-1.2-0.6-1.2-1.3c0-0.1,0-0.1,0-0.2l0.8-4.8
			   c0.1-0.8,1-1.5,1.8-1.5h5.8l1-5.9c0.1-0.8,0.9-1.3,1.7-1.4l9.1-1.5c0.1,0,0.1,0,0.2,0c0.6,0,1.1,0.4,1.1,1c0,0.1,0,0.1,0,0.2
			   l-1.4,7.6h5.9c0.7,0,1.2,0.5,1.2,1.2C246.2,52.2,246.2,52.2,246.2,52.3z"
          />
        </g>
        <g id="XMLID_173_">
          <g>
            <path
              id="XMLID_00000025428159033885325760000011812403866799756938_"
              className="st2"
              d="M285.3,64.9c3.2,1.9,4.8,5.4,4.8,8.7
				   c0,15-13.2,19.2-25.8,19.2c-4.8,0-10.4-0.4-13.2-0.9c-2.3-0.4-3.3-1.1-3.3-3.1c0-0.4,0.1-1,0.1-1.6l7.7-43.8
				   c0.6-3.2,1.8-4.2,4.7-4.7c2.5-0.4,8.6-0.8,13.2-0.8c11.9,0,20.2,3.5,20.2,12.3C293.6,56.3,291,62.3,285.3,64.9z M271.3,69.2
				   h-8.1l-2.3,13.4c1.2,0,4.9,0.1,5.9,0.1c6,0,11.2-1.4,11.2-8.6C278,71.3,276.3,69.2,271.3,69.2z M272.5,48.1
				   c-1.2,0-4.5,0-5.5,0.1l-2.2,12.5h8.1c5.5,0,8.7-2.7,8.7-8.3C281.5,49.1,278.9,48.1,272.5,48.1z"
            />
            <path
              id="XMLID_00000147901267923217742810000000879912934943483569_"
              className="st2"
              d="M332.7,40.4l-1.2,6.7
				   c-0.1,0.8-0.9,1.4-1.7,1.4h-14.6c-3,0-4.8,0.6-5.5,4.8l-1.2,6.6h18.6c0.7,0,1.2,0.5,1.2,1.2c0,0.1,0,0.1,0,0.2l-1.2,6.8
				   c-0.1,0.8-0.9,1.4-1.7,1.4h-18.5l-1.4,7.6c-0.1,0.7-0.2,1.4-0.2,1.9c0,2.3,1.2,3,4,3h14.6c0.7,0,1.2,0.5,1.2,1.2
				   c0,0.1,0,0.1,0,0.2l-1.2,6.7c-0.1,0.9-0.6,1.4-1.7,1.5c-2.6,0.4-6.2,0.9-14,0.9c-7.5,0-15.3-2.7-15.3-12c0-1.1,0.1-2.3,0.4-3.5
				   l4.2-23.9c2.4-14,10.4-15.4,20.3-15.4c7.8,0,11.2,0.6,13.7,0.9c0.9,0.1,1.3,0.4,1.3,1C332.8,40.1,332.7,40.2,332.7,40.4z"
            />
            <path
              id="XMLID_00000016782391781775116580000009886194105501646255_"
              className="st2"
              d="M374.5,40l-1.3,7.4
				   c-0.1,0.8-1,1.4-1.8,1.4h-11.7l-7.3,41.9c-0.1,0.8-0.9,1.4-1.7,1.4h-9.4c-0.7,0-1.2-0.5-1.2-1.2c0-0.1,0-0.2,0-0.3l7.3-41.9
				   h-11.7c-0.7,0-1.2-0.4-1.2-1.1c0-0.1,0-0.2,0-0.3l1.3-7.4c0.1-0.8,0.9-1.4,1.7-1.4h35.7c0.7,0,1.3,0.5,1.3,1.2
				   C374.5,39.9,374.5,39.9,374.5,40z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="XMLID_647_">
      <style type="text/css">{`.logo0{fill:#FFFFFF;} .logo1{fill:#FFED00;}`}</style>

      <radialGradient
        id="XMLID_00000128452556637081281460000003348125684274047415_"
        cx="3660.2686"
        cy="1125.1123"
        r="12.5682"
        gradientTransform="matrix(-0.2707 -4.168632e-02 -0.494 3.2014 1919.8519 -3408.7383)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop
          offset="0.6051"
          style={{ stopColor: '#61A57B', stopOpacity: 0.3917 }}
        />
        <stop
          offset="0.9946"
          style={{ stopColor: '#006D2A', stopOpacity: 0 }}
        />
      </radialGradient>
      <path
        id="XMLID_649_"
        style={{
          fill:
            'url(#XMLID_00000128452556637081281460000003348125684274047415_)',
        }}
        d="M369.8,40.1
	   c3.5-22.4,7.8-40.4,9.6-40.1c1.9,0.3,0.6,18.7-2.9,41.1s-7.8,40.4-9.6,40.1C365.1,81,366.4,62.5,369.8,40.1z"
      />

      <radialGradient
        id="XMLID_00000102529188032691370430000001079979526683740315_"
        cx="-982.6619"
        cy="1584.2017"
        r="12.5661"
        gradientTransform="matrix(5.508197e-03 -0.2738 -3.2385 -6.605203e-02 5507.2466 -125.1919)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
        <stop
          offset="0.6051"
          style={{ stopColor: '#61A57B', stopOpacity: 0.3917 }}
        />
        <stop
          offset="0.9946"
          style={{ stopColor: '#006D2A', stopOpacity: 0 }}
        />
      </radialGradient>
      <path
        id="XMLID_648_"
        style={{
          fill:
            'url(#XMLID_00000102529188032691370430000001079979526683740315_)',
        }}
        d="M371.4,35.8
	   c22.7,0.5,41.1,2.4,41,4.2c0,1.9-18.5,3-41.2,2.6c-22.7-0.5-41.1-2.4-41-4.2C330.3,36.5,348.7,35.3,371.4,35.8z"
      />
    </g>
  </svg>
);

export default SwLogoFlat;
