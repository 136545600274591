import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import replace from 'lodash/replace';

import SubmitHoc from '@features/cashout/SubmitHoc';
import { Inputs } from '@features/cashout/components/stateInputs';
import { BetStats } from '@features/cashout/components/betStats';
import { VirtualKeyboard } from '@features/core/commonComponents/virtual';
import { StyledButton } from '@features/core/commonComponents/buttons';
import CountdownDoubleWhiteButton from '@features/bettingslip/components/buttons/CountdownDoubleWhiteButton';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';

import WithCountDown from '@common/hocs/WithCountDown';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  ButtonSize,
  ButtonType,
  ICashoutInputProps,
  TTimeout,
} from '@common/interfaces';
import {
  STATE_ACCEPTED,
  STATE_ERROR,
  STATE_QUEUED,
} from '@common/providers/cashout/state';
import deleteTimeout from '@common/helpers/timeoutHelper';
import numeral from '@common/helpers/numeralHelper';
import {
  cashoutDefaultButtonValue,
  formatCashoutButtonValue,
  formatCashoutOffer,
  getNewRisk,
  getNewStake,
} from '@common/helpers/cashoutHelper';
import { useCashoutState } from '@common/providers/cashout/useCashout';
import { useBets } from '@common/providers/bets/useBets';

import * as S from './CashoutInputs.styled';

const CashoutInputs: React.FC<ICashoutInputProps> = ({
  betId,
  submit,
  onSubmitCashoutQueued,
  countdown,
  startCountDown,
  cashoutOfferQueued,
  scrollToElem,
}) => {
  const updatesReceived = useBets(s => s.updatesReceived);
  const { t } = useTranslation();
  const {
    cashoutOffer,
    requestedCashout,
    cashoutDisabled,
    totalStake,
    remainingRisk,
  } = useCashoutItemModel(betId);

  const [partValue, setPartValue] = useState('0');
  const [partExtended, setPartExtended] = useState<boolean>(false);
  const [readySubmitMax, setReadySubmitMax] = useState<boolean>(false);
  const [readySubmitPart, setReadySubmitPart] = useState<boolean>(false);
  const timeoutScrollRef = useRef<TTimeout>(null);
  const state = requestedCashout && requestedCashout.state;
  const isQueued = state === STATE_QUEUED;
  const loading = useCashoutState(s => s.loading);

  const getCashoutValue = (isMax?: boolean): number | string => {
    const cashoutValue = isMax ? 0 : cashoutOffer;
    if (cashoutOffer === cashoutDefaultButtonValue) {
      return cashoutOffer;
    }
    return updatesReceived
      ? parseFloat(cashoutOfferQueued || cashoutOffer)
      : cashoutValue;
  };

  const newCashoutOffer = getCashoutValue();
  const isPartCashout = readySubmitPart && !isQueued;
  const isReadySubMux = !readySubmitPart && readySubmitMax && isQueued;
  const maxValue = getCashoutValue(true) as number;

  useEffect(() => {
    return () => deleteTimeout(timeoutScrollRef.current);
  });

  useEffect(() => {
    if (state === STATE_ERROR || state === STATE_ACCEPTED) {
      setReadySubmitMax(false);
      setReadySubmitPart(false);
    }
  }, [requestedCashout]);

  useEffect(() => {
    if (requestedCashout?.queue_delay) {
      startCountDown(
        () => onSubmitCashoutQueued(requestedCashout),
        requestedCashout.queue_delay,
      );
    }
  }, [requestedCashout?.queue_delay]);

  const onReadySubmitMax = (): void => {
    setReadySubmitMax(true);
    setReadySubmitPart(false);
  };

  const onReadySubmitPart = (): void => {
    setReadySubmitMax(false);
    setReadySubmitPart(true);
  };

  return (
    <div className={`cashout-button cashout-el-bg-${betId}`}>
      <div className="partial-wrapper">
        <S.MainAmount
          className="cashout-container cashout-reverse-bg"
          id={`cashout-container-${betId}`}
        >
          <S.AmountButtonWrapper
            aria-disabled={readySubmitPart && !readySubmitMax && isQueued}
            noGap={
              !readySubmitMax && !isQueued && !loading && state !== STATE_ERROR
            }
          >
            {readySubmitMax && !isQueued && !loading && state !== STATE_ERROR && (
              <>
                <StyledButton
                  className="max_cashout"
                  disabled={loading}
                  onClick={(): void => submit(true, totalStake)}
                  aria-label={t('aria_labels.buttons.submit')}
                  label={formatCashoutOffer(
                    newCashoutOffer,
                    cashoutDisabled,
                    true,
                  )}
                  size={ButtonSize.Big}
                />
                <StyledButton
                  onClick={(): void => setReadySubmitMax(false)}
                  aria-label={t('aria_labels.buttons.close')}
                  label=""
                  icon={{
                    name: 'close',
                    width: 10,
                    height: 10,
                    className: 'icon-close',
                    isSingle: true,
                  }}
                  buttonType={ButtonType.LightOutlined}
                  size={ButtonSize.Big}
                />
              </>
            )}
            {!readySubmitMax &&
              !isQueued &&
              !loading &&
              state !== STATE_ERROR && (
                <Inputs
                  value={String(newCashoutOffer)}
                  formattedValue={formatCashoutButtonValue(
                    String(newCashoutOffer),
                  )}
                  disabledBtn={readySubmitPart || cashoutDisabled}
                  onReadySubmit={onReadySubmitMax}
                  inputType="max"
                  isLock={cashoutDisabled}
                />
              )}

            {(readySubmitPart || readySubmitMax) && isQueued && (
              <CountdownDoubleWhiteButton
                label={
                  readySubmitPart
                    ? formatCashoutOffer(
                        replace(partValue, ',', '.'),
                        cashoutDisabled,
                      )
                    : formatCashoutOffer(newCashoutOffer, cashoutDisabled)
                }
                countdown={countdown}
                isWhite
                className={readySubmitPart ? 'part-queued' : 'max_cashout'}
                areaLabel={
                  readySubmitPart
                    ? t('aria_labels.buttons.submit')
                    : t('aria_labels.buttons.submit_cashout')
                }
              />
            )}

            {(loading || state === STATE_ERROR) &&
              !isQueued &&
              !readySubmitPart && (
                <CountdownDoubleWhiteButton
                  label={formatCashoutOffer(newCashoutOffer, cashoutDisabled)}
                  className="disabled_cashout"
                  disabled
                  areaLabel={t('aria_labels.buttons.disable_cashout')}
                  isWhite
                  areaLabelCountdown={t('aria_labels.buttons.go_queued')}
                  isLockIcon
                />
              )}
          </S.AmountButtonWrapper>
          {!cashoutDisabled && (
            <StyledButton
              disabled={
                !formatCashoutOffer(newCashoutOffer, cashoutDisabled) ||
                isQueued
              }
              onClick={(): void => {
                setPartExtended(!partExtended);

                if (!isDesktopView()) {
                  setTimeout(() => {
                    scrollToElem();
                  }, 300);
                }
              }}
              label="cashout.cashout_label"
              buttonType={ButtonType.LightOutlined}
              className="partly-cashout-btn"
              size={ButtonSize.Big}
            />
          )}
          <S.PartCashoutContainer extended={partExtended}>
            <form onSubmit={(e): void => e.preventDefault()}>
              <S.AmountWrapper
                aria-disabled={!readySubmitPart && readySubmitMax && isQueued}
                isPartCashout={isPartCashout}
                isReadySubMux={isReadySubMux}
              >
                {isPartCashout && countdown !== 0 && (
                  <>
                    <StyledButton
                      disabled={loading}
                      onClick={(): void => submit(false, partValue)}
                      className="part-cashout-btn"
                      type="submit"
                      label={formatCashoutOffer(
                        replace(partValue, ',', '.'),
                        cashoutDisabled,
                        true,
                      )}
                      size={ButtonSize.Big}
                    />
                    <StyledButton
                      onClick={(): void => setReadySubmitPart(false)}
                      aria-label={t('common.buttons.close')}
                      label=""
                      icon={{
                        name: 'close',
                        width: 10,
                        height: 10,
                        className: 'icon-close',
                        isSingle: true,
                      }}
                      buttonType={ButtonType.LightOutlined}
                      className="part-cashout-closeBtn"
                      size={ButtonSize.Big}
                    />
                  </>
                )}
                {!readySubmitPart && !isQueued && !cashoutDisabled && (
                  <Inputs
                    focus={!readySubmitMax}
                    value={partValue}
                    formattedValue={formatCashoutButtonValue(partValue)}
                    setValue={setPartValue}
                    disabledBtn={readySubmitMax}
                    onReadySubmit={onReadySubmitPart}
                    inputType="partial"
                    maxValue={maxValue}
                    focused={partExtended}
                  />
                )}

                {isReadySubMux && (
                  <Inputs
                    value={partValue}
                    formattedValue={formatCashoutButtonValue(partValue)}
                    disabledBtn
                    isLock
                    onReadySubmit={onReadySubmitMax}
                    inputType="max"
                  />
                )}
              </S.AmountWrapper>
            </form>
            {!isDesktopView() && !readySubmitPart && !cashoutDisabled && (
              <S.WrapKeyboard>
                <VirtualKeyboard
                  value={partValue}
                  maxValue={maxValue}
                  shouldReplace={false}
                  onChange={(val): void => {
                    if (setPartValue) {
                      const newVal =
                        val === String(newCashoutOffer)
                          ? numeral(+val).format('0.[00]')
                          : val;
                      setPartValue(newVal);
                    }
                  }}
                />
              </S.WrapKeyboard>
            )}
          </S.PartCashoutContainer>
        </S.MainAmount>

        {+partValue !== 0 && !cashoutDisabled && (
          <div className="test">
            <BetStats
              current={false}
              remainingRisk={String(
                getNewRisk(partValue, maxValue, remainingRisk),
              )}
              totalStake={String(getNewStake(partValue, maxValue, totalStake))}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { CashoutInputs as Component };

export default WithCountDown(SubmitHoc(CashoutInputs), {});
