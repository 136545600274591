import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@features/core/commonComponents/icon';

import { toggleContentScroll } from '@common/providers/application/useAppState';
import useOnClickOutside from '@common/hooks/useOnClickOutside';

import BetPackerIcon from './BetPackerIcon';
import * as S from './BetPacker.styled';
import { IBetPackerExplanation, IBetPackerIconSize } from './BetPacker.types';

const BetPackerExplanation: React.FC<IBetPackerExplanation> = ({
  toggleExplanationPopup,
}): JSX.Element => {
  const { t } = useTranslation();
  const handleClose = (): void => {
    toggleExplanationPopup(false);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, e => {
    e.preventDefault();
    handleClose();
  });

  useEffect(() => {
    toggleContentScroll('disable');
    return () => toggleContentScroll('enable');
  }, []);

  return (
    <S.BetPackerPopupWrapper ref={containerRef}>
      <S.PopupArrowUp />
      <S.CloseIcon>
        <Icon
          name="close"
          width="12"
          height="12"
          fill="#005374"
          onClick={handleClose}
        />
      </S.CloseIcon>
      <S.ExplanationText
        dangerouslySetInnerHTML={{
          __html: t('bets.bet_packer.tooltip_hint'),
        }}
      />
      <S.ExampleTableWrapper>
        <S.ExampleTitle>
          <S.ExampleRow>
            <b>
              FC Bayren
              <br />
              Borussia Dortmund
            </b>
          </S.ExampleRow>
          <S.ExampleRow>
            Tipp - 2.25 - <b>FC Bayern</b>
          </S.ExampleRow>

          <S.ExampleRow>
            Uber/Unter - 2.5 - <b>Uber</b>
          </S.ExampleRow>
          <S.ExampleRow>
            Uber/Unter - 3.5 - <b>Unter</b>
          </S.ExampleRow>
        </S.ExampleTitle>
        <S.ExampleValue>
          <S.ExampleRow> 21.02</S.ExampleRow>
          <S.ExampleRow> 21:00</S.ExampleRow>
          <S.BetPackerExampleResult>
            <BetPackerIcon size={IBetPackerIconSize.small} />
            <span>4.00</span>
            <Icon name="delete icon" width="17" height="17" />
          </S.BetPackerExampleResult>
        </S.ExampleValue>
      </S.ExampleTableWrapper>
    </S.BetPackerPopupWrapper>
  );
};

export default BetPackerExplanation;
