import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@features/core/commonComponents/buttons';

import { CANCEL_BONUS } from '@common/constants/dialogs';
import { ButtonSize, ButtonType, SidebarSide } from '@common/interfaces';
import { closeSidebar } from '@common/providers/application/useAppState';
import { postCancelBonus } from '@common/providers/account/helper';

import * as S from './CancelBonus.styled';

const CancelBonus = (): React.ReactElement => {
  const { t } = useTranslation();

  const handleClose = (): void => {
    closeSidebar(CANCEL_BONUS);
  };

  const handleSubmit = async (): Promise<void> => {
    await postCancelBonus('mock');
    handleClose();
  };

  return (
    <S.Wrapper className="info-limit">
      <S.CloseIcon
        className="closeIcon"
        onClick={handleClose}
        name="close"
        fill="#004560"
      />

      <S.TitleWrapper>
        <S.InfoIcon name="info" fill="#004560" />

        <S.Title>{t('user.bonuses.cancel_bonus.title')}</S.Title>
      </S.TitleWrapper>

      <S.Text
        dangerouslySetInnerHTML={{
          __html: t('user.bonuses.cancel_bonus.text'),
        }}
      />

      <S.Text>{t('user.bonuses.cancel_bonus.question')}</S.Text>

      <Button
        buttonType={ButtonType.Blue}
        size={ButtonSize.Big}
        onClick={handleSubmit}
      >
        {t('user.bonuses.cancel_bonus.confirm_button')}
      </Button>

      <Button
        buttonType={ButtonType.DarkOutlined}
        size={ButtonSize.Big}
        onClick={handleClose}
      >
        {t('common.buttons.cancel')}
      </Button>
    </S.Wrapper>
  );
};

export { CancelBonus as Component };

CancelBonus.meta = {
  side: SidebarSide.center,
  key: CANCEL_BONUS,
};

export default CancelBonus;
